import { Avatar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../css/admin_header.css";
import { FaAngleDown } from "react-icons/fa";
import {BiLogOut, BiUserCircle} from "react-icons/bi"
import {RiLockPasswordLine, RiSettings3Line} from "react-icons/ri";
import SideBar from "../../components/Sidebar/SideBar";
import logo from "../../Images/LOGO.webp";
import { useNavigate } from "react-router-dom";
 
export default function AdminHeader() {
  const [activeDrop, setActiveDrop] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Click occurred outside the dropdown, so hide it
        setActiveDrop(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(()=>{
    const access_token = localStorage.getItem("loginToekn");

    if(!access_token){
      navigate("/admin-login")
    }
  }, []);

  return (
    <div>
      <div className="adminHeader" style={{zIndex:"1000"}}>
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <img
              src={logo}
              alt="logo"
              width={50}
              style={{margin:"auto"}}
            />
          </div>
          <div className={activeDrop ? `active loginDetail` : `loginDetail`}>
            <div className="loginName">
              <Avatar alt="Arbaz Ansari" src="/static/images/avatar/1.jpg" />
              <h3 onClick={() => setActiveDrop((prev) => !prev)}>
                Arbaz Ansari&nbsp;
                <FaAngleDown />
              </h3>
            </div>
             
            <ul ref={dropdownRef}>
              <li><BiUserCircle style={{fontSize:"1.4rem"}} />&nbsp;Profile</li>
              <li><RiLockPasswordLine style={{fontSize:"1.4rem"}} />&nbsp;Reset Passoword</li>
              <li><RiSettings3Line style={{fontSize:"1.4rem"}} />&nbsp;Setting</li>
              <li><BiLogOut style={{fontSize:"1.4rem"}} />&nbsp;Log Out</li>
            </ul>
          </div>
        </div>
      </div>

      <SideBar admin={true} />
    </div>
  );
}
