import React, { useRef, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import NoDataFound from "../../pages/NoDataFound";
import { useImageResizer } from "../../context/imageResizer";
import HospitalSheduleAdd from "./HospitalSheduleAdd";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function HospitalDetail({ activeSteps, setActivesteps }) {
  const [hospitalList, setHospitalList] = useState([]);
  const [viewImage, setViewImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [validation, setValidation] = useState({
    name: true,
    phone: true,
    address: true,
    city: true,
    state: true,
    pincode: true,
    description: true,
  });

  const formRef = useRef();
  const { resizeFile } = useImageResizer();

  const imageHandleChange = async (e) => {
    const maxWidth = 300;
    const maxHeight = 300;
    const targetFileSize = 150 * 1024;

    const compresedFile = await resizeFile(
      e.target.files[0],
      maxWidth,
      maxHeight,
      targetFileSize
    );
    setImageFile(compresedFile);
    setViewImage(URL.createObjectURL(compresedFile));
  };

  // ===================== Hospital List ====================

  const createHospitalList = () => {
    const data = new FormData(formRef.current);
    const dataList = hospitalList;
    const listData = {
      imageView: viewImage,
      imageFileData: imageFile,
      name: data.get("name"),
      phone: data.get("phone"),
      address: data.get("address"),
      city: data.get("city"),
      state: data.get("state"),
      pincode: data.get("pincode"),
      gmbLink: data.get("gmb"),
      description: data.get("description"),
      hospitalShedule: [],
    };
    dataList.push(listData);

    setHospitalList([...dataList]);

    formRef.current.reset();
    setValidation({
      name: true,
      phone: true,
      address: true,
      city: true,
      state: true,
      pincode: true,
      description: true,
    });
    setImageFile(null);
    setViewImage(null);
  };

  // ======================= Remove from list ==================

  const removeHospitalList = (index) => {
    hospitalList.splice(index, 1);
    setHospitalList([...hospitalList]);
  };

  // const navigate = useNavigate();

  // ======================= Hospital Shedule ===================

  const hospitalShedule = async () => {
    const addData = {
      accountID: sessionStorage.getItem("accointId"),
      hospitals: hospitalList,
    };

    const req = await axios.post(
      "https://skdm.in/skddev/DrCrm/admin/create_hospital_list_wiith_doctor.php",
      addData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (req.data) {
      // const state = { pathname: "/admin-doctor-list" };
      // navigate("/admin-doctor-list", { state, replace: true });
    }
  };

  return (
    <>
      <div className="d-flex align-items-start">
        <h3 className="pageTitle">Hospitals Details </h3>&nbsp; &nbsp;&nbsp;
      </div>
      <div className="addHospital">
        <div className="row">
          <div className="col-md-7">
            <form ref={formRef} style={{ width: "100%" }} autoComplete="off">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="inputType">
                    <label htmlFor="">
                      Enter Hospital Name{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Hospital Name"
                      name="name"
                      onChange={() =>
                        setValidation({ ...validation, name: false })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="inputType">
                    <label htmlFor="">
                      Enter Phone No. <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone No."
                      name="phone"
                      onChange={() =>
                        setValidation({ ...validation, phone: false })
                      }
                    />
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="inputType">
                    <label htmlFor="">
                      Enter Address <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      name="address"
                      onChange={() =>
                        setValidation({ ...validation, address: false })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="inputType">
                    <label htmlFor="">
                      Enter City <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      name="city"
                      onChange={() =>
                        setValidation({ ...validation, city: false })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="inputType">
                    <label htmlFor="">
                      Enter State <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="State"
                      name="state"
                      onChange={() =>
                        setValidation({ ...validation, state: false })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="inputType">
                    <label htmlFor="">
                      Enter Pincode <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Pincode"
                      name="pincode"
                      onChange={() =>
                        setValidation({ ...validation, pincode: false })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="inputType">
                    <label htmlFor="">Select Image</label>
                    <input
                      type="file"
                      className="form-control"
                      placeholder="Select Image"
                      name="image"
                      onChange={imageHandleChange}
                      accept=".jpg,.jpeg,.png"
                    />
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="inputType">
                    <label htmlFor="">Enter GMB Link</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="GMB Link"
                      name="gmb"
                    />
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="inputType">
                    <label htmlFor="">
                      Enter Description <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      name="description"
                      onChange={() =>
                        setValidation({ ...validation, description: false })
                      }
                      rows={3}
                    />
                  </div>
                </div>
              </div>
              <div className="btn_group">
                <button
                  disabled={
                    validation.name ||
                    validation.phone ||
                    validation.city ||
                    validation.address ||
                    validation.pincode ||
                    validation.state ||
                    validation.description
                  }
                  onClick={createHospitalList}
                >
                  Add
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-5 position-relative">
            <span className="devider"></span>
            <div className="listCard">
              {hospitalList && hospitalList.length > 0 ? (
                <>
                  {" "}
                  {hospitalList.map((value, i) => (
                    <div key={i} className="hospitalCard">
                      <div className="hospitalImage">
                        <img
                          src={
                            value.imageView
                              ? value.imageView
                              : `https://www.medibhai.com/uploads/hospital_image/hospital-profile-default.jpg`
                          }
                          alt={value.name}
                        />
                      </div>
                      <div className="hospitalDetails">
                        <h3>{value.name}</h3>
                        <p>{value.city + ", " + value.state}</p>
                        <div className="buttonGroup d-flex align-items-center">
                          <button
                            title="Remove Hospital"
                            onClick={() => removeHospitalList(i)}
                          >
                            <FaTrashAlt />
                          </button>
                          {/* ========== Add Hospital ========= */}
                          <HospitalSheduleAdd
                            hospitalList={hospitalList}
                            index={i}
                            setHospitalList={setHospitalList}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="btn_group mt-5">
                    <button onClick={() => hospitalShedule()}>Submit</button>
                  </div>
                </>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
