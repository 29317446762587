import {
  Button,
  Form,
  InputNumber,
  Modal,
  Select,
  Table,
  TimePicker,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { BsFillCalendarPlusFill } from "react-icons/bs";
import NoDataFound from "../../pages/NoDataFound";
import { FaCheck } from "react-icons/fa6";
import moment from "moment";
import { FaTrashAlt } from "react-icons/fa";

const names = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function HospitalSheduleAdd({
  hospitalList,
  index,
  setHospitalList,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sheduleList, setSheduleList] = useState(null);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const data = values.days.map((valueData, i) => ({
      key: i,
      days: valueData,
      startTime: moment(new Date(values.shedule[0])).format("hh:mm A"),
      endTime: moment(new Date(values.shedule[1])).format("hh:mm A"),
      slot: values.slot,
    }));

    const previousShedule = sheduleList;
    hospitalList[index].hospitalShedule = [...previousShedule, ...data];
    setHospitalList(hospitalList);
    setSheduleList([...previousShedule, ...data]);
    message.success("Add succesfully");
    form.resetFields();
  };

  const columns = [
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
    },
    {
      title: "Slot",
      dataIndex: "slot",
      key: "slot",
    },
    {
      title: "",
      dataIndex: "remove",
      key: "remove",
      render: (text, index) => (
        <button
          onClick={() => removeShedule(index.key)}
          style={{ background: "transparent", color: "red", border: "unset" }}
        >
          {<FaTrashAlt />}
        </button>
      ),
    },
  ];

  const removeShedule = (indexData) => {
    sheduleList.splice(indexData, 1);
    hospitalList[index].hospitalShedule = sheduleList;
    setHospitalList([...hospitalList]);
    setSheduleList([...sheduleList]);

    message.success("Removed Success");
  };

  //   =================== Shedule List ==============

  useEffect(() => {
    let clearDataTime;

    clearDataTime = setTimeout(() => {
      setSheduleList(hospitalList[index].hospitalShedule);
    }, 500);

    return () => clearTimeout(clearDataTime);
  }, [hospitalList]);

  return (
    <div>
      <button title="Add Shedule" onClick={showModal}>
        <BsFillCalendarPlusFill />
      </button>

      <Modal
        centered
        title={hospitalList[index].name}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        {sheduleList && sheduleList.length > 0 ? (
          <Table
            pagination={false}
            columns={columns}
            dataSource={sheduleList}
          />
        ) : (
          <NoDataFound />
        )}
        <Form
          className="mt-4"
          onFinish={onFinish}
          name="basic"
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <div className="row">
            <div className="col-3 px-0">
              <Form.Item
                label="Select days"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                name="days"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Days"
                >
                  {names.map((name) =>
                    sheduleList && sheduleList.some((value) => value.days === name) ? null : (
                      <Select.Option key={name} value={name}>
                        {name}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </div>
            <div className="col-5">
              <Form.Item
                label="Select shedule"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                name="shedule"
              >
                <TimePicker.RangePicker
                  style={{
                    width: "100%",
                  }}
                  format="h:mm a"
                />
              </Form.Item>
            </div>
            <div className="col-2 px-0">
              <Form.Item
                label="Slots"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                name="slot"
              >
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </div>
            <div className="col-1">
              <Form.Item label=" " tooltip="Add shedule">
                <Button
                  htmlType="submit"
                  style={{
                    background: "green",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "50px",
                  }}
                >
                  <FaCheck />
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
