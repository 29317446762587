import React, { useEffect, useState } from "react";
import "../css/login.css";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { Typography } from "antd";
import { useLoginUserMutation } from "../../services/userAuthAPI";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

export default function LoginAdmin() {
  const [form] = Form.useForm();
  const [loginUser] = useLoginUserMutation();
  const navigate = useNavigate();

  const onFinish = async (value) => {
    const actualData = {
      phone: value.username,
      password: value.password,
    };

    const res = await loginUser(actualData);
  

    if (res.data) {
      if (res.data.loginType === "admin") {
        navigate("/admin-doctor-list");
        localStorage.setItem("loginToekn", res.data.loginType);
      }else{
        message.error("you don't have permission to access admin");
      }
    }

    if (res.error) {
      message.error("please enter valid username or password")
    }
  };

  const [clientReady, setClientReady] = useState(false);

  // To disable submit button at the beginning.
  useEffect(() => {
    setClientReady(true);
  }, []);

  useEffect(()=>{
    const access_token = localStorage.getItem("loginToekn");

    if(access_token){
      navigate("/admin-doctor-list")
    }
  }, []);

  return (
    <div className="login-page">
      <div className="container">
        <div className="login-content">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Title level={2}>Admin Login</Title>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  className="liginSubmitBtn"
                  htmlType="submit"
                  disabled={
                    !clientReady ||
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                >
                  Log in
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
