import React, { useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import OTPInput, { ResendOTP } from "otp-input-react";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaUserCircle } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useCreateAccountMutation } from "../../services/userAuthAPI";

const doctorCategories = [
  "Cardiologist",
  "Dermatologist",
  "Pediatrician",
  "Orthopedic Surgeon",
  "Gynecologist",
  "Neurologist",
  "Oncologist",
  "Psychiatrist",
  "General Practitioner",
  "Urologist",
  "Ophthalmologist",
  "Radiologist",
  "Anesthesiologist",
  "Gastroenterologist",
  "Endocrinologist",
  "Nephrologist",
  "Pulmonologist",
  "Hematologist",
  "Rheumatologist",
  "Otolaryngologist (ENT)",
  "Allergist/Immunologist",
  "Plastic Surgeon",
  "Infectious Disease Specialist",
  "Emergency Medicine Physician",
  "Family Medicine Physician",
  "Critical Care Physician",
  "Sports Medicine Physician",
  "Geriatrician",
  "Obstetrician",
  "Neonatologist",
  "Pathologist",
  "Osteopath",
  "Pain Management Specialist",
  "Dermatopathologist",
  "Radiologic Technologist",
  "Podiatrist",
  "Dentist",
  "Maxillofacial Surgeon",
  "Clinical Psychologist",
  "Physical Therapist",
  "Occupational Therapist",
  "Speech Therapist",
  "Chiropractor",
  "Naturopathic Doctor",
  "Homeopath",
  "Acupuncturist",
  "Optometrist",
  "Nuclear Medicine Physician",
  "Neurosurgeon",
  "Vascular Surgeon",
];

export default function AccountForm({ setActivesteps, activeSteps }) {
  const [numberOTP, setNumberOTP] = useState("");
  const [verify, setVerify] = useState({
    number: false,
    email: false,
  });

  const [accountInfo, setAccountInfo] = useState({
    number: null,
    email: null,
    usename: null,
    password: null,
  });

  const [validationMessage, setValidationMessage] = useState({
    number: false,
    email: false,
    username: false,
    password: false,
    confirmPassord: false,
  });

  const [validation, setValidation] = useState({
    number: true,
    email: true,
    username: true,
    password: true,
    confirmPassord: true,
  });

  // ===================== Email Validation ====================
  const handleEmailValidation = (e) => {
    // Corrected regular expression pattern (removed unnecessary slashes)
    const validPattern = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

    if (validPattern.test(e.target.value)) {
      // Use test method to check if it matches
      setAccountInfo({ ...accountInfo, email: e.target.value });
      setValidationMessage({ ...validationMessage, email: "" });
      setValidation({ ...validation, email: false });
    } else {
      setValidation({ ...validation, email: true });
      setValidationMessage({
        ...validationMessage,
        email: "Please Enter Valid Email",
      });
    }
  };

  // ===================== Number Validation ================
  const handleNumberValidation = (e) => {
    // Corrected regular expression pattern (removed unnecessary slashes)
    const validPattern = /^[0-9]{10}$/; // Matches numbers with exactly 10 digits

    if (validPattern.test(e.target.value)) {
      // Use test method to check if it matches
      setAccountInfo({ ...accountInfo, number: e.target.value });
      setValidationMessage({ ...validationMessage, number: "" });
      setValidation({ ...validation, number: false });
    } else {
      setValidation({ ...validation, number: true });
      setValidationMessage({
        ...validationMessage,
        number: "Please Enter Valid Number",
      });
    }
  };

  // ============================= Username =====================

  const handleUsernameValidation = (e) => {
    const validPattern = /^[A-Z][A-Za-z0-9@]{7,}$/;

    if (validPattern.test(e.target.value)) {
      setAccountInfo({ ...accountInfo, username: e.target.value });
      setValidationMessage({ ...validationMessage, username: "" });
      setValidation({ ...validation, username: false });
    } else {
      setValidation({ ...validation, username: true });
      setValidationMessage({
        ...validationMessage,
        username: "Please Enter Valid Username",
      });
    }
  };
  // ===================== Password ====================
  const handlePasswordValidation = (e) => {
    const validPattern = /^(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]+$/;

    if (validPattern.test(e.target.value)) {
      setAccountInfo({ ...accountInfo, password: e.target.value });
      setValidationMessage({ ...validationMessage, password: "" });
      setValidation({ ...validation, password: false });
    } else {
      setValidation({ ...validation, password: true });
      setValidationMessage({
        ...validationMessage,
        password: "Please Enter Valid Password",
      });
    }
  };

  // ======================== confirm pasword ==================

  const confirmPassowrdValid = (e) => {
    if (e.target.value === accountInfo.password) {
      setAccountInfo({ ...accountInfo, password: e.target.value });
      setValidationMessage({ ...validationMessage, confirmPassord: "" });
      setValidation({ ...validation, confirmPassord: false });
    } else {
      setValidation({ ...validation, confirmPassord: true });
      setValidationMessage({
        ...validationMessage,
        confirmPassord: "Password does not match !",
      });
    }
  };

  // ====================== Submit Data =================

  const [createAccount] = useCreateAccountMutation();

  const handleSubmit = async(e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const formData = new FormData();

    formData.append("number", data.get("number"));
    formData.append("email", data.get("email"));
    formData.append("username", data.get("username"));
    formData.append("password", data.get("password"));
    formData.append("catogery", data.get("catogery"));
    formData.append("action", "create");

    const req = await createAccount(formData);
    if (req.data) {
      sessionStorage.setItem("accointId", req.data.id)
      setActivesteps({
        ...activeSteps,
        step1: "submited",
        step2: "active",
      });
    }

    if (req.error) {
      alert("Something wrong we unable to create account !");
    }
  };

  return (
    <div>
      <h3 className="pageTitle">Login Details</h3>
      <div className="account_form">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="form_input">
                <label
                  htmlFor="inputNumber"
                  className="d-flex align-items-center"
                >
                  Enter Number{" "}
                  {validationMessage.number ? (
                    <span style={{ color: "red" }}>
                      &nbsp;({validationMessage.number})
                    </span>
                  ) : null}{" "}
                </label>
                <div className="input_with_icon">
                  <BsFillTelephoneFill />
                  <input
                    type="number"
                    id="inputNumber"
                    name="number"
                    className="form-control formInput"
                    placeholder="+91 1234567890"
                    onChange={handleNumberValidation}
                  />
                  {verify.number === false ? (
                    <button
                      onClick={() => setVerify({ ...verify, number: true })}
                    >
                      Verify
                    </button>
                  ) : null}
                </div>
                <span>
                  {verify.number && verify.number === "verified" ? (
                    <AiFillCheckCircle className="verifyIcon" />
                  ) : (
                    <AiFillCloseCircle className="notVerifed" />
                  )}
                  &nbsp;Verify the phone number.
                </span>
                {verify.number && verify.number === true ? (
                  <>
                    <div className="otp_div">
                      <OTPInput
                        className="otpin-con"
                        value={numberOTP}
                        onChange={setNumberOTP}
                        autoFocus
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                      />
                      <button
                        className="submitOtp"
                        onClick={() =>
                          setVerify({ ...verify, number: "verified" })
                        }
                      >
                        Submit
                      </button>
                    </div>

                    <ResendOTP
                      className="resnedOtpDoctor"
                      // onResendClick={resendOtpData}
                    />
                  </>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form_input">
                <label
                  htmlFor="inputNumber"
                  className="d-flex align-items-center"
                >
                  Enter Email{" "}
                  {validationMessage.email ? (
                    <span style={{ color: "red" }}>
                      &nbsp;({validationMessage.email})
                    </span>
                  ) : null}{" "}
                </label>
                <div className="input_with_icon">
                  <MdEmail />
                  <input
                    type="email"
                    id="inputNumber"
                    name="email"
                    className="form-control formInput"
                    placeholder="info@example.com"
                    onChange={handleEmailValidation}
                  />
                  {verify.email === false ? (
                    <button
                      onClick={() => setVerify({ ...verify, email: true })}
                    >
                      Verify
                    </button>
                  ) : null}
                </div>
                <span>
                  {verify.email && verify.email === "verified" ? (
                    <AiFillCheckCircle className="verifyIcon" />
                  ) : (
                    <AiFillCloseCircle className="notVerifed" />
                  )}
                  &nbsp;Verify the email ID.
                </span>
                {verify.email && verify.email === true ? (
                  <>
                    <div className="otp_div">
                      <OTPInput
                        className="otpin-con"
                        value={numberOTP}
                        onChange={setNumberOTP}
                        autoFocus
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                      />
                      <button
                        className="submitOtp"
                        onClick={() =>
                          setVerify({ ...verify, email: "verified" })
                        }
                      >
                        Submit
                      </button>
                    </div>

                    <ResendOTP
                      className="resnedOtpDoctor"
                      // onResendClick={resendOtpData}
                    />
                  </>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form_input">
                <label
                  htmlFor="inputNumber"
                  className="d-flex align-items-center"
                >
                  Enter Username
                  {validationMessage.username ? (
                    <span style={{ color: "red" }}>
                      &nbsp;({validationMessage.username})
                    </span>
                  ) : null}
                </label>
                <div className="input_with_icon">
                  <FaUserCircle />
                  <input
                    type="text"
                    id="inputNumber"
                    className="form-control formInput"
                    name="username"
                    placeholder="Username"
                    onChange={handleUsernameValidation}
                  />
                </div>
                <ol>
                  <li style={{ listStyle: "auto" }}>
                    <span>Minimum 8 characters.</span>
                  </li>
                  <li style={{ listStyle: "auto" }}>
                    <span>It must start with an uppercase letter.</span>
                  </li>
                  <li style={{ listStyle: "auto" }}>
                    <span>It must contain the "@" symbol.</span>
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form_input">
                <label
                  htmlFor="inputNumber"
                  className="d-flex align-items-center"
                >
                  Select Catogery
                </label>
                <select className="form-select" name="catogery" id="">
                  {doctorCategories.map((value, i) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form_input">
                <label
                  htmlFor="inputNumber"
                  className="d-flex align-items-center"
                >
                  Enter Password
                  {validationMessage.password ? (
                    <span style={{ color: "red" }}>
                      &nbsp;({validationMessage.password})
                    </span>
                  ) : null}
                </label>
                <div className="input_with_icon">
                  <RiLockPasswordFill />
                  <input
                    type="password"
                    id="inputNumber"
                    className="form-control formInput"
                    placeholder="********"
                    name="password"
                    onChange={handlePasswordValidation}
                  />
                </div>
                <span>Must have one special character</span>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="form_input">
                <label
                  htmlFor="inputNumber"
                  className="d-flex align-items-center"
                >
                  Enter Confirm Password
                  {validationMessage.confirmPassord ? (
                    <span style={{ color: "red" }}>
                      &nbsp;({validationMessage.confirmPassord})
                    </span>
                  ) : null}
                </label>{" "}
                <div className="input_with_icon">
                  <RiLockPasswordFill />
                  <input
                    type="text"
                    id="inputNumber"
                    className="form-control formInput"
                    placeholder="*******"
                    name="confirmPassword"
                    onChange={confirmPassowrdValid}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="btn_group">
                <button
                  disabled={
                    validation.number ||
                    validation.email ||
                    validation.username ||
                    validation.password ||
                    validation.confirmPassord
                  }
                >
                  Submit & Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
