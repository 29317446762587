import React, { useEffect, useState } from "react";
import "../../css/Patients.css";
import { Link, useNavigate } from "react-router-dom";
import { MdDoDisturbAlt } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import AdminHeader from "../components/admin_header";
import { AiOutlineCheckCircle, AiOutlineFundView } from "react-icons/ai";
import NoDataFound from "../../pages/NoDataFound";
import moment from "moment";
import { Avatar, Pagination, Skeleton } from "@mui/material";
import { useCreateAccountMutation } from "../../services/userAuthAPI";

const DoctorList = () => {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [totalPages, setTotalPage] = useState(0);
  const [notFound, setNotFound] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState({
    message: "",
    type: "",
  });

  const DoctorData = (limit, page, searchText, searchType) => {
    setLoading(true);
    fetch(
      "https://skdm.in/skddev/DrCrm/admin/fetch_doctor_list.php?page=" +
        page +
        "&limit=" +
        limit +
        "&search=" +
        searchText +
        "&type=" +
        searchType +
        "",
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((resp) => resp.json())
      .then((apiData) => {
        if (apiData.status === "success") {
          setLoading(false);
          setListData(apiData.data);
          setTotalPage(apiData.totalPages);
        } else {
          setNotFound(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching drug data:", error);
      });
  };

  useEffect(() => {
    DoctorData(limit, page, "", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  function generateSkeletonRow() {
    const rows = [];

    for (let i = 0; i < 6; i++) {
      rows.push(
        <tr key={i}>
          <td>
            <Skeleton variant="rounded" width={120} height={40} />
          </td>
          <td>
            <Skeleton variant="circular" width={40} height={40} />
          </td>
          <td>
            <Skeleton variant="rounded" width={120} height={40} />
          </td>
          <td>
            <Skeleton variant="rounded" width={120} height={40} />
          </td>
          <td>
            <Skeleton variant="rounded" width={120} height={40} />
          </td>
          <td>
            <Skeleton variant="rounded" width={120} height={40} />
          </td>
          <td>
            <Skeleton variant="rounded" width={120} height={40} />
          </td>
          <td>
            <Skeleton variant="rounded" width={120} height={40} />
          </td>
          <td>
            <Skeleton variant="rounded" width={120} height={40} />
          </td>
        </tr>
      );
    }

    return rows;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // You can perform any additional actions here, such as fetching data for the new page.
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
  };

  // =================== Search Data ==================

  const handleSearchData = (e) => {
    e.preventDefault();
    DoctorData(limit, page, search.message, search.type);
  };
  // =================== Access Account ===================
  const naviaget = useNavigate();

  const handleAccessAccounnt = (token, data) => {
    const state = { pathname: "/appointment/slot" };

    sessionStorage.setItem("access_token", token);
    sessionStorage.setItem("doctorData", JSON.stringify(data));
    naviaget("/appointment/slot", { state, replace: true });
  };

  // ======================== Handle Account Cpmtrol ======================

  const [createAccount] = useCreateAccountMutation();

  const handleAccountControl = async (id, status) => {
    if (window.confirm("Are sure you want to update account status")) {
      const formData = new FormData();
      formData.append("action", "account");
      formData.append("id", id);
      formData.append("status", status);

      const req = await createAccount(formData);

      if (req.data) {
        alert("Account " + status + " Successfully");
        DoctorData(limit, page, "");

      }

      if (req.error) {
        alert("Account " + status + " failed");
      }
    }
  };

  return (
    <div>
      <AdminHeader />
      {/* Doctor List */}
      <div className="PatientsListSection navbarBodyMargin">
        <div className="patientListHead">
          <h1 className="pageTitle">Doctor List</h1>
          <div className="addPatientBtn d-flex align-items-center">
            <Link to="/add-doctor">
              <Tooltip title="Add New Doctor" arrow>
                <button
                  className="Clickbtn"
                  data-bs-toggle="modal"
                  data-bs-target="#addTaskModal"
                >
                  + Add Doctor
                </button>
              </Tooltip>
            </Link>
          </div>
        </div>
        {/* =========== Drugs Search Box ========================= */}
        <div className="DrugsPageSearchBox">
          <div className="searchBoxes">
            <form onSubmit={handleSearchData}>
              <div className="row">
                <div className="col-lg-3 col-md-3 col-12 mt-2">
                  <div className="searchDiv">
                    <input
                      className="form-control"
                      placeholder="Search Doctor"
                      type="text"
                      variant="outlined"
                      name="searchText"
                      fullWidth
                      auto
                      size="small"
                      autoComplete="off"
                      onChange={(e) =>
                        setSearch({ ...search, message: e.target.value })
                      }
                    />
                    <div className="iconsStyle search">
                      <SearchIcon />
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-6 mt-2">
                  <div className="searchDiv">
                    <select
                      className="form-select"
                      name="selectedCatogery"
                      aria-label="demo-simple-select-label"
                      onChange={(e) =>
                        setSearch({ ...search, type: e.target.value })
                      }
                    >
                      <option selected> Search By</option>
                      <option value="id">Doctor ID</option>
                      <option value="name">Doctor Name</option>

                      <option value="phone"> Doctor Phone No</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-6 mt-2 p-0 d-flex justify-content-start align-items-center">
                  <div className="searchDrugBtn d-flex align-items-center">
                    <button type="submit" className="Clickbtn">
                      Search
                    </button>
                    &nbsp;
                    <button
                      type="reset"
                      className="Cancelbtn"
                      onClick={() => DoctorData(limit, page, "")}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="patientsListTable d-lg-block d-md-none d-none">
          {notFound ? (
            <NoDataFound />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Doctor ID</th>
                  <th scope="col">Profile</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Number</th>
                  <th scope="col">Join Date</th>
                  <th scope="col">Renew At</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? generateSkeletonRow()
                  : listData &&
                    listData.map((value, i) => (
                      <tr key={i}>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", color: "#2b5a89" }}
                        >
                          {value.account_create_id}
                        </th>
                        <td className="d-flex justify-content-center">
                          <div className="patientImg">
                            <Avatar src={value.logo} alt={value.first_name + " " + value.last_name} />
                          </div>
                        </td>
                        <td>{value.first_name + " " + value.last_name}</td>
                        <td>{value.account_phone}</td>
                        <td>
                          {moment(value.created_at).format("DD MMM YYYY")}
                        </td>
                        <td>
                          {moment(value.created_at)
                            .add(1, "years")
                            .format("DD MMM YYYY")}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          <span style={value.account_status === "active" ? { color: "green", fontWeight: "600" } :{ color: "red", fontWeight: "600" } }>
                            {value.account_status}
                          </span>
                        </td>
                        <td>
                          <Tooltip title="Access Doctor Account">
                            <div
                              onClick={() =>
                                handleAccessAccounnt(value.access_key, value)
                              }
                              className="iconsStyle edit"
                            >
                              <AiOutlineFundView />
                            </div>
                          </Tooltip>
                        </td>
                        <td>
                          {value.account_status === "active" ? (
                            <Tooltip title="Dactivate Account">
                              <div
                                onClick={() =>
                                  handleAccountControl(
                                    value.account_create_id,
                                    "deactive"
                                  )
                                }
                                className="iconsStyle delete"
                                style={{ cursor: "pointer" }}
                              >
                                <MdDoDisturbAlt />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Activate Account">
                              <div
                                onClick={() =>
                                  handleAccountControl(
                                    value.account_create_id,
                                    "active"
                                  )
                                }
                                className="iconsStyle sucess"
                                style={{ cursor: "pointer" }}
                              >
                                <AiOutlineCheckCircle />
                              </div>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          )}
          <div
            className="paginationDetail d-flex align-items-center justify-content-between"
            style={{
              boxShadow: "3px 7px 10px #eee",
              background: "#fff",
              padding: "10px",
            }}
          >
            <div className="limitDetail d-flex align-items-center">
              <span>Per&nbsp;Page:</span>&nbsp;
              <select
                name=""
                id=""
                className="form-select"
                onChange={handleLimitChange}
              >
                {limit === 10 ? (
                  <option value="10" selected>
                    10
                  </option>
                ) : (
                  <option value="10">10</option>
                )}
                {limit === 20 ? (
                  <option value="20" selected>
                    20
                  </option>
                ) : (
                  <option value="20">20</option>
                )}
                {limit === 50 ? (
                  <option value="50" selected>
                    50
                  </option>
                ) : (
                  <option value="50">50</option>
                )}
                {limit === 100 ? (
                  <option value="100" selected>
                    100
                  </option>
                ) : (
                  <option value="100">100</option>
                )}
              </select>
            </div>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorList;
