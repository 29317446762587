import { Avatar } from "@mui/material";
import moment from "moment";
import React from "react";

export default function SecondDesing({
  onAppointmentFormOpening,
  bookedList,
  formattedDate,
  slotList,
  hospitalId,
  timeSlots,
}) {
  return slotList.map((obj, index) => (
    <div key={index} className="row mb-3">
      <div className="col-md-2 col-12 mb-md-0 mb-2">
        <div className="TimeDisplay">
          <h6>{obj}</h6>
        </div>
      </div>
      <div className="col-md-1 d-md-flex d-none align-items-center">
        <strong>-</strong>
      </div>
      <div className="col-md-9 col-12" style={{ position: "relative" }}>
        <div className="Shedular-List-Data-main-con ">
          <div
            className={`Shedular-List-Data-con ${
              moment().isAfter(
                moment(formattedDate + " " + obj, "YYYY-MM-DD h:mm A")
              )
                ? bookedList.some(
                    (value) => value.appointment_start_time === obj
                  )
                  ? bookedList.filter(
                      (valueData) => valueData.appointment_start_time === obj
                    )[0].appointment_status === "visit"
                    ? "Success-list-card"
                    : "Booked-List-Card"
                  : "Done-List-Card"
                : bookedList.some(
                    (value) => value.appointment_start_time === obj
                  )
                ? bookedList.filter(
                    (valueData) => valueData.appointment_start_time === obj
                  )[0].appointment_status === "visit"
                  ? "Success-list-card"
                  : "Booked-List-Card"
                : "Available-List-Card"
            }`}
            onDoubleClick={() =>
              onAppointmentFormOpening(
                moment().isAfter(
                  moment(formattedDate + " " + obj, "YYYY-MM-DD h:mm A")
                )
                  ? bookedList.some(
                      (value) => value.appointment_start_time === obj
                    )
                    ? "navigateData"
                    : true
                  : bookedList.some(
                      (value) => value.appointment_start_time === obj
                    )
                  ? "navigateData"
                  : false,
                obj,
                hospitalId,
                formattedDate,
                obj,
                "online"
              )
            }
          >
            {bookedList.map((value, i) =>
              value.appointment_start_time === obj ? (
                <>
                  <div key={i} className="Shedular-List-Data-profile-con-Main">
                    <div className="Shedular-List-Data-profile-con">
                      <div className="Shedular-List-Data-profile-icon">
                        <Avatar
                          src={value.patient_profile_Image}
                          alt={value.patient_first_name}
                        />
                      </div>
                      <div className="Shedular-List-Data-profile-Name">
                        <h5>
                          <span>
                            {value.patient_first_name}
                            &nbsp;
                            {value.patient_last_name}
                          </span>
                          &nbsp;
                          <span>
                            (
                            {moment().diff(
                              value.pateint_date_of_birth,
                              "Years"
                            )}
                            &nbsp;Y&nbsp;)
                          </span>
                        </h5>
                        <h5>+91 {value.patient_phone_no}</h5>
                        <h6 className="d-sm-none d-block ">
                          {value.purpose_of_visit !== ""
                            ? `(` + value.purpose_of_visit + `)`
                            : ""}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="Shedular-List-Data-Age-con-Main">
                    <div className="Shedular-List-Data-Age-con">
                      <div className="Shedular-List-Data-Age-Name">
                        <h6 className="d-sm-block d-none">
                          {value.purpose_of_visit !== ""
                            ? `(` + value.purpose_of_visit + `)`
                            : ""}
                        </h6>
                      </div>
                    </div>
                  </div>
                </>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  ));
}
