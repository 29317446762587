import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  FaBars,
  FaHome,
  FaLock,
  FaUser,
  FaUserAlt,
  // FaSignOutAlt,
  FaHospitalAlt,
  FaPencilAlt,
  FaSignInAlt,
  FaUserCircle,
  FaListAlt,
} from "react-icons/fa";
import { GiMedicinePills } from "react-icons/gi";
import { SiGooglemessages } from "react-icons/si";
import { BsFillCalendar2WeekFill, BsListTask } from "react-icons/bs";
import { RiLayout5Fill, RiLinksFill } from "react-icons/ri";
import { AiFillMessage, AiFillSetting } from "react-icons/ai";
// import { BsCartCheck } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { removeToken } from "../../services/localStorageServices";
import { MdEmail, MdMedicalServices } from "react-icons/md";
import { useSideBar } from "../../context/sideBar";
import { Tooltip } from "antd";


const SideBar = ({ children, admin }) => {
  let routes = [];
  if (admin) {
    routes = [
      // {
      //   path: "/dashboard",
      //   name: "Dashboard",
      //   icon: <RiDashboardFill />,
      // },
      {
        path: "/admin-doctor-list",
        name: "Doctors",
        icon: <FaUserCircle />,
      },
      {
        path: "",
        name: "Template",
        icon: <RiLayout5Fill />,
        exact: true,
        subRoutes: [
          {
            path: "/template-setting",
            name: "Template Layouts",
            icon: <RiLayout5Fill />,
          },
          {
            path: "/ForgotPassword/",
            name: "Template Attributes",
            icon: <FaListAlt />,
          },
        ]
      },
      // {
      //   path: "/drugs",
      //   name: "Drugs",
      //   icon: <GiMedicinePills />,
      // },
      // {
      //   path: "/notification",
      //   name: "Message Setting",
      //   icon: <AiFillMessage />,
      //   exact: true,
      //   subRoutes: [
      //     {
      //       path: "/sms",
      //       name: "SMS",
      //       icon: <SiGooglemessages />,
      //     },
      //     {
      //       path: "/email",
      //       name: "Email",
      //       icon: <MdEmail />,
      //     },
      //   ],
      // },
      // {
      //   path: "/settings",
      //   name: "Settings",
      //   icon: <AiFillSetting />,
      //   exact: true,
      //   subRoutes: [
      //     {
      //       path: "/settings/doctor",
      //       name: "Profile",
      //       icon: <FaUser />,
      //     },
      //     {
      //       path: "/ForgotPassword/",
      //       name: "Password Reset",
      //       icon: <FaLock />,
      //     },
      //   ],
      // },
      {
        path: "/",
        name: "Sign Out",
        icon: <FaSignInAlt />,
      },
    ];
  } else {
    routes = [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: <FaHome />,
      },
      {
        path: "/appointment/slot",
        name: "Appointment",
        icon: <BsFillCalendar2WeekFill />,
      },

      {
        path: "/hospitals",
        name: "Hospitals",
        icon: <FaHospitalAlt />,
      },
      {
        path: "/patients",
        name: "Patients",
        icon: <FaUserAlt />,
      },
      {
        path: "/templates",
        name: "Prescription Templates",
        icon: <RiLayout5Fill />,
        exact: true,
        subRoutes: [
          {
            path: "/prescriptionTemplates",
            name: "Templates",
            icon: <RiLayout5Fill />,
          },
          {
            path: "/prescriptionAttributes",
            name: "Attributes",
            icon: <BsListTask />,
          },
        ],
      },
      {
        path: "/drugs",
        name: "Drugs",
        icon: <GiMedicinePills />,
      },
      {
        path: "/referrals",
        name: "Referrals",
        icon: <FaPencilAlt />,
        exact: true,
        subRoutes: [
          {
            path: "/referrals/references",
            name: "References",
            icon: <FaPencilAlt />,
          },
          {
            path: "/referrals/referrals",
            name: "Referrals",
            icon: <FaPencilAlt />,
          },
          {
            path: "/referrals/test",
            name: "Test Referrals",
            icon: <FaPencilAlt />,
          },
          {
            path: "/procedure",
            name: "Procedure Referrals",
            icon: <FaPencilAlt />,
          },
        ],
      },
      {
        path: "/notification",
        name: "Notification",
        icon: <AiFillMessage />,
        exact: true,
        subRoutes: [
          {
            path: "/sms",
            name: "SMS",
            icon: <SiGooglemessages />,
          },
          {
            path: "/email",
            name: "Email",
            icon: <MdEmail />,
          },
        ],
      },
      {
        path: "/services",
        name: "Services",
        icon: <MdMedicalServices />,
      },
      {
        path: "/handouts",
        name: "Handouts",
        icon: <RiLinksFill />,
      },

      {
        path: "/settings",
        name: "Settings",
        icon: <AiFillSetting />,
        exact: true,
        subRoutes: [
          {
            path: "/settings/doctor",
            name: "Profile",
            icon: <FaUser />,
          },
          {
            path: "/ForgotPassword/",
            name: "Password Reset",
            icon: <FaLock />,
          },
        ],
      },
      {
        path: "/",
        name: "Sign Out",
        icon: <FaSignInAlt />,
      },
    ];
  }
  const { page } = useParams();
  const [screenSizeValue, setScreenSizeValue] = useState(0);

  const checkScreenSize = () => {
    const screenWidth = window.innerWidth;
    const newValue = screenWidth < 600 ? "0px" : "45px";
    if (page === "reception") {
      setScreenSizeValue(0);
    } else {
      setScreenSizeValue(newValue);
    }
  };

  useEffect(() => {
    // Add event listener to check screen size on resize
    window.addEventListener("resize", checkScreenSize);

    // Initial check when component mounts
    checkScreenSize();

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isOpen, setIsOpen, toggle } = useSideBar();

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const navigate = useNavigate();
  const logOutFunction = () => {
    removeToken();
    navigate("/");
  };

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "240px" : screenSizeValue,

            transition: {
              duration: 0.5,
              // type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  Stetho Master Admin
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars style={{ color: "#fff" }} onClick={toggle} />
            </div>
          </div>

          <section className="routes">
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
                <NavLink
                  onClick={route.name === "Sign Out" ? logOutFunction : toggle}
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </motion.div>

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
