import React, { useEffect, useRef, useState } from "react";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp.js";
import AddDrugBox from "../components/AddDrug/AddDrugBox";
import { IoSearchOutline } from "react-icons/io5";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Dropdown from "react-bootstrap/Dropdown";
import { BiPlus, BiRightArrowAlt } from "react-icons/bi";
import ScrollContainer from "react-indiana-drag-scroll";
import html2canvas from "html2canvas";
import { GiWallet } from "react-icons/gi";
import {
  BsArrowDownLeftCircleFill,
  BsArrowUpRightCircleFill,
  BsCalendarCheck,
  BsCheck2Circle,
  BsFillCalendarCheckFill,
  BsFillFileEarmarkMedicalFill,
} from "react-icons/bs";
import { BsFillPrinterFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { SlCalender, SlOptionsVertical } from "react-icons/sl";
import { AiFillEdit, AiFillCloseCircle } from "react-icons/ai";
import {
  FaAngleRight,
  FaBriefcaseMedical,
  FaClinicMedical,
  FaEdit,
} from "react-icons/fa";
import { CgNotes } from "react-icons/cg";
import { BsInfoCircleFill } from "react-icons/bs";
import { SiGoogletranslate } from "react-icons/si";
import CircularProgress from "@mui/material/CircularProgress";
import * as htmlToImage from "html-to-image";
import Accordion from "react-bootstrap/Accordion";
import {
  useDiagnosisAddMutation,
  useInsturctionRegisterMutation,
  useAddDoctorDataListMutation,
  useDeleteTypeListMutation,
  useCerficateRegisterMutation,
  useSavePriscriptionMutation,
  useDocumentImageDeleteMutation,
} from "../services/userAuthAPI";
import { getToken } from "../services/localStorageServices";
import "../css/appointmentDetails.css";
// images
import { Button } from "react-bootstrap";
import { Link, NavLink, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import "../../src/components/ComplaintTypeBox/ComplaintTypeBox.css";
import Form from "react-bootstrap/Form";
import "../components/EditDrugBox/EditDrugBox.css";
import medicineShort from "../components/json/medicineShort.json";
import moment from "moment/moment";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddLocationBox from "../components/LocationBox/AddLocationBox";
import Alert from "@mui/material/Alert";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import SaveIcon from "@mui/icons-material/Save";
import ReferalRefrenceModal from "../components/referalRefrenceModal/referalRefrenceModal";
import AddAlleryBox from "../components/AddAllergyBox/AddAlleryBox";
import { Avatar, Backdrop, Box, Drawer, Snackbar, Stack } from "@mui/material";
import ReactToPrint from "react-to-print";
import ReactDOMServer from "react-dom/server";
import language from "../components/json/language.json";
import Certificate from "../components/Templates/certificate";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import NextAppointment from "../components/nextAppointment/nextAppointment";
import { Sticky, StickyContainer } from "react-sticky";
import { message } from "antd";
import {
  getDraftData,
  removeDataFromDraft,
  storeIntoDraft,
} from "../services/Draft";
import HandoutComp from "../components/AppointmentDetailComp/Handout.jsx";
import { useSideBar } from "../context/sideBar.js";
import useTemplateAttribute from "../components/TemplateAtrribute/TemplateAttribute.jsx";
import { useCheckInternet } from "../context/contextCheckOnline.js";
import ActiveTemplateComp from "../components/Templates/ActiveTemplateComp.jsx";

const AppointmentDetail = () => {
  const { isConnected } = useCheckInternet();

  const [mediacalDate, setMediacalDate] = useState({
    sufferingFrom: "",
    sufferingTo: null,
    restTill: "",
    resumeFrom: "",
    moreDetail: "",
  });
  const [isOpen, setIsOpen] = useState();
  const [isOpenSecond, setIsOpenSecond] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const [referalDataList, setReferalDataList] = useState([]);
  const [referenceDataList, setReferenceDataList] = useState([]);
  const [key, setKey] = useState("DIAGNOSIS");
  const [typeData, setTypeData] = useState([]);
  const [viewEditDrug, setViewEditDrug] = useState(false);
  const [listPopup, setListPopup] = useState("");
  const [alleryListFetch, setAlleryListFetch] = useState([]);
  const [foundHospital, setFoundHospital] = useState(false);
  const [attributeId, setAttributeId] = useState(null);
  const [result, setResult] = useState(null);

  // ====================== Diagnosis submit ========================

  const [diagnosisAdd] = useDiagnosisAddMutation();
  const { access_token } = getToken();
  const [apiData, setApiData] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [drugList, setDrugList] = useState([]);
  const [patientInstruction, setPatientInstruction] = useState([]);
  const [alertDiloge, setAlertDiloge] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    "Diagnosis / Complaints / Findings"
  );
  const { selectedHandouts, setSelectedHandouts } = useSideBar();

  const [inputData, setInputData] = useState({
    diagnosis: "",
    findings: "",
    complaints: "",
    drugs: "",
    instruction: "",
    test: "",
    proceedure: "",
    default: "",
  });

  const [patientDetail, setPatientDetail] = useState({
    ID: "",
    name: "",
    gender: "",
    date: "",
    age: "",
    number: "",
    image: "",
    address: "",
    city: "",
    pincode: "",
    language: "",
  });

  const { apid, pid, hid, apdate, aptime } = useParams();
  const [moreEditDrug, setMoreEditDrug] = React.useState();
  const [savePriscription] = useSavePriscriptionMutation();
  const [stateAgain, setStateAgain] = React.useState(false);

  const [otherData, setOtherData] = useState([]);
  const dateTime = {
    date: moment(apdate, "YYYY-MM-DD").format("DD MMM YYYY"),
    time: aptime,
  };
  const [drugDesign, setDrugDesign] = useState([]);

  useEffect(() => {
    const drugDesignData = JSON.parse(sessionStorage.getItem("drugDesign"));
    if (drugDesignData === null || drugDesignData.length === 0) {
      setDrugDesign([]);
    } else {
      setDrugDesign(drugDesignData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ================================ save priscription ============================
  const domEl = useRef(null);
  const domElCertificate = useRef(null);
  const [cerficateRegister] = useCerficateRegisterMutation();
  const [activeSearchKey, setActiveSearchKey] = useState("Diagnosis");

  // ======================== Hide Alert ===============================

  useEffect(() => {
    // Set a timer to execute setBookedAppointment(false) after 10 seconds
    const timerId = setTimeout(() => {
      setAlertDiloge(false);
    }, 5000);

    // Clean up the timer when the component unmounts or the bookedAppointment state changes
    return () => {
      clearTimeout(timerId);
    };
  }, [alertDiloge]);

  const popupListRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside of the div
    function handleClickOutside(event) {
      if (
        popupListRef.current &&
        !popupListRef.current.contains(event.target)
      ) {
        setListPopup(false);
      }
    }

    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =============================== Insert Diagnosis ===============================
  const diagnosisSubmit = async (e) => {
    e.preventDefault();
    const type = e.target.getAttribute("data-type");
    const data = new FormData(e.currentTarget);
    if (data.get("name") === "") {
      message.success("Saved Sucessfully");

      return false;
    } else {
      const dataFetchList = diagnosisList;
      const apiDataList = apiData;
      const min = 1;
      const max = 100;

      const dataList = {
        diagnosis_id: "id_" + Math.floor(min + Math.random() * (max - min)),
        diagnosis_name: data.get("name"),
        type: type,
        diagnosis_duration: data.get("complaindTimeInput")
          ? data.get("complaindTimeInput")
          : "",
        diagnosis_days: data.get("durationInput")
          ? data.get("durationInput")
          : "",
        diagnosis_type: "",
        diagnosis_discription: "",
        newSearhc: "true",
        token: access_token,
      };

      apiDataList.unshift(dataList);
      setApiData([...apiDataList]);

      dataFetchList.unshift(dataList);
      setDiagnosisList([...dataFetchList]);

      e.currentTarget.reset();

      setInputData({
        diagnosis: "",
        findings: "",
        complaints: "",
        drugs: "",
        instruction: "",
        test: "",
        proceedure: "",
        default: "",
      }); // clearing input data
      setListPopup("");
      message.success("Addedd Sucessfully"); // success meassage
      addIntoLocal(); // Storing in draft

      const checkIsDataHas = suggestionList.some(
        (checkDataValue) =>
          checkDataValue.diagnosis_name.toLocaleLowerCase() ===
          data.get("name").toLocaleLowerCase()
      );
      if (!checkIsDataHas) {
        const req = await diagnosisAdd(dataList);
        console.log(req);
        // ========== Adding into local storage ============
        const previousData = JSON.parse(localStorage.getItem("suggestionList"));
        let allPushData = [];
        switch (type) {
          case "Diagnosis":
            allPushData = previousData.diagnosis;
            allPushData.unshift(dataList);
            previousData.diagnosis = allPushData;
            localStorage.setItem(
              "suggestionList",
              JSON.stringify(previousData)
            );
            allPushData = [];
            break;
          case "Complaints":
            allPushData = previousData.complaints;
            allPushData.unshift(dataList);
            previousData.complaints = allPushData;
            localStorage.setItem(
              "suggestionList",
              JSON.stringify(previousData)
            );
            allPushData = [];
            break;
          case "Findings":
            allPushData = previousData.findings;
            allPushData.unshift(dataList);
            previousData.findings = allPushData;
            localStorage.setItem(
              "suggestionList",
              JSON.stringify(previousData)
            );
            allPushData = [];
            break;
          default:
            allPushData = [];
        }
      }
      setSearchDataList({ type: type, text: "" });
    }
  };

  // ================================== Search Data ==================================
  const [loaderActive, setLoaderActive] = useState(false);

  const SearchData = async (type, searchDataText) => {
    setLoaderActive(true);

    const suggestionData = JSON.parse(localStorage.getItem("suggestionList"));
    if (suggestionData) {
      if (searchDataText === "") {
        setLoaderActive(false);
        switch (type) {
          case "Diagnosis":
            setSuggestionList(suggestionData.diagnosis);
            break;
          case "Complaints":
            setSuggestionList(suggestionData.complaints);
            break;
          case "Findings":
            setSuggestionList(suggestionData.findings);
            break;
          case "Drug":
            setSuggestionList(suggestionData.drugs);
            break;
          case "Instruction":
            setSuggestionList(suggestionData.instructions);
            break;
          case "Procedures":
            setSuggestionList(suggestionData.procedures);
            break;
          case "Test":
            setSuggestionList(suggestionData.tests);
            break;
          default:
            setSuggestionList([]);
        }
      }
      if (searchDataText !== "") {
        setLoaderActive(false);
        setListPopup(type);
        let searchResutl;
        switch (type) {
          case "Diagnosis":
            searchResutl = suggestionData.diagnosis.filter((item) =>
              item.diagnosis_name
                .toLowerCase()
                .includes(searchDataText.toLowerCase())
            );
            setSuggestionList(searchResutl);
            break;
          case "Complaints":
            searchResutl = suggestionData.complaints.filter((item) =>
              item.diagnosis_name
                .toLowerCase()
                .includes(searchDataText.toLowerCase())
            );
            setSuggestionList(searchResutl);
            break;
          case "Findings":
            searchResutl = suggestionData.findings.filter((item) =>
              item.diagnosis_name
                .toLowerCase()
                .includes(searchDataText.toLowerCase())
            );
            setSuggestionList(searchResutl);
            break;
          case "Drug":
            searchResutl = suggestionData.drugs.filter((item) =>
              item.brand_name
                .toLowerCase()
                .includes(searchDataText.toLowerCase())
            );
            setSuggestionList(searchResutl);
            break;
          case "Instruction":
            searchResutl = suggestionData.instructions.filter((item) =>
              item.instruction_name
                .toLowerCase()
                .includes(searchDataText.toLowerCase())
            );
            setSuggestionList(searchResutl);
            break;
          case "Procedures":
            searchResutl = suggestionData.procedures.filter((item) =>
              item.instruction_name
                .toLowerCase()
                .includes(searchDataText.toLowerCase())
            );
            setSuggestionList(searchResutl);
            break;
          case "Test":
            searchResutl = suggestionData.tests.filter((item) =>
              item.instruction_name
                .toLowerCase()
                .includes(searchDataText.toLowerCase())
            );
            setSuggestionList(searchResutl);
            break;
          default:
            setSuggestionList([]);
        }

        if (searchResutl.length <= 0) {
          setLoaderActive(true);

          try {
            const response = await fetch(
              "https://skdm.in/skddev/DrCrm/prescription_search.php",
              {
                method: "POST",
                body: JSON.stringify({
                  token: access_token,
                  search_prescription: searchDataText,
                  prescription_type: type,
                }),
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                },
              }
            );
            const data = await response.json();
            setLoaderActive(false);
            if (Array.isArray(data)) {
              setSuggestionList((prev) => [...prev, ...data]);

              let listDataFound = [];
              switch (type) {
                case "Diagnosis":
                  listDataFound = suggestionData.diagnosis;
                  listDataFound = [...listDataFound, ...data];
                  suggestionData.diagnosis = listDataFound;
                  localStorage.setItem(
                    "suggestionList",
                    JSON.stringify(suggestionData)
                  );
                  listDataFound = [];
                  break;
                case "Complaints":
                  listDataFound = suggestionData.complaints;
                  listDataFound = [...listDataFound, ...data];
                  suggestionData.complaints = listDataFound;
                  localStorage.setItem(
                    "suggestionList",
                    JSON.stringify(suggestionData)
                  );
                  listDataFound = [];
                  break;
                case "Findings":
                  listDataFound = suggestionData.findings;
                  listDataFound = [...listDataFound, ...data];
                  suggestionData.findings = listDataFound;
                  localStorage.setItem(
                    "suggestionList",
                    JSON.stringify(suggestionData)
                  );
                  listDataFound = [];
                  break;
                case "Drug":
                  listDataFound = suggestionData.drugs;
                  listDataFound = [...listDataFound, ...data];
                  suggestionData.drugs = listDataFound;
                  localStorage.setItem(
                    "suggestionList",
                    JSON.stringify(suggestionData)
                  );
                  listDataFound = [];
                  break;
                case "Instruction":
                  listDataFound = suggestionData.instructions;
                  listDataFound = [...listDataFound, ...data];
                  suggestionData.instructions = listDataFound;
                  localStorage.setItem(
                    "suggestionList",
                    JSON.stringify(suggestionData)
                  );
                  listDataFound = [];
                  break;
                case "Procedures":
                  listDataFound = suggestionData.procedures;
                  listDataFound = [...listDataFound, ...data];
                  suggestionData.procedures = listDataFound;
                  localStorage.setItem(
                    "suggestionList",
                    JSON.stringify(suggestionData)
                  );
                  listDataFound = [];
                  break;
                case "Test":
                  listDataFound = suggestionData.tests;
                  listDataFound = [...listDataFound, ...data];
                  suggestionData.tests = listDataFound;
                  localStorage.setItem(
                    "suggestionList",
                    JSON.stringify(suggestionData)
                  );
                  listDataFound = [];
                  break;
                default:
                  console.log("no data for push");
              }
            } else {
              setSuggestionList([]);
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  };

  // ======================== Search Data Dbounsing ===============
  const [searchDataList, setSearchDataList] = useState({
    text: "",
    type: "Diagnosis",
  });

  useEffect(() => {
    const delay = 500;
    let timeout;
    // Set a new timeout
    timeout = setTimeout(() => {
      if (searchDataList.type && searchDataList.text) {
        SearchData(searchDataList.type, searchDataList.text);
      } else {
        SearchData(searchDataList.type, "");
      }
    }, delay);

    return () => {
      // Cleanup: clear the timeout when the component unmounts or searchData changes
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDataList]);

  // =========================== Add PAtient Data List =======================

  const checkDataAdded = (type, text) => {
    if (type === "Diagnosis" || type === "Complaints" || type === "Findings") {
      const isHave = diagnosisList.some(
        (value) => value.type === type && value.diagnosis_name === text
      );

      return !isHave;
    } else if (type === "Drug") {
      const isHave = drugList.some((value) => value.drug_name === text);
      return !isHave;
    } else if (
      type === "Test" ||
      type === "Instruction" ||
      type === "Procedures"
    ) {
      const isHave = patientInstruction.some(
        (value) =>
          value.instruction_type === type && value.instruction_name === text
      );

      return !isHave;
    }
  };

  const addDataList = async (
    id,
    type,
    name,
    duration,
    catogery,
    frequency,
    dosg,
    strength,
    relation,
    instruction,
    brand_name,
    generic_name,
    once_in
  ) => {
    if (type === "Diagnosis" || type === "Complaints" || type === "Findings") {
      if (checkDataAdded(type, name)) {
        const dataFetchList = diagnosisList;

        const dataList = {
          diagnosis_id: id,
          diagnosis_name: name,
          type: type,
          diagnosis_duration: "",
          diagnosis_days: "Days",
          diagnosis_type: "",
          diagnosis_discription: "",
        };
        dataFetchList.unshift(dataList);
        setDiagnosisList([...dataFetchList]);
      } else {
        alert("Data Already Addedd");
      }
    } else if (type === "Drug") {
      if (checkDataAdded(type, brand_name)) {
        const dataFetchList = drugList;
        const durationSplit = duration.split(" ");
        const freqensySplit = frequency.split(", ");
        const splitStrength = strength.split(" ");

        const min = 1;
        const max = 100;
        const dataList = {
          drug_id: "id_" + Math.floor(min + Math.random() * (max - min)),
          drug_name: name,
          brand_name: brand_name,
          drug_duration_days: durationSplit[0],
          drug_duration_dayType: durationSplit[1],
          drug_catogery: catogery,
          drug_freq_morning: freqensySplit[0],
          drug_freq_evening: freqensySplit[2],
          drug_freq_afternoon: freqensySplit[1],
          drug_freq_night: freqensySplit[3],
          drug_freq_sos: freqensySplit[4],
          drug_freq_stat: freqensySplit[5],
          drug_dosg: splitStrength[1] === "ml" ? 5 : 1,
          drug_strength: strength,
          drug_relation: relation,
          once_in: once_in ? once_in : "",
          drug_instruction: instruction,
          drug_strip: "",
          drug_tapper_list: [],
          drug_generic_name: generic_name,
          type: "Drugs",
        };

        dataFetchList.unshift(dataList);
        setDrugList([...dataFetchList]);

        // ====================== Drug Array =========================
        const arrayList = drugDesign;
        const designArray = {
          type: "checkView",
        };
        arrayList.unshift(designArray);
        setDrugDesign([...arrayList]);
        sessionStorage.setItem("drugDesign", JSON.stringify(arrayList));
      } else {
        alert("Drug Already Addedd");
      }
      // ========================= Test Instruction ==========================
    } else if (
      type === "Test" ||
      type === "Instruction" ||
      type === "Procedures"
    ) {
      if (checkDataAdded(type, name)) {
        const dataFetchList = patientInstruction;
        const min = 1;
        const max = 100;

        const dataList = {
          instruction_id: "id_" + Math.floor(min + Math.random() * (max - min)),
          instruction_name: name,
          instruction_type: type,
          instruction_location_name: "",
          instruction_location_mobile: "",
          instruction_location_email: "",
          instruction_location_address: "",
          instruction_date: "",
          instruction_note: "",
          newSearch: "true",
        };

        dataFetchList.unshift(dataList);
        setPatientInstruction([...dataFetchList]);
      } else {
        alert("Data Already Addedd");
      }
    }
    setInputData({
      diagnosis: "",
      findings: "",
      complaints: "",
      drugs: "",
      instruction: "",
      test: "",
      proceedure: "",
      default: "",
    }); // Reset input text of search panel
    setListPopup(""); // hide list from input design
    addIntoLocal(); // addign into draft
    setSearchDataList({ text: "", type: type });
  };

  const activeStepComponent = (k) => {
    setKey(k);

    if (k === "DIAGNOSIS") {
      setActiveSearchKey("Diagnosis");
      SearchData("Diagnosis", "");
      setSearchPlaceholder("Diagnosis / Complaints / Findings");
    } else if (k === "PRESCRIPTION") {
      setActiveSearchKey("Drug", "");
      SearchData("Drug", "");
      setSearchPlaceholder("Drugs");
    } else if (k === "INSTRUCTIONS") {
      setActiveSearchKey("Test", "");
      SearchData("Test", "");
      setSearchPlaceholder("Instructions / Procedures / Test");
    }
  };

  // ======================= Remove list from array ==========================

  const removeArrayData = (id) => {
    diagnosisList.splice(id, 1);
    setDiagnosisList([...diagnosisList]);
  };

  // ============================== Update Data ================================
  const [activeModal, setActiveModal] = useState();
  const [complainDetaile, setComplainDetails] = useState({
    id: "",
    complainsName: "",
    type: "",
    discription: "",
  });

  // ====================================== Discription and Type Modal Function ==============================================

  const ClickData = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const diagnosisData = diagnosisList.map((value, i) => {
      if (value.diagnosis_id === complainDetaile.id) {
        return {
          ...value,
          diagnosis_discription: data.get("discription"),
        };
      } else {
        return value;
      }
    });

    setDiagnosisList([...diagnosisData]);
    e.currentTarget.reset();
  };

  // =========================== Complain Modal ========================

  const updateComplain = (e, id, type) => {
    if (type === "daysNumber") {
      const diagnosisData = diagnosisList.map((value, i) => {
        if (value.diagnosis_id === id) {
          return {
            ...value,
            diagnosis_duration: e.target.value,
          };
        } else {
          return value;
        }
      });
      setDiagnosisList([...diagnosisData]);
    }

    if (type === "daysSelect") {
      const diagnosisData = diagnosisList.map((value, i) => {
        if (value.diagnosis_id === id) {
          return {
            ...value,
            diagnosis_days: e.target.value,
          };
        } else {
          return value;
        }
      });
      setDiagnosisList([...diagnosisData]);
    }
  };

  // =========================== Type  Modal ===========================
  const [addDoctorDataList] = useAddDoctorDataListMutation();

  const diagnosisType = (e, type) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const dataFetchList = typeData;

    const min = 1;
    const max = 10;

    const dataList = {
      account_data_id: "type_" + Math.floor(min + Math.random() * (max - min)),
      account_type_name: data.get("typeName"),
      type: "newType",
    };

    dataFetchList.unshift(dataList);
    setTypeData([...dataFetchList]);
    e.currentTarget.reset();

    // ================ Insert Into Database =============
    addDoctorData(data.get("typeName"), "", "", "", type, access_token);
  };

  const addDoctorData = async (name, number, email, address, type, token) => {
    const dataList = {
      account_text_name: email,
      account_type_name: name,
      account_mobile: number,
      account_address: address,
      account_data_type: type,
      token: token,
    };

    const req = await addDoctorDataList(dataList);

    if (req.data) {
      message.success("Addedd Sucessfully");
    }
  };

  // ====================== Remove Type ===============================
  const [deleteTypeList] = useDeleteTypeListMutation();
  const removeDataType = (id, typeId) => {
    typeData.splice(id, 1);
    setTypeData([...typeData]);
    deleteTypeList({ token: access_token, account_data_id: typeId });
  };

  // ===================== Fetch Type Data =========================
  const [testLocation, setTestLocation] = useState();
  const [testLoad, setTestLoad] = useState(false);

  const fetchTypeList = async (type, search) => {
    try {
      setTestLoad(true);
      const response = await fetch(
        "https://skdm.in/skddev/DrCrm/account_data_view.php",
        {
          method: "POST",
          body: JSON.stringify({
            token: access_token,
            account_data_types: type,
            search: search,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const data = await response.json();
      if (Array.isArray(data)) {
        if (type === "Test") {
          setTestLocation(data);
        } else if (type === "Procedures") {
          setTestLocation(data);
        } else {
          setTypeData(data);
        }
      } else {
        setTypeData([]);
        setTestLocation([]);
      }
      setTestLoad(false);
    } catch (error) {
      console.error(error);
    }
  };

  // ======================== Checked Type =========================
  const [checkedValueData, setCheckedValueData] = useState([]);
  const [activeDiabledButton, setActiveDiabledButton] = useState(true);
  const updateData = (id, title, type, discription, modal) => {
    setActiveModal(true);
    setComplainDetails({
      id: id,
      complainsName: title,
      type: type,
      discription: discription,
      modal: modal,
    });
    fetchTypeList(type, "");
    if (modal === "type") {
      setActiveDiabledButton(true);
      if (discription !== "") {
        const spiltType = discription.split(" and ");
        setCheckedValueData(spiltType);
      } else {
        setCheckedValueData([]);
      }
    }
  };

  const checkedValue = (e) => {
    const dataFetchList = checkedValueData;
    setActiveDiabledButton(false);

    if (e.target.checked) {
      dataFetchList.unshift(e.target.value);
    } else {
      dataFetchList.splice(checkedValueData.indexOf(e.target.value), 1);
    }
    setCheckedValueData([...dataFetchList]);
  };

  const saveCheckData = () => {
    const diagnosisData = diagnosisList.map((value, i) => {
      if (value.diagnosis_id === complainDetaile.id) {
        return {
          ...value,
          diagnosis_type: checkedValueData.join(" and "),
        };
      } else {
        return value;
      }
    });

    setDiagnosisList([...diagnosisData]);
    setActiveModal(false);
    document.getElementById("diagnosisTypeForm").reset();
  };

  // ============================================= Drug View =====================================
  const changeDugInput = (id, type) => {
    const diagnosisData = drugDesign.map((value, i) => {
      if (i === id) {
        return {
          ...value,
          type: type,
        };
      } else {
        return value;
      }
    });

    setDrugDesign([...diagnosisData]);
    sessionStorage.setItem("drugDesign", JSON.stringify(diagnosisData));
  };

  const ViewEditDrugData = (id) => {
    setViewEditDrug(true);
    setMoreEditDrug(id);
  };

  // ================================ Update Drug Details =====================================
  const drugUpdate = (e) => {
    const name = e.target.getAttribute("data-name");
    const id = e.target.getAttribute("data-id");

    // ============== Dose =================
    if (name === "dose") {
      const diagnosisData = drugList.map((value, i) => {
        if (value.drug_id === id) {
          return {
            ...value,
            drug_dosg: e.target.value,
          };
        } else {
          return value;
        }
      });

      setDrugList([...diagnosisData]);
    }

    // ================== Duration Day ================
    if (name === "drugDays") {
      const diagnosisData = drugList.map((value, i) => {
        if (value.drug_id === id) {
          return {
            ...value,
            drug_duration_days: e.target.value,
          };
        } else {
          return value;
        }
      });

      setDrugList([...diagnosisData]);
    }

    // ================== Duration Month ================
    if (name === "drugDuration") {
      const diagnosisData = drugList.map((value, i) => {
        if (value.drug_id === id) {
          return {
            ...value,
            drug_duration_dayType: e.target.value,
          };
        } else {
          return value;
        }
      });

      setDrugList([...diagnosisData]);
    }

    // ============ Morning Checked ===============
    if (name === "frequencyMorning") {
      if (e.target.checked === true) {
        const diagnosisData = drugList.map((value, i) => {
          if (value.drug_id === id) {
            return {
              ...value,
              drug_freq_morning: e.target.value,
            };
          } else {
            return value;
          }
        });

        setDrugList([...diagnosisData]);
      } else {
        const dataType = e.target.getAttribute("data-type");

        if (dataType === "inputView") {
          const diagnosisData = drugList.map((value, i) => {
            if (value.drug_id === id) {
              return {
                ...value,
                drug_freq_morning: e.target.value,
              };
            } else {
              return value;
            }
          });
          setDrugList([...diagnosisData]);
        } else {
          const diagnosisData = drugList.map((value, i) => {
            if (value.drug_id === id) {
              return {
                ...value,
                drug_freq_morning: "null",
              };
            } else {
              return value;
            }
          });
          setDrugList([...diagnosisData]);
        }
      }
    }

    // ================= After noon Checked ===========
    if (name === "frequencyAfterNoon") {
      if (e.target.checked === true) {
        const diagnosisData = drugList.map((value, i) => {
          if (value.drug_id === id) {
            return {
              ...value,
              drug_freq_afternoon: e.target.value,
            };
          } else {
            return value;
          }
        });

        setDrugList([...diagnosisData]);
      } else {
        const dataType = e.target.getAttribute("data-type");

        if (dataType === "inputView") {
          const diagnosisData = drugList.map((value, i) => {
            if (value.drug_id === id) {
              return {
                ...value,
                drug_freq_afternoon: e.target.value,
              };
            } else {
              return value;
            }
          });
          setDrugList([...diagnosisData]);
        } else {
          const diagnosisData = drugList.map((value, i) => {
            if (value.drug_id === id) {
              return {
                ...value,
                drug_freq_afternoon: "null",
              };
            } else {
              return value;
            }
          });
          setDrugList([...diagnosisData]);
        }
      }
    }
    // ==================== Evening Checked =======================
    if (name === "frequencyEvening") {
      if (e.target.checked === true) {
        const diagnosisData = drugList.map((value, i) => {
          if (value.drug_id === id) {
            return {
              ...value,
              drug_freq_evening: e.target.value,
            };
          } else {
            return value;
          }
        });

        setDrugList([...diagnosisData]);
      } else {
        const dataType = e.target.getAttribute("data-type");

        if (dataType === "inputView") {
          const diagnosisData = drugList.map((value, i) => {
            if (value.drug_id === id) {
              return {
                ...value,
                drug_freq_evening: e.target.value,
              };
            } else {
              return value;
            }
          });
          setDrugList([...diagnosisData]);
        } else {
          const diagnosisData = drugList.map((value, i) => {
            if (value.drug_id === id) {
              return {
                ...value,
                drug_freq_evening: "null",
              };
            } else {
              return value;
            }
          });
          setDrugList([...diagnosisData]);
        }
      }
    }

    // ============== Night Checked ===============
    if (name === "frequencyNight") {
      if (e.target.checked === true) {
        const diagnosisData = drugList.map((value, i) => {
          if (value.drug_id === id) {
            return {
              ...value,
              drug_freq_night: e.target.value,
            };
          } else {
            return value;
          }
        });

        setDrugList([...diagnosisData]);
      } else {
        const dataType = e.target.getAttribute("data-type");

        if (dataType === "inputView") {
          const diagnosisData = drugList.map((value, i) => {
            if (value.drug_id === id) {
              return {
                ...value,
                drug_freq_night: e.target.value,
              };
            } else {
              return value;
            }
          });
          setDrugList([...diagnosisData]);
        } else {
          const diagnosisData = drugList.map((value, i) => {
            if (value.drug_id === id) {
              return {
                ...value,
                drug_freq_night: "null",
              };
            } else {
              return value;
            }
          });
          setDrugList([...diagnosisData]);
        }
      }
    }

    // ================= Sos Checked =================
    if (name === "frequencySos") {
      if (e.target.checked === true) {
        const diagnosisData = drugList.map((value, i) => {
          if (value.drug_id === id) {
            return {
              ...value,
              drug_freq_sos: e.target.value,
            };
          } else {
            return value;
          }
        });

        setDrugList([...diagnosisData]);
      } else {
        const diagnosisData = drugList.map((value, i) => {
          if (value.drug_id === id) {
            return {
              ...value,
              drug_freq_sos: "null",
            };
          } else {
            return value;
          }
        });

        setDrugList([...diagnosisData]);
      }
    }
    // ================ Stat Checked ====================
    if (name === "frequencyStat") {
      if (e.target.checked === true) {
        const diagnosisData = drugList.map((value, i) => {
          if (value.drug_id === id) {
            return {
              ...value,
              drug_freq_stat: e.target.value,
            };
          } else {
            return value;
          }
        });

        setDrugList([...diagnosisData]);
      } else {
        const dataType = e.target.getAttribute("data-type");

        if (dataType === "inputView") {
          const diagnosisData = drugList.map((value, i) => {
            if (value.drug_id === id) {
              return {
                ...value,
                drug_freq_stat: e.target.value,
              };
            } else {
              return value;
            }
          });
          setDrugList([...diagnosisData]);
        } else {
          const diagnosisData = drugList.map((value, i) => {
            if (value.drug_id === id) {
              return {
                ...value,
                drug_freq_stat: "null",
              };
            } else {
              return value;
            }
          });
          setDrugList([...diagnosisData]);
        }
      }
    }

    // ========================= Food Relation =====================
    if (name === "foodRelation") {
      const diagnosisData = drugList.map((value, i) => {
        if (value.drug_id === id) {
          return {
            ...value,
            drug_relation: e.target.value,
          };
        } else {
          return value;
        }
      });

      setDrugList([...diagnosisData]);
    }

    // =================== Drug Instruction ============================
    if (name === "drugInstruction") {
      const diagnosisData = drugList.map((value, i) => {
        if (value.drug_id === id) {
          return {
            ...value,
            drug_instruction: e.target.value,
          };
        } else {
          return value;
        }
      });

      setDrugList([...diagnosisData]);
    }

    // ====================== Drug Strip ==========================
    if (name === "drugStrip") {
      const diagnosisData = drugList.map((value, i) => {
        if (value.drug_id === id) {
          return {
            ...value,
            drug_strip: e.target.value,
          };
        } else {
          return value;
        }
      });

      setDrugList([...diagnosisData]);
    }

    // ================ Drug Once In ==================

    if (name === "onceIn") {
      const diagnosisData = drugList.map((value, i) => {
        if (value.drug_id === id) {
          return {
            ...value,
            once_in: e.target.value,
          };
        } else {
          return value;
        }
      });

      setDrugList([...diagnosisData]);
    }
  };
  // =================================== Remove All Drug ======================

  const removeAllDrug = () => {
    setDrugList([]);
  };

  const removeMedicine = (id) => {
    drugList.splice(id, 1);
    setDrugList([...drugList]);

    drugDesign.splice(id, 1);
    setDrugDesign([...drugDesign]);
    sessionStorage.setItem("drugDesign", JSON.stringify(drugDesign));
  };

  // ========================= Tapper List ===========================

  const [taperList, setTaperList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleFromDateChange = (newDate, index) => {
    let newData = [...taperList];
    const dateUpdate = new Date(newDate);
    const dateFormate = moment(dateUpdate).format("YYYY-MM-DD");
    newData[index].fromDate = dateFormate;
    setTaperList(newData);
    setFromDate(dateFormate);
  };

  const handleDaysChange = (e, index) => {
    let newData = [...taperList];
    newData[index].days = e.target.value;
    setTaperList(newData);
    if (e.target.value) {
      let newToDate = new Date(fromDate);
      newToDate.setDate(newToDate.getDate() + parseInt(e.target.value, 10));
      let toDateString =
        newToDate.getFullYear() +
        "-" +
        (newToDate.getMonth() + 1) +
        "-" +
        newToDate.getDate();
      newData[index].toDate = toDateString;
      setTaperList(newData);
      setToDate(toDateString);
    }
  };

  const addTaperList = (drugId) => {
    let setFromDateData = "";
    const taperListData = taperList;

    if (toDate !== "") {
      let newDate = new Date(toDate);
      newDate.setDate(newDate.getDate() + 1);
      setFromDateData = newDate.toISOString().slice(0, 10); // Format the date as "YYYY-MM-DD"
    } else {
      setFromDateData = "";
    }

    setFromDate(setFromDateData);

    const min = 1;
    const max = 100;

    const dataList = {
      id: "id_" + Math.floor(min + Math.random() * (max - min)),
      fromDate: setFromDateData,
      toDate: "",
      days: "",
      dose: "",
      taper_freq_morning: "null",
      taper_freq_afternoon: "null",
      taper_freq_evening: "null",
      taper_freq_night: "null",
      taper_freq_sos: "null",
      drug_id: drugId,
    };
    taperListData.unshift(dataList);
    setTaperList([...taperListData]);
  };

  // ====================== dose update ========================

  const handleDoseChange = (e, index) => {
    let newData = [...taperList];
    newData[index].dose = e.target.value;
    setTaperList(newData);
  };

  // ==================== Tapper Frequency ======================

  const tapperFrequency = (e, index) => {
    const name = e.target.getAttribute("data-name");

    if (name === "tapperMorning") {
      if (e.target.checked === true) {
        let newData = [...taperList];
        newData[index].taper_freq_morning = e.target.value;
        setTaperList(newData);
      } else {
        let newData = [...taperList];
        newData[index].taper_freq_morning = "null";
        setTaperList(newData);
      }
    }

    if (name === "tapperAfterNoon") {
      if (e.target.checked === true) {
        let newData = [...taperList];
        newData[index].taper_freq_afternoon = e.target.value;
        setTaperList(newData);
      } else {
        let newData = [...taperList];
        newData[index].taper_freq_afternoon = "null";
        setTaperList(newData);
      }
    }

    if (name === "tapperEvening") {
      if (e.target.checked === true) {
        let newData = [...taperList];
        newData[index].taper_freq_evening = e.target.value;
        setTaperList(newData);
      } else {
        let newData = [...taperList];
        newData[index].taper_freq_evening = "null";
        setTaperList(newData);
      }
    }

    if (name === "tapperNight") {
      if (e.target.checked === true) {
        let newData = [...taperList];
        newData[index].taper_freq_night = e.target.value;
        setTaperList(newData);
      } else {
        let newData = [...taperList];
        newData[index].taper_freq_night = "null";
        setTaperList(newData);
      }
    }

    if (name === "tapperSOS") {
      if (e.target.checked === true) {
        let newData = [...taperList];
        newData[index].taper_freq_sos = e.target.value;
        setTaperList(newData);
      } else {
        let newData = [...taperList];
        newData[index].taper_freq_sos = "null";
        setTaperList(newData);
      }
    }
  };

  // ========================== Remove Taper List ===================

  const removeTaper = (id) => {
    taperList.splice(id, 1);
    setTaperList([...taperList]);
  };

  // ====================== Update Final ==============================

  const updateFinalDrug = (id, index) => {
    let newData = [...drugList];

    const taperListData = taperList.filter((value) => value.drug_id === id);

    newData[index].drug_tapper_list = taperListData;
    setDrugList(newData);

    setViewEditDrug(false);
  };

  // ========================================== Instruction Add Update =======================================
  const [insturctionRegister] = useInsturctionRegisterMutation();
  const instructionSubmit = async (e) => {
    e.preventDefault();

    const type = e.target.getAttribute("data-type");
    const data = new FormData(e.currentTarget);
    if (data.get("name") === "") {
      message.success("Addedd Sucessfully");

      return false;
    } else {
      const dataFetchList = patientInstruction; // patient instruction date get previous
      const apiDataList = apiData;
      const min = 1;
      const max = 100;

      const dataList = {
        instruction_id: "id_" + Math.floor(min + Math.random() * (max - min)), // generating random id
        instruction_name: data.get("name"), // type instruction
        instruction_type: type, // instruction type
        instruction_location_name: "",
        instruction_location_mobile: "",
        instruction_location_email: "",
        instruction_location_address: "",
        instruction_date: "",
        instruction_note: "",
        newSearch: "true",
        token: access_token,
      };
      apiDataList.unshift(dataList);
      setApiData([...apiDataList]);

      dataFetchList.unshift(dataList);
      setPatientInstruction([...dataFetchList]); // pusing data with previous data patient insstruction

      e.target.reset();

      setInputData({
        diagnosis: "",
        findings: "",
        complaints: "",
        drugs: "",
        instruction: "",
        test: "",
        proceedure: "",
        default: "",
      }); // clearing input data
      setListPopup("");
      message.success("Addedd Sucessfully"); // success meassage
      addIntoLocal(); // Storing in draft

      const checkIsDataHas = suggestionList.some(
        (checkDataValue) =>
          checkDataValue.instruction_name.toLocaleLowerCase() ===
          data.get("name").toLocaleLowerCase()
      );
      if (!checkIsDataHas) {
        const res = await insturctionRegister(dataList); // adding into databse
        console.log(res);

        // ========== Adding into local storage ============
        const previousData = JSON.parse(localStorage.getItem("suggestionList"));
        let allPushData = [];
        switch (type) {
          case "Instruction":
            allPushData = previousData.instructions;
            allPushData.unshift(dataList);
            previousData.instructions = allPushData;
            localStorage.setItem(
              "suggestionList",
              JSON.stringify(previousData)
            );
            allPushData = [];
            break;
          case "Procedures":
            allPushData = previousData.procedures;
            allPushData.unshift(dataList);
            previousData.procedures = allPushData;
            localStorage.setItem(
              "suggestionList",
              JSON.stringify(previousData)
            );
            allPushData = [];
            break;
          case "Test":
            allPushData = previousData.tests;
            allPushData.unshift(dataList);
            previousData.tests = allPushData;
            localStorage.setItem(
              "suggestionList",
              JSON.stringify(previousData)
            );
            allPushData = [];
            break;
          default:
            allPushData = [];
        }
      }
      setSearchDataList({ type: type, text: "" });
      return false;
    }
  };

  // ========================= Date Change Test Instruction ======================

  const testDateChange = (newDate, index) => {
    let newData = [...patientInstruction];

    const formattedDate = new Date(newDate);
    const dateFormate = moment(formattedDate).format("YYYY-MM-DD");

    newData[index].instruction_date = dateFormate;
    setPatientInstruction(newData);

    setIsOpen("");
    setIsOpenSecond("");
  };

  // ========================= Remove Instruction List =================================

  const removeInstructionList = (id) => {
    patientInstruction.splice(id, 1);
    setPatientInstruction([...patientInstruction]);
  };

  // ============== Location Modal =====================

  const [showideLocation, setShowideLocation] = useState(false);
  const [indexData, setIndexData] = useState();
  const [locationType, setLocationType] = useState();

  const handleActiveLoction = (index, type) => {
    setShowideLocation(true);
    setIndexData(index);
    fetchTypeList(type, "");
    setLocationType(type);
  };

  // ======================= Set Advise and done ========================

  const instructionNote = (e, index) => {
    const note = e.target.getAttribute("data-note");
    let newData = [...patientInstruction];
    newData[index].instruction_note = note;
    setPatientInstruction(newData);
  };

  // ================================== Patient Detail fetch ============================
  // ====================================== language update ===================================
  const [languageList, setLanguageList] = useState();
  const [selectLanguage, setSelectLanguage] = useState("english");

  const selectedFunction = (e) => {
    const selectedLanguage = e.currentTarget;
    if (selectedLanguage.checked) {
      setSelectLanguage(selectedLanguage.value);
      message.success("language changed to " + selectedLanguage.value);
    }
  };

  const PatientDetailSet = async (pid) => {
    const data = JSON.parse(sessionStorage.getItem("appointment_list"));
    if (data && data.length > 0) {
      const foundData = data.filter(
        (filtData) => filtData.patient_id_reff === pid
      );

      setPatientDetail({
        ID: foundData[0].patient_id_reff,
        name:
          foundData[0].patient_first_name +
          " " +
          (foundData[0].patient_last_name !== null &&
          foundData[0].patient_last_name !== "null"
            ? foundData[0].patient_last_name
            : ""),
        number: foundData[0].patient_phone_no,
        date: foundData[0].pateint_date_of_birth,
        gender: "Male",
        age:
          moment().diff(foundData[0].pateint_date_of_birth, "years") +
          " Yrs " +
          (moment().diff(foundData[0].pateint_date_of_birth, "months") % 12) +
          " Mnth",
        templateAge: moment().diff(foundData[0].pateint_date_of_birth, "years"),
        image: foundData[0].patient_profile_Image,
        address: foundData[0].patient_full_address,
        city: foundData[0].city,
        pincode: foundData[0].pincode,
        language: foundData[0].language,
      });
      setSelectLanguage(
        foundData[0].language ? foundData[0].language.toLowerCase() : "english"
      );
      setAlleryListFetch(foundData[0].allergy);
    }
  };

  useEffect(() => {
    const englishLanguage = language.filter(
      (value) => value.language === selectLanguage
    );

    setLanguageList(englishLanguage);
  }, [selectLanguage]);

  // ==================================== Template Design ==============================
  const [templateSetting, setTemplateSetting] = useState({
    fontStyle: "'Roboto', sans-serif",
    fontSize: "14",
    headerHeight: 0,
    footerHeight: 0,
    leftSpace: 0,
    rightSpace: 0,
    doctorDetails: "",
    letterHead: null,
    signature: null,
  });
  const [activeTemplate, setActiveTemplate] = useState();

  const templateListFetch = () => {
    const localData = sessionStorage.getItem("template");
    if (localData === null || localData.length === 0) {
      fetch("https://skdm.in/skddev/DrCrm/fetch_template_property.php", {
        method: "post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          token: access_token, // Replace with your actual access token
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          SettingArrayData(data);
          sessionStorage.setItem("template", JSON.stringify(data));
        });
    } else {
      SettingArrayData(JSON.parse(localData));
    }
  };

  const SettingArrayData = (fetchData) => {
    if (fetchData && fetchData.length > 0) {
      const activeTemplateSetting = fetchData.filter(
        (value) => value.hospitalId === hid
      );
      if (activeTemplateSetting && activeTemplateSetting.length > 0) {
        setActiveTemplate(activeTemplateSetting[0].template_name);
        setAttributeId(activeTemplateSetting[0].template_attribute.split(","));
        setFoundHospital(true);
        setTemplateSetting({
          ...templateSetting,
          fontStyle:
            activeTemplateSetting[0].template_style.template_font_style,
          fontSize: activeTemplateSetting[0].template_style.template_size,
          footerHeight:
            activeTemplateSetting[0].template_style.template_footer_height,
          headerHeight:
            activeTemplateSetting[0].template_style.template_header_height,
          leftSpace:
            activeTemplateSetting[0].template_style.template_left_margin,
          rightSpace:
            activeTemplateSetting[0].template_style.template_right_margin,
          letterHead:
            activeTemplateSetting[0].template_style.template_letter_head,
          signature: activeTemplateSetting[0].template_style.template_signature,
          doctorDetails:
            activeTemplateSetting[0].template_style.template_doctor_detail,
        });
      } else {
        setFoundHospital(false);
        setActiveTemplate("Template 1");
        setTemplateSetting({
          fontStyle: "'Roboto', sans-serif",
          fontSize: "14",
          headerHeight: 0,
          footerHeight: 0,
          leftSpace: 0,
          rightSpace: 0,
          doctorDetails: "",
          letterHead: null,
          signature: null,
        });
      }
    }
  };

  // ======================== Drug Instruction =========================

  const drugInstructionSubmit = (e, type) => {
    e.preventDefault();

    if (type === "drugInstruction") {
      const data = new FormData(e.currentTarget);
      const arrayData = otherData;
      const findDataFirst = otherData.some(
        (value, i) => value.patient_desc_type === "Drug"
      );
      const index = otherData.findIndex(
        (value) => value.patient_desc_type === "Drug"
      );
      if (data.get("drugInstruction") !== "") {
        if (!findDataFirst) {
          const listOtherData = {
            patient_desc_text: data.get("drugInstruction"),
            patient_desc_type: "Drug",
            follow_up: "",
          };
          arrayData.unshift(listOtherData);
          setOtherData([...arrayData]);

          message.success("Instruction Saved");
        } else {
          let newData = [...otherData];
          newData[index].patient_desc_text = data.get("drugInstruction");
          setOtherData(newData);

          message.success("Instruction Updated");
        }
      } else {
        if (findDataFirst) {
          otherData.splice(index, 1);
          setOtherData([...otherData]);

          message.success("Instruction Updated");
        }
      }
    }

    if (type === "diagnosisInstruction") {
      const data = new FormData(e.currentTarget);
      const arrayData = otherData;
      const findDataFirst = otherData.some(
        (value) => value.patient_desc_type === "Diagnosis"
      );
      const index = otherData.findIndex(
        (value, i) => value.patient_desc_type === "Diagnosis"
      );
      if (data.get("diagnosisInstruction") !== "") {
        if (!findDataFirst) {
          const listOtherData = {
            patient_desc_text: data.get("diagnosisInstruction"),
            patient_desc_type: "Diagnosis",
            follow_up: "",
          };
          arrayData.unshift(listOtherData);
          setOtherData([...arrayData]);

          message.success("Clinical Note Saved");
        } else {
          let newData = [...otherData];
          newData[index].patient_desc_text = data.get("diagnosisInstruction");
          setOtherData(newData);

          message.success("Clinical Note Updated");
        }
      } else {
        if (findDataFirst) {
          otherData.splice(index, 1);
          setOtherData([...otherData]);

          message.success("Clinical Note Updated");
        }
      }
    }

    addIntoLocal();
  };

  // =============================== Follow Date ==================================

  const followDateChange = (e, type, dayType) => {
    e.preventDefault();
    const value = e.target.value;
    const findDataFirst = otherData.some(
      (value, i) => value.patient_desc_type === "Follow"
    );
    const index = otherData.findIndex(
      (value) => value.patient_desc_type === "Follow"
    );
    const arrayData = otherData;

    // ===================== Calender Update =====================
    if (type === "date") {
      if (!findDataFirst) {
        const listOtherData = {
          patient_desc_text: "",
          patient_desc_type: "Follow",
          follow_up: value,
        };
        arrayData.unshift(listOtherData);
        setOtherData([...arrayData]);

        message.success("Follow Up Date Updated");
      } else {
        let newData = [...otherData];
        newData[index].follow_up = value;
        setOtherData(newData);

        message.success("Follow Up Date Updated");
      }
    }

    // ===================== Number Select =======================

    if (type === "number") {
      message.success("Follow Up Date Updated");

      let currentDate = new Date();
      let calculatedDate;
      if (dayType === "day") {
        calculatedDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1 + parseInt(value, 10)
        );
      } else if (dayType === "week") {
        calculatedDate = new Date(
          currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
        );
      } else if (dayType === "month") {
        calculatedDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          currentDate.getDate() + 1
        );
      } else if (dayType === "year") {
        calculatedDate = new Date(
          currentDate.getFullYear() + 1,
          currentDate.getMonth(),
          currentDate.getDate() + 1
        );
      }

      if (!findDataFirst) {
        const listOtherData = {
          patient_desc_text: "",
          patient_desc_type: "Follow",
          follow_up: calculatedDate.toISOString().split("T")[0],
        };
        arrayData.unshift(listOtherData);
        setOtherData([...arrayData]);

        message.success("Follow Up Date Updated");
      } else {
        let newData = [...otherData];
        newData[index].follow_up = calculatedDate.toISOString().split("T")[0];
        setOtherData(newData);

        message.success("Follow Up Date Updated");
      }
    }

    // ================== Date Select Follow Up ====================
    if (type === "selectedDate") {
      message.success("Follow Up Date Updated");

      let currentDate = new Date();
      let calculatedDate;
      let data = new FormData(e.currentTarget);
      if (data.get("duration") === "days") {
        calculatedDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1 + parseInt(data.get("daysNumber"), 10)
        );
      } else if (data.get("duration") === "months") {
        calculatedDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + parseInt(data.get("daysNumber"), 10),
          currentDate.getDate() + 1
        );
      } else if (data.get("duration") === "weeks") {
        calculatedDate = new Date(
          currentDate.getFullYear() + parseInt(data.get("daysNumber"), 10),
          currentDate.getMonth(),
          currentDate.getDate() + 1
        );
      }

      if (!findDataFirst) {
        const listOtherData = {
          patient_desc_text: "",
          patient_desc_type: "Follow",
          follow_up: calculatedDate.toISOString().split("T")[0],
        };
        arrayData.unshift(listOtherData);
        setOtherData([...arrayData]);

        message.success("Follow Up Date Updated");
      } else {
        let newData = [...otherData];
        newData[index].follow_up = calculatedDate.toISOString().split("T")[0];
        setOtherData(newData);

        message.success("Follow Up Date Updated");
      }
    }

    // =================== Follow Up Note Update ================

    if (type === "note") {
      let data = new FormData(e.currentTarget);

      if (data.get("noteInput") !== "") {
        if (findDataFirst) {
          let newData = [...otherData];
          newData[index].patient_desc_text = data.get("noteInput");
          setOtherData(newData);

          message.success("Follow Up Note Updated");
        }
      }
    }

    // ====================== Reset Follow Up Date ======================
    if (type === "reset") {
      if (findDataFirst) {
        otherData.splice(index, 1);
        setOtherData([...otherData]);
        message.success("Follow Up Reset");
      }
    }

    addIntoLocal();
  };

  // =================== Referal Refrence Modal ==================
  const [showModal, setShowModal] = useState(false);
  const [referalType, setReferalType] = useState("");
  const [selectedRefrence, setSelectedRefrence] = useState({
    doctor_referal_name: "",
    doctor_referal_specialization: "",
    doctor_referal_mobile: "",
    doctor_referal_email: "",
    doctor_referal_address: "",
    typeData: "",
  });

  const [selectedReferal, setSelectedReferal] = useState({
    doctor_referal_name: "",
    doctor_referal_specialization: "",
    doctor_referal_mobile: "",
    doctor_referal_email: "",
    doctor_referal_address: "",
    typeData: "",
  });

  const referalRefrence = (type) => {
    setShowModal(true);
    setReferalType(type);
  };

  const referalRefrenceSelected = (e, id, typeList) => {
    const filterData = referalDataList.filter(
      (value) =>
        value.doctor_references_id === id &&
        value.doctor_references_type === typeList
    );
    if (e.target.checked) {
      setSelectedRefrence({
        doctor_referal_name: filterData[0].doctor_references_name,
        doctor_referal_specialization:
          filterData[0].doctor_refrences_specialization,
        doctor_referal_mobile: filterData[0].doctor_refernces_mobile,
        doctor_referal_email: filterData[0].doctor_refernces_email,
        doctor_referal_address: filterData[0].doctor_referncesl_address,
        typeData: filterData[0].doctor_references_type,
      });
    } else {
      setSelectedRefrence({
        doctor_referal_name: "",
        doctor_referal_specialization: "",
        doctor_referal_mobile: "",
        doctor_referal_email: "",
        doctor_referal_address: "",
        typeData: "",
      });
    }
  };

  const referalSelected = (e, id, typeList) => {
    const filterData = referenceDataList.filter(
      (value) =>
        value.doctor_references_id === id &&
        value.doctor_references_type === typeList
    );

    if (e.target.checked) {
      setSelectedReferal({
        doctor_referal_name: filterData[0].doctor_references_name,
        doctor_referal_specialization:
          filterData[0].doctor_refrences_specialization,
        doctor_referal_mobile: filterData[0].doctor_refernces_mobile,
        doctor_referal_email: filterData[0].doctor_refernces_email,
        doctor_referal_address: filterData[0].doctor_referncesl_address,
        typeData: filterData[0].doctor_references_type,
      });
    } else {
      setSelectedReferal({
        doctor_referal_name: "",
        doctor_referal_specialization: "",
        doctor_referal_mobile: "",
        doctor_referal_email: "",
        doctor_referal_address: "",
        typeData: "",
      });
    }
  };

  // ================================ Patient History============================
  const [historyList, setHistoryList] = useState([]);
  const [vitalData, setVitalData] = useState([]);

  const patientHistoryFetch = async (pid) => {
    try {
      const response = await fetch(
        "https://skdm.in/skddev/DrCrm/patient_history_view.php",
        {
          method: "POST",
          body: JSON.stringify({
            token: access_token,
            patient_id: pid,
            action: "patientHistory",
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const data = await response.json();
      setHistoryList(data);
      if (data.length > 0) {
        setVitalData(data[0].patient_vital);

        if (data[0].appointmentID === apid) {
          const diagnosisData = data[0].diagnosis_data;
          const drugData = data[0].drug_data;
          const instructionData = data[0].instruction_data;
          const tapperList = data[0].drug_duration;
          const otherDataList = data[0].patientDesc_data;
          const handoutData = data[0].handouts;

          const arrayList = [];
          const arrayDrugList = [];
          const arrayInstructionList = [];

          const min = 1;
          const max = 100;

          if (diagnosisData.length > 0) {
            // ========================== diagnosis Data ==========================
            diagnosisData.forEach((value) => {
              const diagnosisList = {
                diagnosis_id:
                  "id_" + Math.floor(min + Math.random() * (max - min)),
                diagnosis_name: value.diagnosis_name,
                type: value.type,
                diagnosis_duration: value.diagnosis_duration.split(" ")[0],
                diagnosis_days: value.diagnosis_duration.split(" ")[1],
                diagnosis_type:
                  value.diagnosis_name_type_complain === null
                    ? ""
                    : value.diagnosis_name_type_complain,
                diagnosis_discription:
                  value.diagnosis_description === null
                    ? ""
                    : value.diagnosis_description,
                newSearhc: "true",
                token: access_token,
              };

              arrayList.unshift(diagnosisList);
            });
            setDiagnosisList([...arrayList]);
          }

          // ========================== Drug Data ==========================

          if (drugData.length > 0) {
            const arrayDesignList = [];
            // ======================================== Tapper Data ================================

            if (tapperList.length > 0) {
              const taperListData = [];

              tapperList.forEach((value) => {
                const dataList = {
                  id: "id_" + Math.floor(min + Math.random() * (max - min)),
                  fromDate: value.start_date,
                  toDate: value.end_date,
                  days: value.drug_duration,
                  dose: value.drug_dose,
                  taper_freq_morning: value.drug_frequency.split(", ")[0],
                  taper_freq_afternoon: value.drug_frequency.split(", ")[1],
                  taper_freq_evening: value.drug_frequency.split(", ")[2],
                  taper_freq_night: value.drug_frequency.split(", ")[3],
                  taper_freq_sos: value.drug_frequency.split(", ")[4],
                  drug_id: value.pateint_drug_id_reff,
                };
                taperListData.unshift(dataList);
              });

              setTaperList([...taperListData]);
            }
            // ===================== Drug List ===========================
            drugData.forEach((value) => {
              const matchingDrug = taperList.filter(
                (drug) => drug.drug_id === value.pateint_drug_id
              );

              const drugList = {
                drug_id: value.pateint_drug_id,
                drug_name: value.drug_name,
                brand_name: value.brand_name,
                drug_duration_days: value.duration.split(" ")[0],
                drug_duration_dayType: value.duration.split(" ")[1],
                drug_catogery: value.medicine_category,
                drug_freq_morning: value.frequency.split(", ")[0],
                drug_freq_evening: value.frequency.split(", ")[2],
                drug_freq_afternoon: value.frequency.split(", ")[1],
                drug_freq_night: value.frequency.split(", ")[3],
                drug_freq_sos: value.frequency.split(", ")[4],
                drug_freq_stat: value.frequency.split(", ")[5],
                drug_dosg: value.drug_dose,
                drug_strength: value.strength_medicine,
                drug_relation: value.relation_of_food,
                drug_instruction: value.drug_instruction,
                drug_strip: value.drug_strip,
                drug_tapper_list: matchingDrug.length > 0 ? matchingDrug : [],
                drug_generic_name: value.generic_name,
                type: "Drugs",
              };

              const designArray = {
                type: "checkView",
              };

              arrayDesignList.unshift(designArray);
              arrayDrugList.unshift(drugList);
            });

            setDrugDesign(arrayDesignList);
            sessionStorage.setItem(
              "drugDesign",
              JSON.stringify(arrayDesignList)
            );

            setDrugList([...arrayDrugList]);
          }

          if (instructionData.length > 0) {
            // ===================================== Patient Instruction ==============================
            instructionData.forEach((value) => {
              const dataList = {
                instruction_id:
                  "id_" + Math.floor(min + Math.random() * (max - min)),
                instruction_name: value.instruction_patient_name,
                instruction_type: value.instruction_patient_type,
                instruction_location_name: value.instruction_location_name,
                instruction_location_mobile: value.instruction_location_mobile,
                instruction_location_email: value.instruction_location_email,
                instruction_location_address:
                  value.instruction_location_address,
                instruction_date:
                  value.instruction_patient_date === "0000-00-00"
                    ? ""
                    : value.instruction_patient_date,
                instruction_note: value.instruction_note,
                newSearch: "true",
                token: access_token,
              };

              arrayInstructionList.unshift(dataList);
            });

            setPatientInstruction([...arrayInstructionList]);
          }

          if (otherDataList.length > 0) {
            setOtherData(otherDataList);
          }

          if (handoutData.length > 0) {
            setSelectedHandouts(handoutData);
          }

          // =========================== Other Data Like follow up, drug instruction etc =======================
        } else {
          setVitalData([]);
          setPatientInstruction([]);
          setDrugList([]);
          setTaperList([]);
          setDiagnosisList([]);
          setOtherData([]);
          setSelectedHandouts([]);
        }
      } else {
        setVitalData([]);
        setPatientInstruction([]);
        setDrugList([]);
        setTaperList([]);
        setDiagnosisList([]);
        setOtherData([]);
        setSelectedHandouts([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { userData } = getDraftData(pid, apdate, hid);

  useEffect(() => {
    let dbounse;
    dbounse = setTimeout(() => {
      if (userData) {
        setDiagnosisList(userData.diagnosis);
        setDrugList(userData.priscription);
        setPatientInstruction(userData.instruction);
        setOtherData(userData.otherData);
        setSelectedHandouts(userData.handouts);
      } else {
        patientHistoryFetch(pid);
      }

      PatientDetailSet(pid);
    }, 500);

    return () => clearTimeout(dbounse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pid]);

  // ==================================== Apply all function ================================
  const [activeButton, setActiveButton] = useState(true);

  const applyAllData = () => {
    setActiveButton(false);
    const diagnosisData = historyList[0].diagnosis_data;
    const drugData = historyList[0].drug_data;
    const instructionData = historyList[0].instruction_data;
    const tapperList = historyList[0].drug_duration;

    const arrayList = diagnosisList;
    const arrayDrugList = drugList;
    const arrayInstructionList = patientInstruction;

    const min = 1;
    const max = 100;

    if (diagnosisData.length > 0) {
      // ========================== diagnosis Data ==========================
      diagnosisData.forEach((value) => {
        const diagnosisList = {
          diagnosis_id: "id_" + Math.floor(min + Math.random() * (max - min)),
          diagnosis_name: value.diagnosis_name,
          type: value.type,
          diagnosis_duration: value.diagnosis_duration.split(" ")[0],
          diagnosis_days: value.diagnosis_duration.split(" ")[1],
          diagnosis_type:
            value.diagnosis_name_type_complain === null
              ? ""
              : value.diagnosis_name_type_complain,
          diagnosis_discription:
            value.diagnosis_description === null
              ? ""
              : value.diagnosis_description,
          newSearhc: "true",
          token: access_token,
        };

        arrayList.unshift(diagnosisList);
      });
      setDiagnosisList([...arrayList]);
    }

    // ========================== Drug Data ==========================

    if (drugData.length > 0) {
      const arrayDesignList = drugDesign;
      // ======================================== Tapper Data ================================

      if (tapperList.length > 0) {
        const taperListData = taperList;

        tapperList.forEach((value) => {
          const dataList = {
            id: "id_" + Math.floor(min + Math.random() * (max - min)),
            fromDate: value.start_date,
            toDate: value.end_date,
            days: value.drug_duration,
            dose: value.drug_dose,
            taper_freq_morning: value.drug_frequency.split(", ")[0],
            taper_freq_afternoon: value.drug_frequency.split(", ")[1],
            taper_freq_evening: value.drug_frequency.split(", ")[2],
            taper_freq_night: value.drug_frequency.split(", ")[3],
            taper_freq_sos: value.drug_frequency.split(", ")[4],
            drug_id: value.pateint_drug_id_reff,
          };
          taperListData.unshift(dataList);
        });

        setTaperList([...taperListData]);
      }
      // ===================== Drug List ===========================
      drugData.forEach((value) => {
        const matchingDrug = taperList.filter(
          (drug) => drug.drug_id === value.pateint_drug_id
        );

        const drugList = {
          drug_id: value.pateint_drug_id,
          drug_name: value.drug_name,
          brand_name: value.brand_name,
          drug_duration_days: value.duration.split(" ")[0],
          drug_duration_dayType: value.duration.split(" ")[1],
          drug_catogery: value.medicine_category,
          drug_freq_morning: value.frequency.split(", ")[0],
          drug_freq_evening: value.frequency.split(", ")[2],
          drug_freq_afternoon: value.frequency.split(", ")[1],
          drug_freq_night: value.frequency.split(", ")[3],
          drug_freq_sos: value.frequency.split(", ")[4],
          drug_freq_stat: value.frequency.split(", ")[5],
          drug_dosg: value.drug_dose,
          drug_strength: value.strength_medicine,
          drug_relation: value.relation_of_food,
          drug_instruction: value.drug_instruction,
          drug_strip: value.drug_strip,
          drug_tapper_list: matchingDrug.length > 0 ? matchingDrug : [],
          drug_generic_name: value.generic_name,
          type: "Drugs",
        };

        const designArray = {
          type: "checkView",
        };

        arrayDesignList.unshift(designArray);
        arrayDrugList.unshift(drugList);
      });

      setDrugDesign(arrayDesignList);
      sessionStorage.setItem("drugDesign", JSON.stringify(arrayDesignList));

      setDrugList([...arrayDrugList]);
    }

    if (instructionData.length > 0) {
      // ===================================== Patient Instruction ==============================
      instructionData.forEach((value) => {
        const dataList = {
          instruction_id: "id_" + Math.floor(min + Math.random() * (max - min)),
          instruction_name: value.instruction_patient_name,
          instruction_type: value.instruction_patient_type,
          instruction_location_name: value.instruction_location_name,
          instruction_location_mobile: value.instruction_location_mobile,
          instruction_location_email: value.instruction_location_email,
          instruction_location_address: value.instruction_location_address,
          instruction_date:
            value.instruction_patient_date === "0000-00-00"
              ? ""
              : value.instruction_patient_date,
          instruction_note: value.instruction_note,
          newSearch: "true",
          token: access_token,
        };

        arrayInstructionList.unshift(dataList);
      });

      setPatientInstruction([...arrayInstructionList]);
    }
  };

  // ==================================== Remove all ===========================

  const removeAllData = () => {
    setActiveButton(true);
    setPatientInstruction([]);
    setDrugList([]);
    setTaperList([]);
    setDiagnosisList([]);
  };

  const parentStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    overflow: "hidden",
  };

  // ===================================== Template Save ==============================

  const saveData = () => {
    if (foundHospital) {
      setShowBackdrop(true);
      document.body.style.overflow = "hidden";

      var followUpDate = "";
      var followUpNote = "";
      var surgicalNote = "";
      var drugNote = "";

      if (otherData && otherData.length > 0) {
        const followData = otherData.filter(
          (value) => value.patient_desc_type === "Follow"
        );
        const drugNoteData = otherData.filter(
          (value) => value.patient_desc_type === "drug"
        );
        const surgicalNoteData = otherData.filter(
          (value) => value.patient_desc_type === "Diagnosis"
        );
        if (followData && followData.length > 0) {
          followUpDate = followData[0].follow_up;
          followUpNote = followData[0].patient_desc_text;
        }

        if (drugNoteData && drugNoteData.length > 0) {
          drugNote = drugNoteData[0].patient_desc_text;
        }
        if (surgicalNoteData && surgicalNoteData.length > 0) {
          surgicalNote = surgicalNoteData[0].patient_desc_text;
        }
      }
      const priscriptionData = {
        token: access_token,
        hospital_id: hid,
        language: languageList[0].language,
        appointment_id: apid,
        patient_data: {
          patient_name: patientDetail.name,
          patient_age:
            moment
              .duration(moment().diff(moment(patientDetail.date, "YYYY-MM-DD")))
              .asYears()
              .toFixed(0) +
            "Y/" +
            patientDetail.gender,
          patient_pid: pid,
          patient_address: patientDetail.address,
          patient_date: apdate,
          patient_time: aptime,
          patient_mobile: patientDetail.number,
          patient_vitals:
            vitalData && vitalData.length > 0
              ? {
                  weight: vitalData[0].patient_Weight,
                  height: vitalData[0].patient_height,
                  bmi: vitalData[0].patinet_bmi,
                  temp: vitalData[0].patient_temperature,
                  pulse: vitalData[0].patient_pulse,
                  rr: vitalData[0].patient_respiratory_rate,
                  bp: vitalData[0].patinet_blood_presure,
                  stats: vitalData[0].patient_oxygen_saturation,
                }
              : [],
        },
        diag_data: diagnosisList,
        inst_data: patientInstruction,
        drug_data: drugList,
        handout_data: selectedHandouts,
        other_details: {
          follow_up: {
            date: followUpDate,
            note: followUpNote,
          },
          drug_notes: drugNote,
          surgical_notes: surgicalNote,
          referral: selectedReferal,
          refrences: selectedRefrence,
        },
      };

      // ========== Check Internet =========
      if (isConnected) {
        savePriscription(priscriptionData);
      } else {
        // ======== Checking already data is stored in local storage ==========
        const previousData = JSON.parse(
          localStorage.getItem("priscriptionSave")
        );
        if (previousData) {
          // ========== is Same Appointment Id have or not ===============
          const indexHas = previousData.findIndex(
            (value) => value.appointment_id === priscriptionData.appointment_id
          );
          if (indexHas !== -1) {
            // =========== If have replace with previous ==============
            previousData[indexHas] = priscriptionData;
            localStorage.setItem(
              "priscriptionSave",
              JSON.stringify(previousData)
            );
          } else {
            // ============= push new priscription ===========
            previousData.unshift(priscriptionData);
            localStorage.setItem(
              "priscriptionSave",
              JSON.stringify(previousData)
            );
          }
        } else {
          // ========== No Data in store adding new one ======
          localStorage.setItem(
            "priscriptionSave",
            JSON.stringify([priscriptionData])
          );
        }
      }
      // fetch(
      //   "https://skdm.in/skddev/DrCrm/precription_api/appointment_add.php",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: priscriptionData, // Your form data
      //   }
      // );

      try {
        const hiddenDiv = document.createElement("div");
        hiddenDiv.style.maxWidth = "60%";
        hiddenDiv.setAttribute("id", "#appnedTemplate");
        hiddenDiv.innerHTML = ReactDOMServer.renderToStaticMarkup(
          <ActiveTemplateComp
            headerHeight={templateSetting.headerHeight}
            footerHeight={templateSetting.footerHeight}
            fontSize={templateSetting.fontSize}
            templateName="Template 1"
            fontFamily={templateSetting.fontStyle}
            signature={templateSetting.signature}
            preview={templateSetting.letterHead}
            doctorDataFooter={templateSetting.doctorDetails}
            patientDetail={patientDetail}
            dateTime={dateTime}
            withHeader={true}
            activeTemplate={activeTemplate}
            diagnosis={
              diagnosisList && diagnosisList.length > 0
                ? diagnosisList.filter((value) => value.type === "Diagnosis")
                : []
            }
            complaints={
              diagnosisList && diagnosisList.length > 0
                ? diagnosisList.filter((value) => value.type === "Complaints")
                : []
            }
            findings={
              diagnosisList && diagnosisList.length > 0
                ? diagnosisList.filter((value) => value.type === "Findings")
                : []
            }
            drugList={drugList}
            test={
              patientInstruction && patientInstruction.length > 0
                ? patientInstruction.filter(
                    (value) => value.instruction_type === "Test"
                  )
                : []
            }
            procedures={
              patientInstruction && patientInstruction.length > 0
                ? patientInstruction.filter(
                    (value) => value.instruction_type === "Procedures"
                  )
                : []
            }
            instruction={
              patientInstruction && patientInstruction.length > 0
                ? patientInstruction.filter(
                    (value) => value.instruction_type === "Instruction"
                  )
                : []
            }
            followUp={
              otherData && otherData.length > 0
                ? otherData.filter(
                    (value) => value.patient_desc_type === "Follow"
                  )
                : []
            }
            stepInstructionData={
              otherData && otherData.length > 0
                ? otherData.filter(
                    (value) => value.patient_desc_type === "Drug"
                  )
                : []
            }
            diagnosisNote={
              otherData && otherData.length > 0
                ? otherData.filter(
                    (value) => value.patient_desc_type === "Diagnosis"
                  )
                : []
            }
            selectedRefrence={selectedRefrence}
            selectedReferal={selectedReferal}
            vitalData={vitalData}
            languageList={languageList}
            result={result}
            handoutList={selectedHandouts}
          />
        );

        document.body.appendChild(hiddenDiv);

        html2canvas(hiddenDiv).then((canvas) => {
          // Convert the canvas to a Blob
          canvas.toBlob((blob) => {
            const formData = new FormData();

            formData.append("prescription", blob);
            formData.append("appointment_Id", apid);
            formData.append("token", access_token);
            if (isConnected) {
              fetch(
                "https://skdm.in/skddev/DrCrm/booking_appointment_update.php",
                {
                  method: "POST",
                  body: formData, // Your form data
                }
              );
            }
            document.body.style.overflow = "auto";
            setShowBackdrop(false);
            message.success("Save and submited successfully");
            document.body.removeChild(hiddenDiv);
            removeDataFromDraft(pid, apdate, hid);
          });

          printPriscription();
        });
      } catch (error) {
        document.getElementById("#appnedTemplate").style.display = "none";
        document.body.style.overflow = "auto";
        // handle the error here
        setShowBackdrop(false);
        console.log("Error capturing screenshot:", error);
        message.error("Failed to upload pleas try again");
      }
    } else {
      alert(
        "You have not did setting of hospital please do setting first in templates page ."
      );
    }
  };
  // =================================== Referal and Refrences List =========================

  const referenceView = (typeData) => {
    if (referalDataList && referalDataList.length <= 0) {
      fetch("https://skdm.in/skddev/DrCrm/doctor_references_view.php", {
        method: "POST",
        body: JSON.stringify({
          token: access_token,
          references_type: typeData,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((resp) => resp.json())

        .then((apiData) => {
          if (Array.isArray(apiData)) {
            setReferalDataList(apiData);
          } else {
            setReferalDataList([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching reference data:", error);
        });
    }
  };

  const referalView = (typeData) => {
    if (referenceDataList && referenceDataList.length <= 0) {
      fetch("https://skdm.in/skddev/DrCrm/doctor_references_view.php", {
        method: "POST",
        body: JSON.stringify({
          token: access_token,
          references_type: typeData,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((resp) => resp.json())

        .then((apiData) => {
          if (Array.isArray(apiData)) {
            setReferenceDataList(apiData);
          } else {
            setReferenceDataList([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching reference data:", error);
        });
    }
  };

  useEffect(() => {
    let dbounseData;
    dbounseData = setTimeout(() => {
      referenceView("References");
      referalView("Referrals");
      templateListFetch();
    }, 10000);

    return () => clearTimeout(dbounseData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ============================== Print Priscription =========================

  const printPriscription = () => {
    // =================== Print ======================
    const newTab = window.open();
    const printDiv = document.createElement("div");
    printDiv.innerHTML = ReactDOMServer.renderToStaticMarkup(
      <ActiveTemplateComp
        headerHeight={templateSetting.headerHeight}
        footerHeight={templateSetting.footerHeight}
        marginLeft={templateSetting.leftSpace}
        marginRight={templateSetting.rightSpace}
        fontSize={templateSetting.fontSize}
        templateName="Template 1"
        fontFamily={templateSetting.fontStyle}
        signature={templateSetting.signature}
        preview={templateSetting.letterHead}
        doctorDataFooter={templateSetting.doctorDetails}
        patientDetail={patientDetail}
        dateTime={dateTime}
        withHeader={false}
        activeTemplate={activeTemplate}
        diagnosis={
          diagnosisList && diagnosisList.length > 0
            ? diagnosisList.filter((value) => value.type === "Diagnosis")
            : []
        }
        complaints={
          diagnosisList && diagnosisList.length > 0
            ? diagnosisList.filter((value) => value.type === "Complaints")
            : []
        }
        findings={
          diagnosisList && diagnosisList.length > 0
            ? diagnosisList.filter((value) => value.type === "Findings")
            : []
        }
        drugList={drugList}
        test={
          patientInstruction && patientInstruction.length > 0
            ? patientInstruction.filter(
                (value) => value.instruction_type === "Test"
              )
            : []
        }
        procedures={
          patientInstruction && patientInstruction.length > 0
            ? patientInstruction.filter(
                (value) => value.instruction_type === "Procedures"
              )
            : []
        }
        instruction={
          patientInstruction && patientInstruction.length > 0
            ? patientInstruction.filter(
                (value) => value.instruction_type === "Instruction"
              )
            : []
        }
        followUp={
          otherData && otherData.length > 0
            ? otherData.filter((value) => value.patient_desc_type === "Follow")
            : []
        }
        stepInstructionData={
          otherData && otherData.length > 0
            ? otherData.filter((value) => value.patient_desc_type === "Drug")
            : []
        }
        diagnosisNote={
          otherData && otherData.length > 0
            ? otherData.filter(
                (value) => value.patient_desc_type === "Diagnosis"
              )
            : []
        }
        selectedRefrence={selectedRefrence}
        selectedReferal={selectedReferal}
        vitalData={vitalData}
        languageList={languageList}
        result={result}
        handoutList={selectedHandouts}
      />
    );
    newTab.document.body.appendChild(printDiv);
    newTab.print();
  };
  // ============================ Certificate Print and Save ==================================

  const certificatePrint = async () => {
    setShowBackdrop(true);

    try {
      const hiddenDiv = document.createElement("div");
      hiddenDiv.style.maxWidth = "60%";
      hiddenDiv.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <Certificate
          headerHeight={templateSetting.headerHeight}
          footerHeight={templateSetting.footerHeight}
          fontSize={templateSetting.fontSize}
          dateTime={dateTime}
          preview={templateSetting.letterHead}
          patientDetail={patientDetail}
        />
      );
      document.body.appendChild(hiddenDiv);
      const imageData = await htmlToImage.toJpeg(hiddenDiv);
      const formData = new FormData();
      formData.append("patient_Image", imageData);
      formData.append("appointmentIdreff", apid);
      formData.append("patientIdReff", pid);
      formData.append("token", access_token);
      const req = await cerficateRegister(formData);
      if (req.data) {
        setShowBackdrop(false);
        message.error("Saved and Submited Successfully");
        document.body.removeChild(hiddenDiv);

        // =================== Print ======================
        const newTab = window.open();
        const printDiv = document.createElement("div");
        printDiv.innerHTML = ReactDOMServer.renderToStaticMarkup(
          <Certificate
            headerHeight={templateSetting.headerHeight}
            footerHeight={templateSetting.footerHeight}
            fontSize={templateSetting.fontSize}
            dateTime={dateTime}
            preview={templateSetting.letterHead}
            patientDetail={patientDetail}
          />
        );
        newTab.document.body.appendChild(printDiv);
        newTab.print();
        ReactToPrint.printComponent(hiddenDiv);
      }
      if (req.error) {
        setShowBackdrop(false);

        message.error("Failed to submit please try again");
      }
    } catch (error) {
      console.error("Error capturing screenshot:", error);
      // handle the error here
      setShowBackdrop(false);
      message.error("Error capturing screenshot");
    }
  };

  // ====================== Handle remove allergy =====================
  const [documentImageDelete] = useDocumentImageDeleteMutation();

  const handleRemoveAllergy = async (index, id) => {
    if (window.confirm("Are sure you want to remove this ?")) {
      const res = await documentImageDelete({
        patient_desc_id: id,
        token: access_token,
      });

      if (res.data) {
        message.success("Allergy Removed");
        const newArray = alleryListFetch.filter((item, i) => i !== index);
        setAlleryListFetch(newArray);
      }
    }
  };

  const replaceSpacesWithNbsp = (text) => {
    const modifiedText = text.split(" ").join("&nbsp;");
    return <p dangerouslySetInnerHTML={{ __html: modifiedText }} />;
  };

  // ========================== Save to draft ======================

  const addIntoLocal = () => {
    storeIntoDraft(
      apdate,
      hid,
      pid,
      diagnosisList,
      drugList,
      patientInstruction,
      otherData,
      selectedHandouts
    );
  };

  // ================================== Check Attribute Select ========================

  const attributeList = useTemplateAttribute();

  const generateAttributeList = (ids, list) => {
    if (list && list.length > 0 && ids && ids.length > 0) {
      const activeList = {};

      list.forEach((value) => {
        activeList[value.template_api_name] = ids.includes(
          value.template_attribute_id
        );
      });

      setResult(activeList);
    }
  };

  useEffect(() => {
    let dbounse;
    dbounse = setTimeout(() => {
      generateAttributeList(attributeId, attributeList);
    }, 500);

    return () => clearTimeout(dbounse);
  }, [attributeId, attributeList]);
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 100000000000000,
          overflow: "hidden",
        }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ReferalRefrenceModal
        type={referalType}
        show={showModal}
        hideModal={() => setShowModal(false)}
        referalDataList={referalDataList}
        setReferalDataList={setReferalDataList}
        setReferenceDataList={setReferenceDataList}
        referenceDataList={referenceDataList}
      />
      <AddLocationBox
        show={showideLocation}
        close={() => setShowideLocation(false)}
        patientInstruction={patientInstruction}
        setPatientInstruction={setPatientInstruction}
        index={indexData}
        locationType={locationType}
        testLocation={testLocation}
        setTestLocation={setTestLocation}
        token={access_token}
        fetchTypeList={fetchTypeList}
        testLoad={testLoad}
      />

      <SideBar>
        <NavbarComp />
        <StickyContainer>
          <div className="prescriptionPag navbarBodyMargin">
            <div className="row">
              <div className="col-12 d-xl-none d-block">
                <div classname="sidebar2">
                  <div className="">
                    <div className="row border1pxgray ">
                      <div className="col-md-6">
                        <div className="prescriptionSuggestSection">
                          <div className="patientProfilePss">
                            <Link
                              to={`/patientProfile/${pid}`}
                              className="patientImgName"
                            >
                              <div className="patientImg">
                                <Avatar
                                  src={patientDetail.image}
                                  alt={patientDetail.name}
                                />
                              </div>
                              <div className="patientName">
                                <h6
                                  className="h6"
                                  style={{ color: "rgb(9, 57, 101)" }}
                                >
                                  {patientDetail.name}{" "}
                                </h6>
                                <p>
                                  {patientDetail.age}{" "}
                                  <span>| +91 {patientDetail.number}</span>
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <hr className="d-block d-md-none" />
                      <div className="col-md-6">
                        <div className="p-md-4 p-2">
                          <div className="row">
                            <div className="col-2">
                              <div className="iconsStyle sucess">
                                <GiWallet />
                              </div>
                            </div>
                            <div className="col-2">
                              <p className="billtitle">Bill</p>
                            </div>
                            <div className="col-5">
                              <div className="searchDiv">
                                <input
                                  className="billinput"
                                  placeholder="Total Bill"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="billbuttion">
                                <Button
                                  className="checkCircleButton"
                                  type="submit"
                                >
                                  <BsCheck2Circle />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 d-none d-xl-block">
                <Sticky>
                  {({ style }) => (
                    <div
                      className="prescriptionSuggestSection"
                      style={{ ...style, top: "100px" }}
                    >
                      <div className="patientProfilePss">
                        <Link
                          to={`/patientProfile/${pid}`}
                          className="patientImgName"
                        >
                          <div className="patientImg">
                            <Avatar
                              src={patientDetail.image}
                              alt={patientDetail.name}
                            />
                          </div>
                          <div className="patientName">
                            <h6 className="h6">{patientDetail.name} </h6>
                            <p>
                              {patientDetail.age}{" "}
                              <span>| +91 {patientDetail.number}</span>
                            </p>
                          </div>
                        </Link>
                      </div>
                      {key === "SUMMARY" ? (
                        <div className="prescriptionSuggestSummary">
                          <Accordion>
                            {/* ============ Follow Up After ============ */}
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <div className="accordionIcon">
                                  <BsFillCalendarCheckFill />
                                </div>{" "}
                                Follow Up After
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="summaryAccordianBox">
                                  <div className="followupDays">
                                    <div className="followDaysButtons">
                                      <div className="followDayButton">
                                        <input
                                          type="button"
                                          value="1"
                                          className="form-control"
                                          onClick={(e) =>
                                            followDateChange(e, "number", "day")
                                          }
                                        />
                                      </div>
                                      <div className="followDayButton">
                                        <input
                                          type="button"
                                          value="2"
                                          className="form-control"
                                          onClick={(e) =>
                                            followDateChange(e, "number", "day")
                                          }
                                        />
                                      </div>
                                      <div className="followDayButton">
                                        <input
                                          type="button"
                                          value="3"
                                          className="form-control"
                                          onClick={(e) =>
                                            followDateChange(e, "number", "day")
                                          }
                                        />
                                      </div>
                                      <div className="followDayButton">
                                        <input
                                          type="button"
                                          value="4"
                                          className="form-control"
                                          onClick={(e) =>
                                            followDateChange(e, "number", "day")
                                          }
                                        />
                                      </div>
                                      <div className="followDayButton">
                                        <input
                                          type="button"
                                          value="5"
                                          className="form-control"
                                          onClick={(e) =>
                                            followDateChange(e, "number", "day")
                                          }
                                        />
                                      </div>

                                      <div className="followDayText d-flex align-items-center">
                                        <h6
                                          style={{ color: "rgb(9, 57, 101)" }}
                                        >
                                          Days
                                        </h6>
                                      </div>
                                      <div className="followDayResetBtn">
                                        <button
                                          className="Clickbtn"
                                          onClick={(e) =>
                                            followDateChange(e, "reset")
                                          }
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="followWeekButtons mt-3">
                                      <div className="followweekButton">
                                        <input
                                          type="button"
                                          value="1 Week"
                                          className="form-control"
                                          onClick={(e) =>
                                            followDateChange(
                                              e,
                                              "number",
                                              "week"
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="followweekButton">
                                        <input
                                          type="button"
                                          value="1 Month"
                                          className="form-control"
                                          onClick={(e) =>
                                            followDateChange(
                                              e,
                                              "number",
                                              "month"
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="followweekButton">
                                        <input
                                          type="button"
                                          value="1 Year"
                                          className="form-control"
                                          onClick={(e) =>
                                            followDateChange(
                                              e,
                                              "number",
                                              "year"
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="followTimeButton">
                                        <input
                                          type="date"
                                          className="form-control"
                                          value={otherData
                                            .filter(
                                              (value) =>
                                                value.patient_desc_type ===
                                                "Follow"
                                            )
                                            .map((value) => value.follow_up)}
                                          onChange={(e) =>
                                            followDateChange(e, "date")
                                          }
                                        />
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="followInput">
                                      <div className="inputBoxInput">
                                        <form
                                          onSubmit={(e) =>
                                            followDateChange(e, "selectedDate")
                                          }
                                        >
                                          <div className="row">
                                            <div className="col-5">
                                              <div className="input-style-1">
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  name="daysNumber"
                                                  placeholder=""
                                                />
                                              </div>
                                            </div>
                                            <div className="col-5">
                                              <div className="input-style-1">
                                                <select
                                                  name="duration"
                                                  id="duration"
                                                  className="form-select"
                                                >
                                                  <option value="days">
                                                    Days
                                                  </option>
                                                  <option value="weeks">
                                                    Weeks
                                                  </option>
                                                  <option value="months">
                                                    Months
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div className="col-2 d-flex justify-content-center align-items-center">
                                              <Button
                                                className="checkCircleButton"
                                                type="submit"
                                              >
                                                <BsCheck2Circle />
                                              </Button>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="followInputBox">
                                      <div className="inputBoxInput">
                                        <form
                                          onSubmit={(e) =>
                                            followDateChange(e, "note")
                                          }
                                        >
                                          <div className="row">
                                            <div className="col-10">
                                              <div className="input-style-1">
                                                <textarea
                                                  className="form-control"
                                                  rows={3}
                                                  name="noteInput"
                                                  defaultValue={otherData
                                                    .filter(
                                                      (value) =>
                                                        value.patient_desc_type ===
                                                        "Follow"
                                                    )
                                                    .map(
                                                      (value) =>
                                                        value.patient_desc_text
                                                    )}
                                                  type="text"
                                                  placeholder=""
                                                />
                                              </div>
                                            </div>
                                            <div className="col-2 d-flex justify-content-center align-items-center">
                                              <Button
                                                className="checkCircleButton"
                                                type="submit"
                                              >
                                                <BsCheck2Circle />
                                              </Button>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>

                            {/* =============== References ================ */}
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                <div className="accordionIcon">
                                  <BsArrowDownLeftCircleFill />
                                </div>
                                References
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="referencesAccordian">
                                  <div className="referencesSearchBox">
                                    <div className="row">
                                      <div className="col-10">
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder="Search References"
                                        />
                                      </div>
                                      <div className="col-2">
                                        <div className="referenceAddButton">
                                          <button
                                            className="Clickbtn"
                                            onClick={() =>
                                              referalRefrence("References")
                                            }
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="referencesList">
                                    <div className="row">
                                      {referalDataList &&
                                      referalDataList.length > 0 ? (
                                        referalDataList.map((value, i) =>
                                          value.doctor_references_type ===
                                          "References" ? (
                                            <div className="col-6" key={i}>
                                              <Form.Check
                                                onChange={(e) =>
                                                  referalRefrenceSelected(
                                                    e,
                                                    value.doctor_references_id,
                                                    value.doctor_references_type
                                                  )
                                                }
                                                type="radio"
                                                name="refrences"
                                                id={`default-${value.doctor_references_id}`}
                                                label={
                                                  value.doctor_references_name
                                                }
                                              />
                                            </div>
                                          ) : (
                                            ""
                                          )
                                        )
                                      ) : (
                                        <h6>No Data Found</h6>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>

                            {/* ================ Referrals  ===============  */}
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                <div className="accordionIcon">
                                  <BsArrowUpRightCircleFill />
                                </div>
                                Referrals
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="referencesAccordian">
                                  <div className="referencesSearchBox">
                                    <div className="row">
                                      <div className="col-9">
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder="Search Referrals"
                                        />
                                      </div>
                                      <div className="col-3 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                                        <div className="referenceAddButton">
                                          <button
                                            className="Clickbtn"
                                            onClick={() =>
                                              referalRefrence("Referrals")
                                            }
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="referencesList">
                                    <div className="row">
                                      {referenceDataList &&
                                      referenceDataList.length > 0 ? (
                                        referenceDataList.map((value, i) =>
                                          value.doctor_references_type ===
                                          "Referrals" ? (
                                            <div className="col-6" key={i}>
                                              <Form.Check
                                                onChange={(e) =>
                                                  referalSelected(
                                                    e,
                                                    value.doctor_references_id,
                                                    value.doctor_references_type
                                                  )
                                                }
                                                type="radio"
                                                name="refrels"
                                                id={`default-${value.doctor_references_id}`}
                                                label={
                                                  value.doctor_references_name
                                                }
                                              />
                                            </div>
                                          ) : (
                                            ""
                                          )
                                        )
                                      ) : (
                                        <h6>No Data Found</h6>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>

                            {/* ================ Change Language ============ */}
                            <Accordion.Item eventKey="3">
                              <Accordion.Header>
                                <div className="accordionIcon">
                                  <SiGoogletranslate />
                                </div>
                                Change Language
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="languageOptionAccordian">
                                  <div className="row">
                                    <div className="col-6">
                                      <Form.Check
                                        type="radio"
                                        name="language"
                                        id="english"
                                        value="english"
                                        label="English"
                                        checked={selectLanguage === "english"}
                                        onChange={selectedFunction}
                                      />
                                    </div>
                                    <div className="col-6">
                                      <Form.Check
                                        type="radio"
                                        name="language"
                                        id="hindi"
                                        value="hindi"
                                        checked={selectLanguage === "hindi"}
                                        label="Hindi"
                                        onChange={selectedFunction}
                                      />
                                    </div>
                                    <div className="col-6">
                                      <Form.Check
                                        type="radio"
                                        name="language"
                                        id="marathi"
                                        value="marathi"
                                        checked={selectLanguage === "marathi"}
                                        label="Marathi"
                                        onChange={selectedFunction}
                                      />
                                    </div>
                                    <div className="col-6">
                                      <Form.Check
                                        type="radio"
                                        name="language"
                                        id="gujrati"
                                        value="gujrati"
                                        checked={selectLanguage === "gujrati"}
                                        label="Gujrati"
                                        onChange={selectedFunction}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>

                            {/* =========== Upload Records ===============   */}
                            {/* <Accordion.Item eventKey="4">
                        <Accordion.Header>Upload Records</Accordion.Header>
                        <Accordion.Body>
                          <div className="UploadRecordsSection">
                            <div className="row">
                              <div className="col-6">
                                <div className="input-style-1">
                                  <input
                                    type="text"
                                    placeholder="Record Name"
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div clasName="inputBoxInput">
                                  <select name="uploadRecord" id="uploadRecord">
                                    <option value="prescription">
                                      Prescription
                                    </option>
                                    <option value="Test">Test</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="input-style-1">
                                  <input type="date" placeholder="" />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="input-style-1">
                                  <label
                                    className="form-label"
                                    for="recordUpload"
                                  >
                                    Upload Record
                                  </label>
                                  <input
                                    name="recordUpload"
                                    type="file"
                                    className="form-control"
                                    id="recordUpload"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item> */}

                            {/* =========== Upload Records ===============   */}
                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                <div className="accordionIcon">
                                  <BsFillFileEarmarkMedicalFill />
                                </div>
                                Generate Certificate
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="generateCertificate">
                                  <Accordion>
                                    <Accordion.Item eventKey="0">
                                      <Accordion.Header>
                                        <div className="accordionIcon">
                                          <FaBriefcaseMedical />
                                        </div>
                                        Medical Certificate
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <div className="row">
                                          <div className="col-6">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                label="Suffering From"
                                                inputFormat="DD-MM-YYYY"
                                                value={
                                                  mediacalDate.sufferingFrom
                                                }
                                                onChange={(newDate) =>
                                                  setMediacalDate({
                                                    ...mediacalDate,
                                                    sufferingFrom: newDate,
                                                  })
                                                }
                                                renderInput={(params) => (
                                                  <TextField {...params} />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                          <div className="col-6">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                label="Suffering To"
                                                inputFormat="DD-MM-YYYY"
                                                value={mediacalDate.sufferingTo}
                                                onChange={(newDate) =>
                                                  setMediacalDate({
                                                    ...mediacalDate,
                                                    sufferingTo: newDate,
                                                  })
                                                }
                                                renderInput={(params) => (
                                                  <TextField {...params} />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                          <div className="col-6">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                label="Rest Till"
                                                inputFormat="DD-MM-YYYY"
                                                value={mediacalDate.restTill}
                                                onChange={(newDate) =>
                                                  setMediacalDate({
                                                    ...mediacalDate,
                                                    restTill: newDate,
                                                  })
                                                }
                                                renderInput={(params) => (
                                                  <TextField {...params} />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                          <div className="col-6">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                label="Resume From"
                                                inputFormat="DD-MM-YYYY"
                                                value={mediacalDate.resumeFrom}
                                                onChange={(newDate) =>
                                                  setMediacalDate({
                                                    ...mediacalDate,
                                                    resumeFrom: newDate,
                                                  })
                                                }
                                                renderInput={(params) => (
                                                  <TextField {...params} />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                        </div>
                                        <hr />
                                        {addMore ? (
                                          <div className="addMoreDetail">
                                            <textarea
                                              className="form-control"
                                              value={mediacalDate.moreDetail}
                                              onChange={(e) =>
                                                setMediacalDate({
                                                  ...mediacalDate,
                                                  moreDetail: e.value,
                                                })
                                              }
                                              rows="3"
                                            ></textarea>
                                            <hr />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="row">
                                          <div className="col-6">
                                            {addMore ? (
                                              ""
                                            ) : (
                                              <button
                                                className="btn btn-dark"
                                                onClick={() => setAddMore(true)}
                                              >
                                                Add More
                                              </button>
                                            )}
                                          </div>
                                          <div className="col-6 d-flex justify-content-end align-items-center">
                                            <button
                                              className="btn btn-success"
                                              onClick={certificatePrint}
                                            >
                                              Save & Print
                                            </button>
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                      <Accordion.Header>
                                        <div className="accordionIcon">
                                          <FaClinicMedical />
                                        </div>
                                        Leave Certificate
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <div className="row">
                                          <div className="col-6">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                label="Suffering From"
                                                inputFormat="DD-MM-YYYY"
                                                value={
                                                  mediacalDate.sufferingFrom
                                                }
                                                onChange={(newDate) =>
                                                  setMediacalDate({
                                                    ...mediacalDate,
                                                    sufferingFrom: newDate,
                                                  })
                                                }
                                                renderInput={(params) => (
                                                  <TextField {...params} />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                          <div className="col-6">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <Stack spacing={3}>
                                                <DesktopDatePicker
                                                  label="Suffering To"
                                                  inputFormat="DD/MM/YYYY"
                                                  value={
                                                    mediacalDate.sufferingTo
                                                  }
                                                  onChange={(newDate) =>
                                                    setMediacalDate({
                                                      ...mediacalDate,
                                                      sufferingTo: newDate,
                                                    })
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField {...params} />
                                                  )}
                                                />
                                              </Stack>
                                            </LocalizationProvider>
                                          </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                          <div className="col-6">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                label="Rest Till"
                                                inputFormat="DD-MM-YYYY"
                                                value={mediacalDate.restTill}
                                                onChange={(newDate) =>
                                                  setMediacalDate({
                                                    ...mediacalDate,
                                                    restTill: newDate,
                                                  })
                                                }
                                                renderInput={(params) => (
                                                  <TextField {...params} />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                          <div className="col-6">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                label="Resume From"
                                                inputFormat="DD-MM-YYYY"
                                                value={mediacalDate.resumeFrom}
                                                onChange={(newDate) =>
                                                  setMediacalDate({
                                                    ...mediacalDate,
                                                    resumeFrom: newDate,
                                                  })
                                                }
                                                renderInput={(params) => (
                                                  <TextField {...params} />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                        </div>
                                        <hr />
                                        {addMore ? (
                                          <div className="addMoreDetail">
                                            <textarea
                                              className="form-control"
                                              value={mediacalDate.moreDetail}
                                              onChange={(e) =>
                                                setMediacalDate({
                                                  ...mediacalDate,
                                                  moreDetail: e.value,
                                                })
                                              }
                                              rows="3"
                                            ></textarea>
                                            <hr />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="row">
                                          <div className="col-6">
                                            {addMore ? (
                                              ""
                                            ) : (
                                              <button
                                                className="btn btn-dark"
                                                onClick={() => setAddMore(true)}
                                              >
                                                Add More
                                              </button>
                                            )}
                                          </div>
                                          <div className="col-6 d-flex justify-content-end align-items-center">
                                            <button
                                              className="btn btn-success"
                                              onClick={certificatePrint}
                                            >
                                              Save & Print
                                            </button>
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      ) : (
                        <div className="wordSuggestionSection">
                          <div className="wordSuggestionSearchBox">
                            <span>Search Here</span>
                            <div className="searchDiv">
                              <input
                                type="text"
                                name=""
                                className="form-control"
                                placeholder={searchPlaceholder}
                                value={inputData.default}
                                onChange={(e) => {
                                  setSearchDataList({
                                    text: e.target.value,
                                    type: activeSearchKey,
                                  });
                                  setInputData({
                                    ...inputData,
                                    default: e.target.value,
                                  });
                                }}
                                id=""
                              />
                              <div className="iconsStyle search">
                                <IoSearchOutline />
                              </div>
                            </div>
                          </div>
                          <div className="wordSuggestionsBox">
                            {loaderActive ? (
                              <CircularProgress />
                            ) : key === "PRESCRIPTION" ? (
                              <div className="wordSuggestionWords">
                                {suggestionList && suggestionList.length > 0 ? (
                                  suggestionList.map((obj, i) => (
                                    <span key={i}>
                                      <button
                                        onClick={() => {
                                          addDataList(
                                            obj.drug_id,
                                            "Drug",
                                            obj.drug_name,
                                            obj.duration,
                                            obj.medicine_category,
                                            obj.frequency,
                                            1,
                                            obj.strength_medicine,
                                            obj.relation_of_food,
                                            obj.drug_instruction,
                                            obj.brand_name,
                                            obj.generic_name,
                                            obj.once_in
                                          );
                                        }}
                                        className="drug"
                                      >
                                        {medicineShort.map((value) =>
                                          value.name === obj.medicine_category
                                            ? value.name
                                            : null
                                        )}
                                        {" " + obj.brand_name}
                                        {obj.strength_medicine !== null &&
                                        obj.strength_medicine !== " mg"
                                          ? " (" + obj.strength_medicine + ") "
                                          : null}
                                      </button>
                                    </span>
                                  ))
                                ) : (
                                  <div className="">
                                    <h5 className="mt-3">
                                      No Suggestion Found
                                    </h5>
                                    <br />
                                    <AddDrugBox
                                      setAlertDiloge={setAlertDiloge}
                                      setAlertText={setAlertText}
                                      setSuggestionList={setSuggestionList}
                                      suggestionList={suggestionList}
                                      drugName={searchDataList.text}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : key === "INSTRUCTIONS" ? (
                              <div className="wordSuggestionWords">
                                {suggestionList && suggestionList.length > 0 ? (
                                  suggestionList.map((obj, i) => (
                                    <span key={i}>
                                      <button
                                        onClick={() =>
                                          addDataList(
                                            obj.instruction_id,
                                            obj.instruction_type,
                                            obj.instruction_name
                                          )
                                        }
                                        className={obj.instruction_type}
                                        name={obj.instruction_type}
                                      >
                                        {obj.instruction_name}
                                      </button>
                                    </span>
                                  ))
                                ) : (
                                  <h5 className="mt-3">No Suggestion Found</h5>
                                )}
                              </div>
                            ) : (
                              <div className="wordSuggestionWords">
                                {suggestionList && suggestionList.length > 0 ? (
                                  suggestionList.map((obj, i) => (
                                    <span key={i}>
                                      <button
                                        onClick={() =>
                                          addDataList(
                                            obj.diagnosis_id,
                                            obj.type,
                                            obj.diagnosis_name,
                                            obj.diagnosis_duration
                                          )
                                        }
                                        className={obj.type}
                                        name={obj.type}
                                      >
                                        {obj.diagnosis_name}
                                      </button>
                                    </span>
                                  ))
                                ) : (
                                  <h5 className="mt-3">No Suggestion Found</h5>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="wordSuggestionSearchBox">
                            <div className="row">
                              <div className="col-md-2">
                                <div className="iconsStyle sucess">
                                  <GiWallet />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <p className="billtitle">Bill</p>
                              </div>
                              <div className="col-md-5">
                                <div className="searchDiv">
                                  <input
                                    className="billinput"
                                    placeholder="Total Bill"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="billbuttion">
                                  <Button
                                    className="checkCircleButton"
                                    type="submit"
                                  >
                                    <BsCheck2Circle />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Sticky>
              </div>
              <div className="col-xl-8 col-12">
                <div className="mainsidebar2">
                  <div className="diagnosisFromSection">
                    <div className="Facilities-Section2-tabpanel">
                      <Tabs
                        activeKey={key}
                        id="justify-tab-example"
                        onSelect={(k) => activeStepComponent(k)}
                        className="mb-3 tabssec"
                        justify
                      >
                        {/* ========== Diagnosis Tab ============= */}

                        <Tab eventKey="DIAGNOSIS" title="DIAGNOSIS">
                          <div className="Treatment-Con">
                            {/* Diagnois INput Box */}
                            <div className="inputBox">
                              <div className="inputBoxHead">
                                <div className="inputBoxTitle ">
                                  <span className="green"></span>
                                  <h6> Diagnosis</h6>
                                </div>
                                <div className="inputBoxButton">
                                  <NavLink
                                    to={`/viewHistory/${encodeURIComponent(
                                      pid
                                    )}/${encodeURIComponent(
                                      patientDetail.name
                                    )}/${encodeURIComponent(
                                      patientDetail.date
                                    )}/${encodeURIComponent(
                                      patientDetail.gender
                                    )}`}
                                  >
                                    <button>
                                      View Medical History <BiRightArrowAlt />
                                    </button>
                                  </NavLink>
                                </div>
                              </div>
                              <div className="inputBoxInput">
                                <div className="row">
                                  <div className="col-12 mb-3">
                                    {diagnosisList &&
                                      diagnosisList.map((value, index) =>
                                        value.type === "Diagnosis" ? (
                                          <div
                                            key={index}
                                            className="listOfData"
                                          >
                                            <div className="name">
                                              <h6>
                                                {value.diagnosis_name}{" "}
                                                {value.diagnosis_type === "" ||
                                                value.diagnosis_type === " "
                                                  ? ""
                                                  : "(" +
                                                    value.diagnosis_type +
                                                    ")"}
                                                {value.diagnosis_discription ===
                                                  "" ||
                                                value.diagnosis_discription ===
                                                  null
                                                  ? ""
                                                  : "- " +
                                                    value.diagnosis_discription}
                                              </h6>
                                            </div>
                                            <div>
                                              <span
                                                onClick={() =>
                                                  updateData(
                                                    value.diagnosis_id,
                                                    value.diagnosis_name,
                                                    value.type,
                                                    value.diagnosis_type,
                                                    "type"
                                                  )
                                                }
                                                className="iconsStyle sucess"
                                              >
                                                <FaEdit />
                                              </span>
                                            </div>
                                            <div>
                                              <span
                                                onClick={() =>
                                                  updateData(
                                                    value.diagnosis_id,
                                                    value.diagnosis_name,
                                                    value.type,
                                                    value.diagnosis_discription,
                                                    "discription"
                                                  )
                                                }
                                                className="iconsStyle edit"
                                              >
                                                <AiFillEdit />
                                              </span>
                                              <div className=""></div>
                                            </div>
                                            <div>
                                              <span
                                                onClick={() =>
                                                  removeArrayData(index)
                                                }
                                                className="iconsStyle delete"
                                              >
                                                <MdDelete />
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      )}
                                  </div>
                                </div>
                                <form
                                  id="diagnosisForm"
                                  data-type="Diagnosis"
                                  onSubmit={diagnosisSubmit}
                                >
                                  <div className="row">
                                    <div className="col-11">
                                      <div className="position-relative">
                                        <div className="inputpadding">
                                          <div className="iconadde">
                                            <div className="iconsStyle search">
                                              <BiPlus />
                                            </div>
                                          </div>
                                          <input
                                            type="text"
                                            name="name"
                                            placeholder="Type here..."
                                            autoComplete="off"
                                            data-type="Diagnosis"
                                            value={inputData.diagnosis}
                                            onClick={(e) => {
                                              searchDataList.type !==
                                                "Diagnosis" &&
                                                setSearchDataList({
                                                  text: e.target.value,
                                                  type: "Diagnosis",
                                                });
                                            }}
                                            onInput={(e) => {
                                              setInputData({
                                                ...inputData,
                                                diagnosis: e.target.value,
                                              });

                                              setSearchDataList({
                                                text: e.target.value,
                                                type: "Diagnosis",
                                              });
                                            }}
                                          />
                                        </div>
                                        {listPopup === "Diagnosis" ? (
                                          <div className="listSearchDropDown">
                                            {suggestionList &&
                                            suggestionList.length === 0 ? (
                                              <ul ref={popupListRef}>
                                                <li>No Suggestion Found</li>
                                              </ul>
                                            ) : (
                                              <ul ref={popupListRef}>
                                                {suggestionList &&
                                                suggestionList.length > 0
                                                  ? suggestionList.map(
                                                      (obj, i) => (
                                                        <li
                                                          key={i}
                                                          onClick={() => {
                                                            addDataList(
                                                              obj.diagnosis_id,
                                                              obj.type,
                                                              obj.diagnosis_name,
                                                              obj.diagnosis_duration
                                                            );
                                                            setListPopup("");
                                                            setInputData({
                                                              ...inputData,
                                                              diagnosis: "",
                                                              default: "",
                                                            });
                                                          }}
                                                          name={obj.type}
                                                        >
                                                          {obj.diagnosis_name}
                                                        </li>
                                                      )
                                                    )
                                                  : null}
                                              </ul>
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-1 col-2 d-flex justify-content-center align-items-center">
                                      <Button
                                        className="checkCircleButton"
                                        type="submit"
                                      >
                                        <BsCheck2Circle />
                                      </Button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            {/* ===================== Complaints ====================== */}
                            <div className="inputBox centerInputBox">
                              <div className="inputBoxHead">
                                <div className="inputBoxTitle">
                                  <span class="orange"></span>
                                  <h6>Complaints</h6>
                                </div>
                                <div className="col-1"></div>
                                <div className="inputBoxTitle">
                                  <h6>Duration</h6>
                                </div>
                                <div className="col-1"></div>
                              </div>
                              <div className="inputBoxInput">
                                <div className="row">
                                  <div className="col-12 mb-3">
                                    {diagnosisList &&
                                      diagnosisList.map((value, index) =>
                                        value.type === "Complaints" ? (
                                          <div
                                            key={index}
                                            className="listOfData"
                                          >
                                            <div className="name">
                                              <h6>
                                                {value.diagnosis_name}{" "}
                                                {value.diagnosis_type === "" ||
                                                value.diagnosis_type === " "
                                                  ? ""
                                                  : "(" +
                                                    value.diagnosis_type +
                                                    ")"}
                                                {value.diagnosis_discription ===
                                                  "" ||
                                                value.diagnosis_discription ===
                                                  null
                                                  ? ""
                                                  : "- " +
                                                    value.diagnosis_discription}
                                              </h6>
                                              <div className="inputDetail">
                                                <div className="input-style-1">
                                                  <input
                                                    autoComplete="off"
                                                    type="text"
                                                    placeholder=""
                                                    className="form-control"
                                                    onChange={(e) =>
                                                      updateComplain(
                                                        e,
                                                        value.diagnosis_id,
                                                        "daysNumber"
                                                      )
                                                    }
                                                    defaultValue={
                                                      value.diagnosis_duration
                                                    }
                                                    name="complaindTime"
                                                  />
                                                </div>
                                                <div className="input-style-1">
                                                  <select
                                                    name="duration"
                                                    id="duration"
                                                    className="form-select"
                                                    onChange={(e) =>
                                                      updateComplain(
                                                        e,
                                                        value.diagnosis_id,
                                                        "daysSelect"
                                                      )
                                                    }
                                                  >
                                                    {value.diagnosis_days ===
                                                    "days" ? (
                                                      <option
                                                        value="days"
                                                        selected
                                                      >
                                                        Days
                                                      </option>
                                                    ) : (
                                                      <option
                                                        value="days"
                                                        selected
                                                      >
                                                        Days
                                                      </option>
                                                    )}
                                                    {value.diagnosis_days ===
                                                    "weeks" ? (
                                                      <option
                                                        value="weeks"
                                                        selected
                                                      >
                                                        Weeks
                                                      </option>
                                                    ) : (
                                                      <option value="Weeks">
                                                        Weeks
                                                      </option>
                                                    )}
                                                    {value.diagnosis_days ===
                                                    "months" ? (
                                                      <option
                                                        value="months"
                                                        selected
                                                      >
                                                        Months
                                                      </option>
                                                    ) : (
                                                      <option value="months">
                                                        Months
                                                      </option>
                                                    )}
                                                    {value.diagnosis_days ===
                                                    "year" ? (
                                                      <option
                                                        value="year"
                                                        selected
                                                      >
                                                        Year
                                                      </option>
                                                    ) : (
                                                      <option value="year">
                                                        Year
                                                      </option>
                                                    )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>

                                            <div style={{ color: "#f2b33d" }}>
                                              <span
                                                className="iconsStyle sucess"
                                                onClick={() =>
                                                  updateData(
                                                    value.diagnosis_id,
                                                    value.diagnosis_name,
                                                    value.type,
                                                    value.diagnosis_type,
                                                    "type"
                                                  )
                                                }
                                              >
                                                <FaEdit />
                                              </span>
                                            </div>
                                            <div>
                                              <span
                                                onClick={() =>
                                                  updateData(
                                                    value.diagnosis_id,
                                                    value.diagnosis_name,
                                                    value.type,
                                                    value.diagnosis_discription,
                                                    "discription"
                                                  )
                                                }
                                                className="iconsStyle edit"
                                              >
                                                <AiFillEdit />
                                              </span>
                                            </div>

                                            <div>
                                              <span
                                                onClick={() =>
                                                  removeArrayData(index)
                                                }
                                                className="iconsStyle delete "
                                              >
                                                <MdDelete />
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      )}
                                  </div>
                                </div>
                                <form
                                  id="complainsForm"
                                  data-type="Complaints"
                                  onSubmit={diagnosisSubmit}
                                >
                                  <div className="row">
                                    <div className="col-6">
                                      <div className="position-relative">
                                        <div className="inputpadding">
                                          <div className="iconadde">
                                            <div className="iconsStyle search">
                                              <BiPlus />
                                            </div>
                                          </div>
                                          <input
                                            autoComplete="off"
                                            type="text"
                                            name="name"
                                            placeholder="Type here..."
                                            value={inputData.complaints}
                                            onClick={(e) => {
                                              searchDataList.type !==
                                                "Complaints" &&
                                                setSearchDataList({
                                                  text: e.target.value,
                                                  type: "Complaints",
                                                });
                                            }}
                                            onInput={(e) => {
                                              setInputData({
                                                ...inputData,
                                                complaints: e.target.value,
                                              });
                                              setSearchDataList({
                                                text: e.target.value,
                                                type: "Complaints",
                                              });
                                            }}
                                            data-type="Complaints"
                                          />
                                        </div>
                                        {listPopup === "Complaints" ? (
                                          <div className="listSearchDropDown">
                                            {suggestionList &&
                                            suggestionList.length === 0 ? (
                                              <ul ref={popupListRef}>
                                                <li>No Suggestion Found</li>
                                              </ul>
                                            ) : (
                                              <ul ref={popupListRef}>
                                                {suggestionList &&
                                                suggestionList.length > 0
                                                  ? suggestionList.map(
                                                      (obj, i) => (
                                                        <li
                                                          key={i}
                                                          onClick={() => {
                                                            addDataList(
                                                              obj.diagnosis_id,
                                                              obj.type,
                                                              obj.diagnosis_name,
                                                              obj.diagnosis_duration
                                                            );
                                                            setListPopup(false);
                                                            setInputData({
                                                              ...inputData,
                                                              complaints: "",
                                                              default: "",
                                                            });
                                                          }}
                                                          name={obj.type}
                                                        >
                                                          {obj.diagnosis_name}
                                                        </li>
                                                      )
                                                    )
                                                  : null}
                                              </ul>
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div
                                      className="col-2 m-0"
                                      style={{ padding: "0 5px 0 0" }}
                                    >
                                      <div className="inputpadding">
                                        <input
                                          autoComplete="off"
                                          type="text"
                                          placeholder=""
                                          name="complaindTimeInput"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-2 p-0 m-0">
                                      <div className="inputselect">
                                        <select
                                          name="durationInput"
                                          id="duration"
                                        >
                                          <option value="days">Days</option>
                                          <option value="weeoks">Weeks</option>
                                          <option value="months">Months</option>
                                          <option value="year">Year</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-1 col-2 d-flex justify-content-center align-items-center">
                                      <Button
                                        className="checkCircleButton"
                                        type="submit"
                                      >
                                        <BsCheck2Circle />
                                      </Button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            {/* ========================== Findings ====================== */}

                            <form
                              id="findingsForm"
                              data-type="Findings"
                              onSubmit={diagnosisSubmit}
                            >
                              <div className="inputBox">
                                <div className="inputBoxHead ">
                                  <div className="findingtext">
                                    <span class="blue"></span>
                                    <h6>Findings</h6>
                                  </div>
                                </div>
                                <div className="inputBoxInput">
                                  <div className="row">
                                    <div className="col-12 mb-3">
                                      {diagnosisList &&
                                        diagnosisList.map((value, index) =>
                                          value.type === "Findings" ? (
                                            <div
                                              key={index}
                                              className="listOfData"
                                            >
                                              <div className="name">
                                                <h6>
                                                  {value.diagnosis_name}{" "}
                                                  {value.diagnosis_type ===
                                                    "" ||
                                                  value.diagnosis_type === " "
                                                    ? ""
                                                    : "(" +
                                                      value.diagnosis_type +
                                                      ")"}
                                                  {value.diagnosis_discription ===
                                                    "" ||
                                                  value.diagnosis_discription ===
                                                    null
                                                    ? ""
                                                    : "- " +
                                                      value.diagnosis_discription}
                                                </h6>
                                              </div>
                                              <div
                                                className=""
                                                style={{ color: "#f2b33d" }}
                                              >
                                                <span
                                                  onClick={() =>
                                                    updateData(
                                                      value.diagnosis_id,
                                                      value.diagnosis_name,
                                                      value.type,
                                                      value.diagnosis_type,
                                                      "type"
                                                    )
                                                  }
                                                  className="iconsStyle sucess"
                                                >
                                                  <FaEdit />
                                                </span>
                                              </div>
                                              <div className="">
                                                <span
                                                  className="iconsStyle edit"
                                                  onClick={() =>
                                                    updateData(
                                                      value.diagnosis_id,
                                                      value.diagnosis_name,
                                                      value.type,
                                                      value.diagnosis_discription,
                                                      "discription"
                                                    )
                                                  }
                                                >
                                                  <AiFillEdit />
                                                </span>
                                              </div>
                                              <div>
                                                <span
                                                  onClick={() =>
                                                    removeArrayData(index)
                                                  }
                                                  className="iconsStyle delete "
                                                >
                                                  <MdDelete />
                                                </span>
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )
                                        )}
                                    </div>

                                    <div className="col-11">
                                      <div className="position-relative">
                                        <div className="inputpadding">
                                          <div className="iconadde">
                                            <div className="iconsStyle search">
                                              <BiPlus />
                                            </div>
                                          </div>
                                          <input
                                            value={inputData.findings}
                                            onClick={(e) => {
                                              searchDataList.type !==
                                                "Findings" &&
                                                setSearchDataList({
                                                  text: e.target.value,
                                                  type: "Findings",
                                                });
                                            }}
                                            onChange={(e) => {
                                              setInputData({
                                                ...inputData,
                                                findings: e.target.value,
                                              });
                                              setSearchDataList({
                                                text: e.target.value,
                                                type: "Findings",
                                              });
                                            }}
                                            autoComplete="off"
                                            type="text"
                                            data-type="Findings"
                                            name="name"
                                            placeholder="Type here..."
                                          />
                                        </div>
                                        {listPopup === "Findings" ? (
                                          <div className="listSearchDropDown">
                                            {suggestionList &&
                                            suggestionList.length === 0 ? (
                                              <ul ref={popupListRef}>
                                                <li>No Suggestion Found</li>
                                              </ul>
                                            ) : (
                                              <ul ref={popupListRef}>
                                                {suggestionList.map(
                                                  (obj, i) => (
                                                    <li
                                                      key={i}
                                                      onClick={() => {
                                                        addDataList(
                                                          obj.diagnosis_id,
                                                          obj.type,
                                                          obj.diagnosis_name,
                                                          obj.diagnosis_duration
                                                        );
                                                        setListPopup(false);
                                                        setInputData({
                                                          ...inputData,
                                                          findings: "",
                                                          default: "",
                                                        });
                                                      }}
                                                      name={obj.type}
                                                    >
                                                      {obj.diagnosis_name}
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-1 col-2 d-md-flex d-flex justify-content-center align-items-center">
                                      <Button
                                        className="checkCircleButton"
                                        type="submit"
                                      >
                                        <BsCheck2Circle />
                                      </Button>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>

                            {historyList.length > 0
                              ? historyList.map((obj, i) => (
                                  <div key={i} className="inputBox mt-4">
                                    <div className="inputBoxInput">
                                      <div className="row">
                                        <div className="col-9 d-flex justify-content-start align-items-center">
                                          <span className="historyTitle">
                                            Last Visit -{" "}
                                            {moment(obj.appointmentDate).format(
                                              "MMM DD, YYYY"
                                            )}{" "}
                                            at ({obj.appointment_start_time})
                                          </span>
                                        </div>
                                        <div className="col-3 d-flex align-items-center m-0 mb-2 p-0 justify-content-center d-flex align-items-center d-flex justify-content-center align-items-center">
                                          {activeButton ? (
                                            <div>
                                              <Button
                                                className="Clickbtn"
                                                onClick={applyAllData}
                                              >
                                                Apply Now
                                              </Button>
                                            </div>
                                          ) : (
                                            <button
                                              className="Cancelbtn"
                                              onClick={removeAllData}
                                            >
                                              Remove All
                                            </button>
                                          )}
                                        </div>
                                        <div className="col-12">
                                          <div className="input-style-1">
                                            <p className="historyDetail">
                                              {patientDetail.name},{" "}
                                              {moment().diff(
                                                patientDetail.date,
                                                "years"
                                              )}
                                              &nbsp;years{" "}
                                              {moment().diff(
                                                patientDetail.date,
                                                "months"
                                              ) % 12}
                                              &nbsp;months{" "}
                                              {patientDetail.gender}.
                                              {patientDetail.gender === "Male"
                                                ? ` He`
                                                : ` She`}{" "}
                                              had{" "}
                                              {/* ==================== Allergy ================== */}
                                              Allergy{" "}
                                              {alleryListFetch.map(
                                                (value) => value.text
                                              )}
                                              ,
                                              {/* ==================== Daignosis ================= */}
                                              {obj.diagnosis_data.length > 0 &&
                                              obj.diagnosis_data.some(
                                                (item) =>
                                                  item.type === "Diagnosis"
                                              )
                                                ? `diagnosis of ${obj.diagnosis_data
                                                    .filter(
                                                      (item) =>
                                                        item.type ===
                                                        "Diagnosis"
                                                    )
                                                    .map(
                                                      (diagnosisValue) =>
                                                        `${
                                                          diagnosisValue.diagnosis_name
                                                        } ${
                                                          diagnosisValue.diagnosis_name_type_complain !==
                                                            "" &&
                                                          diagnosisValue.diagnosis_name_type_complain !==
                                                            null
                                                            ? "(" +
                                                              diagnosisValue.diagnosis_name_type_complain +
                                                              ")"
                                                            : ""
                                                        } ${
                                                          diagnosisValue.diagnosis_description !==
                                                            null &&
                                                          diagnosisValue.diagnosis_description !==
                                                            ""
                                                            ? " - " +
                                                              diagnosisValue.diagnosis_description
                                                            : ""
                                                        }
                                                         `
                                                    )
                                                    .join(", ")}`
                                                : ""}
                                              {/* =========== Complain ============== */}
                                              {obj.diagnosis_data.length > 0 &&
                                              obj.diagnosis_data.some(
                                                (item) =>
                                                  item.type === "Complaints"
                                              )
                                                ? `Complaints of ${obj.diagnosis_data
                                                    .filter(
                                                      (item) =>
                                                        item.type ===
                                                        "Complaints"
                                                    )
                                                    .map(
                                                      (diagnosisValue) =>
                                                        `${
                                                          diagnosisValue.diagnosis_name
                                                        } ${
                                                          diagnosisValue.diagnosis_name_type_complain !==
                                                            "" &&
                                                          diagnosisValue.diagnosis_name_type_complain !==
                                                            null
                                                            ? "(" +
                                                              diagnosisValue.diagnosis_name_type_complain +
                                                              ")"
                                                            : ""
                                                        } ${
                                                          diagnosisValue.diagnosis_description !==
                                                            null &&
                                                          diagnosisValue.diagnosis_description !==
                                                            ""
                                                            ? " - " +
                                                              diagnosisValue.diagnosis_description
                                                            : ""
                                                        } ${
                                                          diagnosisValue.diagnosis_duration !==
                                                          ""
                                                            ? " since " +
                                                              diagnosisValue.diagnosis_duration
                                                            : ""
                                                        } `
                                                    )
                                                    .join(", ")}`
                                                : ""}
                                              {/* =================== Findings =========== */}
                                              {obj.diagnosis_data.length > 0 &&
                                              obj.diagnosis_data.some(
                                                (item) =>
                                                  item.type === "Findings"
                                              )
                                                ? `Findings of ${obj.diagnosis_data
                                                    .filter(
                                                      (item) =>
                                                        item.type === "Findings"
                                                    )
                                                    .map(
                                                      (diagnosisValue) =>
                                                        `${
                                                          diagnosisValue.diagnosis_name
                                                        } ${
                                                          diagnosisValue.diagnosis_name_type_complain !==
                                                            "" &&
                                                          diagnosisValue.diagnosis_name_type_complain !==
                                                            null
                                                            ? "(" +
                                                              diagnosisValue.diagnosis_name_type_complain +
                                                              ")"
                                                            : ""
                                                        } ${
                                                          diagnosisValue.diagnosis_description !==
                                                            null &&
                                                          diagnosisValue.diagnosis_description !==
                                                            ""
                                                            ? " - " +
                                                              diagnosisValue.diagnosis_description
                                                            : ""
                                                        }`
                                                    )
                                                    .join(", ")}`
                                                : ""}
                                              {/* ================== Drug ================= */}
                                              {obj.drug_data.length > 0
                                                ? `Gave drugs ${obj.drug_data
                                                    .map(
                                                      (diagnosisValue) =>
                                                        `${diagnosisValue.brand_name} for ${diagnosisValue.duration} `
                                                    )
                                                    .join(", ")}`
                                                : ""}
                                              {/* ================ Test ========================= */}
                                              {obj.instruction_data.length >
                                                0 &&
                                              obj.instruction_data.some(
                                                (item) =>
                                                  item.instruction_patient_type ===
                                                  "Test"
                                              )
                                                ? `Tests - ${obj.instruction_data
                                                    .filter(
                                                      (item) =>
                                                        item.instruction_patient_type ===
                                                        "Test"
                                                    )
                                                    .map(
                                                      (instructionValue) =>
                                                        ` ${instructionValue.instruction_patient_name}`
                                                    )
                                                    .join(", ")}`
                                                : ""}
                                              {/* ===================== Procedures ===================== */}
                                              {obj.instruction_data.length >
                                                0 &&
                                              obj.instruction_data.some(
                                                (item) =>
                                                  item.instruction_patient_type ===
                                                  "Procedures"
                                              )
                                                ? `Procedures - ${obj.instruction_data
                                                    .filter(
                                                      (item) =>
                                                        item.instruction_patient_type ===
                                                        "Procedures"
                                                    )
                                                    .map(
                                                      (instructionValue) =>
                                                        ` ${instructionValue.instruction_patient_name}`
                                                    )
                                                    .join(", ")}`
                                                : ""}
                                              {/* =================== Instructions ================= */}
                                              {obj.instruction_data.length >
                                                0 &&
                                              obj.instruction_data.some(
                                                (item) =>
                                                  item.instruction_patient_type ===
                                                  "Instruction"
                                              )
                                                ? ` and follow instruction - ${obj.instruction_data
                                                    .filter(
                                                      (item) =>
                                                        item.instruction_patient_type ===
                                                        "Instruction"
                                                    )
                                                    .map(
                                                      (instructionValue) =>
                                                        ` ${instructionValue.instruction_patient_name}`
                                                    )
                                                    .join(", ")}`
                                                : ""}
                                              {/* ============================ Follow Up =================== */}
                                              {obj.patientDesc_data.length >
                                                0 &&
                                              obj.patientDesc_data.some(
                                                (item) =>
                                                  item.patient_desc_type ===
                                                  "Follow"
                                              )
                                                ? obj.patientDesc_data
                                                    .filter(
                                                      (item) =>
                                                        item.patient_desc_type ===
                                                        "Follow"
                                                    )
                                                    .map(
                                                      (instructionValue) =>
                                                        ` and follow up - ${moment(
                                                          instructionValue.follow_up
                                                        ).format(
                                                          "DD MMM YYYY"
                                                        )}`
                                                    )
                                                    .join(", ")
                                                : ""}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : null}

                            <div className="inputBox mt-4">
                              <div className="inputBoxInput">
                                <form
                                  onSubmit={(e) =>
                                    drugInstructionSubmit(
                                      e,
                                      "diagnosisInstruction"
                                    )
                                  }
                                >
                                  <div className="row">
                                    <div className="col-1 d-none d-md-flex d-flex justify-content-center align-items-center">
                                      <div className="iconadde">
                                        {/* <div className="iconsStyle search">
                                        <BiPlus />
                                      </div> */}
                                      </div>
                                    </div>
                                    <div className="col-10">
                                      <div className="input-style-1">
                                        <textarea
                                          className="form-control"
                                          rows={4}
                                          type="text"
                                          name="diagnosisInstruction"
                                          defaultValue={
                                            otherData && otherData.length > 0
                                              ? otherData.some(
                                                  (value) =>
                                                    value.patient_desc_type ===
                                                    "Diagnosis"
                                                )
                                                ? otherData.find(
                                                    (value) =>
                                                      value.patient_desc_type ===
                                                      "Diagnosis"
                                                  ).patient_desc_text
                                                : null
                                              : null
                                          }
                                          placeholder="Enter detailed clinical / surgical notes here"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-1 col-md-1 col-2 d-flex justify-content-center align-items-center">
                                      <Button
                                        className="checkCircleButton"
                                        type="submit"
                                      >
                                        <BsCheck2Circle />
                                      </Button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </Tab>

                        {/* ========== Prescription Tab ============= */}
                        <Tab eventKey="PRESCRIPTION" title="PRESCRIPTION">
                          <div className="Treatment-Con">
                            <div className="prescriptionSection">
                              {/* Add drugs button */}
                              <div className="addDrugsButton">
                                <AddDrugBox
                                  setAlertDiloge={setAlertDiloge}
                                  setAlertText={setAlertText}
                                  setSuggestionList={setSuggestionList}
                                  suggestionList={suggestionList}
                                />
                                {/* <button>Add New Drug</button>{" "} */}
                                <span>Add drugs to your directory</span>
                              </div>

                              {/* Add Gugs Allergy Button */}
                              <div className="drugAllergySection d-block">
                                <div className="row">
                                  <div className="col-lg-1 mb-3 mb-lg-0 d-flex align-items-center">
                                    <div className="drugAllerygyText">
                                      <h6>Drug&nbsp;Allery</h6>
                                    </div>
                                  </div>
                                  <div className="col-xl-6 col-md-6 mb-3 mb-md-0 d-flex align-items-center">
                                    <div className="drugAllergyList">
                                      <ScrollContainer className="scroll-container AllergyList d-flex align-items-center">
                                        {alleryListFetch &&
                                        alleryListFetch.length > 0
                                          ? alleryListFetch.map((value, i) => (
                                              <span key={i}>
                                                {replaceSpacesWithNbsp(
                                                  value.text
                                                )}
                                                <button
                                                  onClick={() =>
                                                    handleRemoveAllergy(
                                                      i,
                                                      value.id
                                                    )
                                                  }
                                                >
                                                  <AiFillCloseCircle />
                                                </button>
                                              </span>
                                            ))
                                          : null}
                                      </ScrollContainer>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-3 col-6 d-flex align-items-center justify-content-md-end justify-content-start">
                                    <div className="allergyBtn">
                                      <AddAlleryBox
                                        alleryListFetch={alleryListFetch}
                                        setAlleryListFetch={setAlleryListFetch}
                                        patientId={pid}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-6 d-flex align-items-center justify-content-end">
                                    <div className="inputBoxButton">
                                      <NavLink
                                        to={`/viewHistory/${encodeURIComponent(
                                          pid
                                        )}/${encodeURIComponent(
                                          patientDetail.name
                                        )}/${encodeURIComponent(
                                          patientDetail.date
                                        )}/${encodeURIComponent(
                                          patientDetail.gender
                                        )}`}
                                      >
                                        <button>
                                          View Medical History
                                          <BiRightArrowAlt />
                                        </button>
                                      </NavLink>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Add Prescription Box */}
                              <div className="inputBox ">
                                <div className="inputBoxHead inputBoxPrescription">
                                  <div class="row d-flex align-items-center">
                                    <div className="col-md-4 col-6">
                                      <div className="inputBoxTitle">
                                        <h6>Drug</h6>
                                      </div>
                                    </div>
                                    <div className="col-1 d-none d-md-flex d-flex justify-content-center">
                                      <div className="inputBoxTitle">
                                        <h6>Dosage</h6>
                                      </div>
                                    </div>
                                    <div className="col-3 d-none d-md-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center d-flex justify-content-center">
                                      <div className="row">
                                        <div className="col-2"></div>
                                        <div className="col-2 d-flex justify-content-center">
                                          <div className="inputBoxTitle">
                                            <h6>M</h6>
                                          </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center">
                                          <div className="inputBoxTitle">
                                            <h6>A</h6>
                                          </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center">
                                          <div className="inputBoxTitle">
                                            <h6>E</h6>
                                          </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center">
                                          <div className="inputBoxTitle">
                                            <h6>N</h6>
                                          </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center">
                                          <div className="inputBoxTitle">
                                            <h6>SOS</h6>
                                          </div>
                                        </div>
                                        {/* <div className="col-2">
                                          <div className="inputBoxTitle">
                                            <h6>Stat</h6>
                                          </div>
                                        </div> */}
                                      </div>
                                    </div>

                                    <div className="col-2  d-none d-md-flex justify-content-center">
                                      <div className="inputBoxTitle">
                                        <h6>Duration</h6>
                                      </div>
                                    </div>
                                    <div className="col-md-1 col-6 d-flex justify-content-end">
                                      <div className="inputBoxHeadIcons d-flex">
                                        <div
                                          className="headIcon"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <div className="iconsStyle edit">
                                            <BsFillPrinterFill />
                                          </div>
                                        </div>
                                        <div className="iconsStyle delete">
                                          <div
                                            className="headIcon"
                                            style={{ cursor: "pointer" }}
                                            onClick={removeAllDrug}
                                          >
                                            <div className="iconsStyle delete">
                                              <MdDelete />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="inputBoxInput">
                                  {drugList && drugList.length > 0
                                    ? drugList.map((obj, i) => (
                                        <div
                                          key={i}
                                          class="row borderDrug pt-2 pb-2 mainRoot mb-3"
                                        >
                                          <div className="col-md-4 col-10 mb-3 mb-md-0 d-flex align-items-center justify-content-start">
                                            <div className="input-style-1">
                                              <h6>
                                                {medicineShort.map((value) =>
                                                  value.name ===
                                                  obj.drug_catogery
                                                    ? value.name
                                                    : null
                                                )}
                                                {" " + obj.brand_name}
                                                {obj.drug_strength
                                                  ? obj.drug_strength.split(
                                                      " "
                                                    )[0] !== ""
                                                    ? " (" +
                                                      obj.drug_strength +
                                                      ") "
                                                    : null
                                                  : null}

                                                {obj.strength_medicine
                                                  ? obj.strength_medicine !==
                                                    " mg"
                                                    ? " (" +
                                                      obj.strength_medicine +
                                                      ") "
                                                    : null
                                                  : null}
                                              </h6>
                                              <p
                                                style={{
                                                  fontSize: ".85rem",
                                                  color: "gray",
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                {obj.drug_instruction}
                                              </p>
                                            </div>
                                          </div>
                                          {/* =============== For Responsive ============== */}
                                          <div className="col-sm-1 col-2 mb-3 mb-md-0 d-block d-md-none">
                                            <div
                                              className="inputBoxHeadIcons"
                                              style={{ position: "relative" }}
                                            >
                                              <div
                                                className="headIcon"
                                                onClick={() =>
                                                  removeMedicine(i)
                                                }
                                                style={{ cursor: "pointer" }}
                                              >
                                                <div className="iconstyle delete"></div>
                                                <MdDelete />
                                              </div>

                                              <Dropdown>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                  <SlOptionsVertical />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  {drugDesign.map(
                                                    (value, index) =>
                                                      index === i &&
                                                      value.type ===
                                                        "checkView" ? (
                                                        <Dropdown.Item
                                                          key={index}
                                                          href="#"
                                                          onClick={() =>
                                                            changeDugInput(
                                                              i,
                                                              "inputView"
                                                            )
                                                          }
                                                        >
                                                          <FaEdit />
                                                        </Dropdown.Item>
                                                      ) : (
                                                        ""
                                                      )
                                                  )}

                                                  {drugDesign.map(
                                                    (value, index) =>
                                                      index === i &&
                                                      value.type ===
                                                        "inputView" ? (
                                                        <Dropdown.Item
                                                          key={index}
                                                          href="#"
                                                          onClick={() =>
                                                            changeDugInput(
                                                              i,
                                                              "checkView"
                                                            )
                                                          }
                                                        >
                                                          <FaEdit />
                                                        </Dropdown.Item>
                                                      ) : (
                                                        ""
                                                      )
                                                  )}

                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      ViewEditDrugData(
                                                        obj.drug_id
                                                      )
                                                    }
                                                    href="#"
                                                  >
                                                    <CgNotes />
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                          </div>
                                          <div className="col-md-4 col-sm-8 col-12 mb-3 mb-md-0 d-flex align-items-center justify-content-center">
                                            {/* ===================== First View ======================= */}

                                            {drugDesign.map((value, index) =>
                                              index === i &&
                                              value.type === "checkView" ? (
                                                <div
                                                  key={index}
                                                  className="row"
                                                  id="firstDesign"
                                                >
                                                  <div className="col-sm-4 col-3 d-flex align-items-center justify-content-center flex-column">
                                                    <div className="input-style-1">
                                                      <input
                                                        type="number"
                                                        placeholder=""
                                                        className="form-control"
                                                        disabled={
                                                          obj.drug_catogery ===
                                                          "Ointment"
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={drugUpdate}
                                                        defaultValue={
                                                          obj.drug_catogery ===
                                                          "Ointment"
                                                            ? ""
                                                            : obj.drug_dosg !==
                                                              0
                                                            ? obj.drug_dosg
                                                            : 1
                                                        }
                                                        data-name="dose"
                                                        data-id={obj.drug_id}
                                                        style={
                                                          obj.drug_catogery ===
                                                          "Ointment"
                                                            ? {
                                                                background:
                                                                  "#eee",
                                                              }
                                                            : null
                                                        }
                                                      />
                                                    </div>
                                                    <span>
                                                      {medicineShort.map(
                                                        (value) =>
                                                          value.name ===
                                                          obj.drug_catogery
                                                            ? value.shrt
                                                            : null
                                                      )}{" "}
                                                    </span>
                                                  </div>
                                                  <div className="col-sm-8 col-9 d-flex align-items-start justify-content-center marginbot">
                                                    <div className="row d-flex align-items-center justify-content-center">
                                                      <div className="col-2 d-flex flex-column align-items-center">
                                                        <div className="input-style-1">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="morning"
                                                            value="morning"
                                                            onChange={
                                                              drugUpdate
                                                            }
                                                            data-name="frequencyMorning"
                                                            data-id={
                                                              obj.drug_id
                                                            }
                                                            defaultChecked={
                                                              obj.drug_freq_morning ===
                                                              "null"
                                                                ? false
                                                                : true
                                                            }
                                                          />
                                                        </div>
                                                        <span className="d-block d-md-none">
                                                          M
                                                        </span>
                                                      </div>
                                                      <div className="col-2 d-flex flex-column align-items-center">
                                                        <div className="input-style-1">
                                                          <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="after"
                                                            value="after"
                                                            onChange={
                                                              drugUpdate
                                                            }
                                                            data-name="frequencyAfterNoon"
                                                            data-id={
                                                              obj.drug_id
                                                            }
                                                            defaultChecked={
                                                              obj.drug_freq_afternoon ===
                                                              "null"
                                                                ? false
                                                                : true
                                                            }
                                                          />
                                                        </div>
                                                        <span className="d-block d-md-none">
                                                          A
                                                        </span>
                                                      </div>
                                                      <div className="col-2 d-flex flex-column align-items-center">
                                                        <div className="input-style-1">
                                                          <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="evening"
                                                            value="evening"
                                                            onChange={
                                                              drugUpdate
                                                            }
                                                            data-name="frequencyEvening"
                                                            data-id={
                                                              obj.drug_id
                                                            }
                                                            defaultChecked={
                                                              obj.drug_freq_evening ===
                                                              "null"
                                                                ? false
                                                                : true
                                                            }
                                                          />
                                                        </div>
                                                        <span className="d-block d-md-none">
                                                          E
                                                        </span>
                                                      </div>
                                                      <div className="col-2 d-flex flex-column align-items-center">
                                                        <div className="input-style-1">
                                                          <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="night"
                                                            value="night"
                                                            onChange={
                                                              drugUpdate
                                                            }
                                                            data-name="frequencyNight"
                                                            data-id={
                                                              obj.drug_id
                                                            }
                                                            defaultChecked={
                                                              obj.drug_freq_night ===
                                                              "null"
                                                                ? false
                                                                : true
                                                            }
                                                          />
                                                        </div>
                                                        <span className="d-block d-md-none">
                                                          N
                                                        </span>
                                                      </div>
                                                      <div className="col-2 d-flex flex-column align-items-center ">
                                                        <div className="input-style-1">
                                                          <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="sos"
                                                            value="sos"
                                                            onChange={
                                                              drugUpdate
                                                            }
                                                            data-name="frequencySos"
                                                            data-id={
                                                              obj.drug_id
                                                            }
                                                            defaultChecked={
                                                              obj.drug_freq_sos ===
                                                              "null"
                                                                ? false
                                                                : true
                                                            }
                                                          />
                                                        </div>
                                                        <span className="d-block d-md-none">
                                                          SOS
                                                        </span>
                                                      </div>
                                                      {/* <div className="col-2 d-flex flex-column align-items-center">
                                                        <div className="input-style-1">
                                                          <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="stat"
                                                            value="stat"
                                                            onChange={
                                                              drugUpdate
                                                            }
                                                            data-name="frequencyStat"
                                                            data-id={
                                                              obj.drug_id
                                                            }
                                                            defaultChecked={
                                                              obj.drug_freq_stat ===
                                                              "null"
                                                                ? false
                                                                : true
                                                            }
                                                          />
                                                        </div>
                                                        <span className="d-block d-md-none">
                                                          STAT
                                                        </span>
                                                      </div> */}
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )
                                            )}

                                            {/* ============================= Second View ======================= */}
                                            {drugDesign.map((value, index) =>
                                              index === i &&
                                              value.type === "inputView" ? (
                                                <div
                                                  key={index}
                                                  className="row px-2 px-2 marginbot"
                                                  id="secondView"
                                                >
                                                  <div className="col-2 m-0 p-0">
                                                    <div className="input-style-1">
                                                      <input
                                                        type="number"
                                                        name="morning"
                                                        className="form-control"
                                                        placeholder="M"
                                                        onChange={drugUpdate}
                                                        data-name="frequencyMorning"
                                                        data-type="inputView"
                                                        data-id={obj.drug_id}
                                                      />
                                                    </div>
                                                    <div className="inputMedType d-flex justify-content-center">
                                                      <span>
                                                        {medicineShort.map(
                                                          (value) =>
                                                            value.name ===
                                                            obj.drug_catogery
                                                              ? value.shrt
                                                              : " "
                                                        )}{" "}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="col-2 m-0 p-0">
                                                    <div className="input-style-1">
                                                      <input
                                                        type="number"
                                                        name="after"
                                                        className="form-control"
                                                        placeholder="A"
                                                        onChange={drugUpdate}
                                                        data-name="frequencyAfterNoon"
                                                        data-type="inputView"
                                                        data-id={obj.drug_id}
                                                      />
                                                    </div>
                                                    <div className="inputMedType d-flex justify-content-center">
                                                      <span>
                                                        {medicineShort.map(
                                                          (value) =>
                                                            value.name ===
                                                            obj.drug_catogery
                                                              ? value.shrt
                                                              : " "
                                                        )}{" "}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="col-2 m-0 p-0">
                                                    <div className="input-style-1">
                                                      <input
                                                        type="number"
                                                        name="evening"
                                                        className="form-control"
                                                        placeholder="E"
                                                        onChange={drugUpdate}
                                                        data-name="frequencyEvening"
                                                        data-type="inputView"
                                                        data-id={obj.drug_id}
                                                      />
                                                    </div>
                                                    <div className="inputMedType d-flex justify-content-center">
                                                      <span>
                                                        {medicineShort.map(
                                                          (value) =>
                                                            value.name ===
                                                            obj.drug_catogery
                                                              ? value.shrt
                                                              : " "
                                                        )}{" "}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="col-2 m-0 p-0">
                                                    <div className="input-style-1">
                                                      <input
                                                        type="number"
                                                        name="night"
                                                        className="form-control"
                                                        placeholder="N"
                                                        onChange={drugUpdate}
                                                        data-name="frequencyNight"
                                                        data-type="inputView"
                                                        data-id={obj.drug_id}
                                                      />
                                                    </div>
                                                    <div className="inputMedType d-flex justify-content-center">
                                                      <span>
                                                        {medicineShort.map(
                                                          (value) =>
                                                            value.name ===
                                                            obj.drug_catogery
                                                              ? value.shrt
                                                              : " "
                                                        )}{" "}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="col-2 m-0 p-0">
                                                    <div className="input-style-1">
                                                      <input
                                                        type="number"
                                                        name="stat"
                                                        className="form-control"
                                                        placeholder="stat"
                                                        onChange={drugUpdate}
                                                        data-name="frequencyStat"
                                                        data-type="inputView"
                                                        data-id={obj.drug_id}
                                                      />
                                                    </div>
                                                    <div className="inputMedType d-flex justify-content-center">
                                                      <span>
                                                        {medicineShort.map(
                                                          (value) =>
                                                            value.name ===
                                                            obj.drug_catogery
                                                              ? value.shrt
                                                              : " "
                                                        )}{" "}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="col-2 m-0 p-0"></div>
                                                </div>
                                              ) : (
                                                ""
                                              )
                                            )}
                                          </div>

                                          <div className="col-sm-3 col-6 d-flex align-items-center justify-content-center d-flex align-items-center d-flex align-items-center justify-content-center">
                                            <div className="input-style-1 prescreptionDuration marginbot paddbot">
                                              <input
                                                type="number"
                                                name="duration"
                                                data-name="drugDays"
                                                className="form-control"
                                                data-id={obj.drug_id}
                                                onChange={drugUpdate}
                                                defaultValue={
                                                  obj.drug_duration_days
                                                }
                                              />
                                              <select
                                                name="duration"
                                                id="duration"
                                                className="form-select"
                                                data-name="drugDuration"
                                                data-id={obj.drug_id}
                                                onChange={drugUpdate}
                                              >
                                                {obj.drug_duration_dayType ===
                                                "days" ? (
                                                  <option selected value="Days">
                                                    Days
                                                  </option>
                                                ) : (
                                                  <option value="Days">
                                                    Days
                                                  </option>
                                                )}

                                                {obj.drug_duration_dayType ===
                                                "weeks" ? (
                                                  <option
                                                    selected
                                                    value="Weeks"
                                                  >
                                                    Weeks
                                                  </option>
                                                ) : (
                                                  <option value="Weeks">
                                                    Weeks
                                                  </option>
                                                )}
                                                {obj.drug_duration_dayType ===
                                                "months" ? (
                                                  <option
                                                    selected
                                                    value="Months"
                                                  >
                                                    Months
                                                  </option>
                                                ) : (
                                                  <option value="Months">
                                                    Months
                                                  </option>
                                                )}
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-1 d-none d-md-block">
                                            <div
                                              className="inputBoxHeadIcons"
                                              style={{ position: "relative" }}
                                            >
                                              <div
                                                className="headIcon"
                                                onClick={() =>
                                                  removeMedicine(i)
                                                }
                                                style={{ cursor: "pointer" }}
                                              >
                                                <div className="iconstyle delete"></div>
                                                <MdDelete />
                                              </div>

                                              <Dropdown>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                  <SlOptionsVertical />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  {drugDesign.map(
                                                    (value, index) =>
                                                      index === i &&
                                                      value.type ===
                                                        "checkView" ? (
                                                        <Dropdown.Item
                                                          key={index}
                                                          href="#"
                                                          onClick={() =>
                                                            changeDugInput(
                                                              i,
                                                              "inputView"
                                                            )
                                                          }
                                                        >
                                                          <FaEdit />
                                                        </Dropdown.Item>
                                                      ) : (
                                                        ""
                                                      )
                                                  )}

                                                  {drugDesign.map(
                                                    (value, index) =>
                                                      index === i &&
                                                      value.type ===
                                                        "inputView" ? (
                                                        <Dropdown.Item
                                                          key={index}
                                                          href="#"
                                                          onClick={() =>
                                                            changeDugInput(
                                                              i,
                                                              "checkView"
                                                            )
                                                          }
                                                        >
                                                          <FaEdit />
                                                        </Dropdown.Item>
                                                      ) : (
                                                        ""
                                                      )
                                                  )}

                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      ViewEditDrugData(
                                                        obj.drug_id
                                                      )
                                                    }
                                                    href="#"
                                                  >
                                                    <CgNotes />
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    : null}

                                  <div className="inputBoxInputText d-block d-xl-none">
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="position-relative">
                                          <div className="inputpadding">
                                            <div className="iconadde">
                                              <div className="iconsStyle search">
                                                <BiPlus />
                                              </div>
                                            </div>
                                            <input
                                              type="text"
                                              name="name"
                                              placeholder="Search Drug here..."
                                              autoComplete="off"
                                              data-type="Drug"
                                              value={inputData.drugs}
                                              onChange={(e) => {
                                                setInputData({
                                                  ...inputData,
                                                  drugs: e.target.value,
                                                });
                                                setSearchDataList({
                                                  text: e.target.value,
                                                  type: "Drug",
                                                });
                                              }}
                                            />
                                          </div>
                                          {listPopup === "Drug" ? (
                                            <div className="listSearchDropDown">
                                              {suggestionList &&
                                              suggestionList.length === 0 ? (
                                                <ul ref={popupListRef}>
                                                  <li>No Suggestion Found</li>
                                                </ul>
                                              ) : (
                                                <ul ref={popupListRef}>
                                                  {suggestionList.map(
                                                    (obj, i) => (
                                                      <li
                                                        key={i}
                                                        onClick={() => {
                                                          addDataList(
                                                            obj.drug_id,
                                                            "Drug",
                                                            obj.drug_name,
                                                            obj.duration,
                                                            obj.medicine_category,
                                                            obj.frequency,
                                                            " ",
                                                            obj.strength_medicine,
                                                            obj.relation_of_food,
                                                            obj.drug_instruction,
                                                            obj.brand_name,
                                                            obj.generic_name
                                                          );
                                                          setListPopup(false);
                                                          setInputData({
                                                            ...inputData,
                                                            drugs: "",
                                                            default: "",
                                                          });
                                                        }}
                                                        name={obj.type}
                                                      >
                                                        {medicineShort.map(
                                                          (value) =>
                                                            value.name ===
                                                            obj.medicine_category
                                                              ? value.name
                                                              : " "
                                                        )}

                                                        {obj.brand_name}
                                                        {obj.strength_medicine !==
                                                        " mg"
                                                          ? " (" +
                                                            obj.strength_medicine +
                                                            ") "
                                                          : null}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              )}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="col-md-1 d-none col-2 d-flex justify-content-center align-items-center">
                                        <Button
                                          className="checkCircleButton"
                                          type="submit"
                                        >
                                          <BsCheck2Circle />
                                        </Button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="inputBoxInputText">
                                    <p>
                                      Please use the search pan to add drugs to
                                      this prescription
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* Add instructions box */}
                              <div className="inputBox mt-4">
                                <div className="inputBoxInput">
                                  <form
                                    onSubmit={(e) =>
                                      drugInstructionSubmit(
                                        e,
                                        "drugInstruction"
                                      )
                                    }
                                  >
                                    <div className="row">
                                      <div className="col-10">
                                        <div className="input-style-1">
                                          <textarea
                                            className="form-control"
                                            rows={4}
                                            type="text"
                                            name="drugInstruction"
                                            defaultValue={
                                              otherData && otherData.length > 0
                                                ? otherData.some(
                                                    (value) =>
                                                      value.patient_desc_type ===
                                                      "Drug"
                                                  )
                                                  ? otherData.find(
                                                      (value) =>
                                                        value.patient_desc_type ===
                                                        "Drug"
                                                    ).patient_desc_text
                                                  : null
                                                : null
                                            }
                                            placeholder="Enter specific prescription instructions here"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-2 d-flex justify-content-center align-items-center">
                                        <Button
                                          className="checkCircleButton"
                                          type="submit"
                                        >
                                          <BsCheck2Circle />
                                        </Button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab>

                        {/* ========== Instructions Tab ============= */}
                        <Tab eventKey="INSTRUCTIONS" title="INSTRUCTIONS">
                          <div className="Treatment-Con">
                            {/* test instruction box */}
                            <div className="inputBox testSection">
                              <div className="inputBoxHead">
                                <div className="inputBoxTitle testInputHead d-flex justify-content-center align-items-center">
                                  <div>
                                    <h6 className="m-0 testHeading">
                                      <span className="green"></span>Tests
                                    </h6>
                                  </div>
                                  <div className="inputTitleIcon">
                                    <div className="iconsStyle edit">
                                      <CiLocationOn />
                                    </div>
                                  </div>
                                  <div className="inputTitleIcon">
                                    <div className="iconsStyle sucess">
                                      <SlCalender />
                                    </div>
                                  </div>
                                </div>
                                <div className="inputBoxButton ">
                                  <NavLink
                                    to={`/viewHistory/${encodeURIComponent(
                                      pid
                                    )}/${encodeURIComponent(
                                      patientDetail.name
                                    )}/${encodeURIComponent(
                                      patientDetail.date
                                    )}/${encodeURIComponent(
                                      patientDetail.gender
                                    )}`}
                                  >
                                    <button>
                                      View Medical History <BiRightArrowAlt />
                                    </button>
                                  </NavLink>
                                </div>
                              </div>
                              <div className="selectedList">
                                {patientInstruction.map((value, index) =>
                                  value.instruction_type === "Test" ? (
                                    <div key={index} className="listOfData">
                                      <div className="name">
                                        <h6>
                                          {value.instruction_name}
                                          <div className="locationSelect d-none d-sm-flex  align-items-center">
                                            <span
                                              className="location"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                handleActiveLoction(
                                                  index,
                                                  "Test"
                                                )
                                              }
                                            >
                                              <div className="iconsStyle edit">
                                                <CiLocationOn />
                                              </div>
                                            </span>
                                            <span style={{ fontSize: "1rem" }}>
                                              {value.instruction_location_name ===
                                              ""
                                                ? ""
                                                : value.instruction_location_name}
                                            </span>
                                          </div>
                                          <div className="dateSelect d-none d-sm-flex">
                                            <button
                                              onClick={() => setIsOpen(index)}
                                              style={{
                                                background: "transparent",
                                                border: "unset",
                                                fontSize: "1.2rem",
                                                margin: "0 10px",
                                              }}
                                            >
                                              <div className="iconsStyle sucess ">
                                                <BsCalendarCheck />
                                              </div>
                                            </button>
                                            {isOpen === index ? (
                                              <div className="calenderPopup">
                                                <Calendar
                                                  value={value.instruction_date}
                                                  onChange={(newDate) =>
                                                    testDateChange(
                                                      newDate,
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>
                                            ) : null}

                                            <span>
                                              {value.instruction_date === ""
                                                ? null
                                                : moment(
                                                    value.instruction_date
                                                  ).format("DD-MM-YYYY")}
                                            </span>
                                          </div>
                                        </h6>
                                        <div className="d-block d-sm-none">
                                          <span
                                            onClick={() =>
                                              removeInstructionList(index)
                                            }
                                          >
                                            <div className="iconsStyle delete">
                                              <MdDelete />
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="d-none d-sm-flex">
                                        <span
                                          onClick={() =>
                                            removeInstructionList(index)
                                          }
                                        >
                                          <div className="iconsStyle delete">
                                            <MdDelete />
                                          </div>
                                        </span>
                                      </div>
                                      {/* =============================== For Responsive */}
                                      <div className="otherDetails d-flex align-items-center justify-content-start d-sm-none">
                                        <div className="locationSelect d-flex align-items-center">
                                          <span
                                            className="location"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleActiveLoction(index, "Test")
                                            }
                                          >
                                            <div className="iconsStyle edit">
                                              <CiLocationOn />
                                            </div>
                                          </span>
                                          <span style={{ fontSize: "1rem" }}>
                                            {value.instruction_location_name ===
                                            ""
                                              ? ""
                                              : value.instruction_location_name}
                                          </span>
                                        </div>
                                        <div className="dateSelect">
                                          <button
                                            onClick={() => setIsOpen(index)}
                                            style={{
                                              background: "transparent",
                                              border: "unset",
                                              fontSize: "1.2rem",
                                              margin: "0 10px",
                                            }}
                                          >
                                            <div className="iconsStyle sucess ">
                                              <BsCalendarCheck />
                                            </div>
                                          </button>
                                          {isOpen === index ? (
                                            <div className="calenderPopup">
                                              <Calendar
                                                value={value.instruction_date}
                                                onChange={(newDate) =>
                                                  testDateChange(newDate, index)
                                                }
                                              />
                                            </div>
                                          ) : null}

                                          <span>
                                            {value.instruction_date === ""
                                              ? null
                                              : moment(
                                                  value.instruction_date
                                                ).format("DD-MM-YYYY")}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                              </div>
                              <div className="inputBoxInput">
                                <form
                                  onSubmit={instructionSubmit}
                                  data-type="Test"
                                >
                                  <div className="row">
                                    <div className="col-11">
                                      <div className="position-relative">
                                        <div className="inputpadding">
                                          <div className="iconadde">
                                            <div className="iconsStyle search">
                                              <BiPlus />
                                            </div>
                                          </div>
                                          <input
                                            autoComplete="off"
                                            type="text"
                                            name="name"
                                            placeholder="Type here..."
                                            value={inputData.test}
                                            onClick={(e) => {
                                              searchDataList.type !== "Test" &&
                                                setSearchDataList({
                                                  text: e.target.value,
                                                  type: "Test",
                                                });
                                            }}
                                            onChange={(e) => {
                                              setInputData({
                                                ...inputData,
                                                test: e.target.value,
                                              });
                                              setSearchDataList({
                                                text: e.target.value,
                                                type: "Test",
                                              });
                                            }}
                                          />
                                        </div>
                                        {listPopup === "Test" ? (
                                          <div className="listSearchDropDown">
                                            {suggestionList &&
                                            suggestionList.length === 0 ? (
                                              <ul ref={popupListRef}>
                                                <li>No Suggestion Found</li>
                                              </ul>
                                            ) : (
                                              <ul ref={popupListRef}>
                                                {suggestionList.map(
                                                  (value, i) => (
                                                    <li
                                                      key={i}
                                                      onClick={() => {
                                                        addDataList(
                                                          value.instruction_id,
                                                          value.instruction_type,
                                                          value.instruction_name,
                                                          value.instruction_date
                                                        );
                                                        setInputData({
                                                          ...inputData,
                                                          test: "",
                                                          default: "",
                                                        });
                                                        setListPopup(false);
                                                      }}
                                                      name={value.type}
                                                    >
                                                      {value.instruction_name}
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-1 col-2 d-flex justify-content-center align-items-center">
                                      <Button
                                        className="checkCircleButton"
                                        type="submit"
                                      >
                                        <BsCheck2Circle />
                                      </Button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            {/* Instructions Box */}
                            <div className="inputBox">
                              <div className="inputBoxHead">
                                <div className="instructionsInputBoxTitle">
                                  <h6 className="instructionHeading d-flex align-items-center">
                                    <span class="orange"></span>
                                    <span>Instructions</span>
                                    &nbsp;
                                    <span>
                                      Please limit your instructions to 75
                                      characters
                                    </span>
                                  </h6>
                                </div>
                              </div>
                              <div className="selectedList">
                                {patientInstruction.map((value, index) =>
                                  value.instruction_type === "Instruction" ? (
                                    <div
                                      key={index}
                                      className="listOfData d-block"
                                    >
                                      <div className="row">
                                        <div className="col-10">
                                          <div className="name">
                                            <h6>{value.instruction_name}</h6>
                                          </div>
                                        </div>
                                        <div className="col-2">
                                          <span
                                            onClick={() =>
                                              removeInstructionList(index)
                                            }
                                          >
                                            <div className="iconsStyle delete">
                                              <MdDelete />
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                              </div>
                              <div className="inputBoxInput">
                                <form
                                  onSubmit={instructionSubmit}
                                  data-type="Instruction"
                                >
                                  <div className="row">
                                    <div className="col-11">
                                      <div className="position-relative">
                                        <div className="inputpadding">
                                          <div className="iconadde">
                                            <div className="iconsStyle search">
                                              <BiPlus />
                                            </div>
                                          </div>
                                          <input
                                            autoComplete="off"
                                            type="text"
                                            name="name"
                                            placeholder="Type Here..."
                                            value={inputData.instruction}
                                            onClick={(e) => {
                                              searchDataList.type !==
                                                "Instruction" &&
                                                setSearchDataList({
                                                  text: e.target.value,
                                                  type: "Instruction",
                                                });
                                            }}
                                            onChange={(e) => {
                                              setInputData({
                                                ...inputData,
                                                instruction: e.target.value,
                                              });
                                              setSearchDataList({
                                                text: e.target.value,
                                                type: "Instruction",
                                              });
                                            }}
                                          />
                                        </div>
                                        {listPopup === "Instruction" ? (
                                          <div className="listSearchDropDown">
                                            {suggestionList &&
                                            suggestionList.length === 0 ? (
                                              <ul ref={popupListRef}>
                                                <li>No Suggestion Found</li>
                                              </ul>
                                            ) : (
                                              <ul ref={popupListRef}>
                                                {suggestionList.map(
                                                  (value, i) => (
                                                    <li
                                                      key={i}
                                                      onClick={() => {
                                                        addDataList(
                                                          value.instruction_id,
                                                          value.instruction_type,
                                                          value.instruction_name,
                                                          value.instruction_patient_date
                                                        );
                                                        setListPopup(false);
                                                        setInputData({
                                                          ...inputData,
                                                          instruction: "",
                                                          default: "",
                                                        });
                                                      }}
                                                      name={value.type}
                                                    >
                                                      {value.instruction_name}
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-1 col-2 d-flex justify-content-center align-items-center">
                                      <Button
                                        className="checkCircleButton"
                                        type="submit"
                                      >
                                        <BsCheck2Circle />
                                      </Button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            {/*=============== Procedures Box =========*/}
                            <div className="inputBox">
                              <div className="inputBoxHead ">
                                <div className="inputBoxTitle proceduresInputTitle d-flex align-items-center">
                                  <div>
                                    {" "}
                                    <h6 className="m-0 procedureTitle">
                                      <span className="blue"></span>
                                      Procedures
                                    </h6>
                                  </div>
                                  <div className="inputTitleIcon">
                                    <div className="iconsStyle edit">
                                      <CiLocationOn />
                                    </div>
                                  </div>
                                  <div className="inputTitleIcon">
                                    <div className="iconsStyle sucess">
                                      <SlCalender />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="selectedList">
                                {patientInstruction.map((value, index) =>
                                  value.instruction_type === "Procedures" ? (
                                    <div key={index} className="listOfData">
                                      <div className="name">
                                        <h6>
                                          {value.instruction_name}
                                          <span>
                                            {value.instruction_note === ""
                                              ? ""
                                              : " - " + value.instruction_note}
                                          </span>
                                          {value.instruction_note ===
                                          "Done" ? null : (
                                            <div className="locationSelect d-none d-sm-flex align-items-center">
                                              <span
                                                className="location"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  handleActiveLoction(
                                                    index,
                                                    "Procedures"
                                                  )
                                                }
                                              >
                                                <div className="iconsStyle edit">
                                                  <CiLocationOn />
                                                </div>
                                              </span>

                                              <span>
                                                {value.instruction_location_name ===
                                                ""
                                                  ? null
                                                  : value.instruction_location_name}
                                              </span>
                                            </div>
                                          )}

                                          {value.instruction_note ===
                                          "Done" ? null : (
                                            <div className="dateSelect d-none d-sm-flex">
                                              <button
                                                onClick={() =>
                                                  setIsOpenSecond(index)
                                                }
                                                style={{
                                                  background: "transparent",
                                                  border: "unset",
                                                  fontSize: "1.2rem",
                                                  margin: "0 10px",
                                                }}
                                              >
                                                <div className="iconsStyle sucess">
                                                  <BsCalendarCheck />
                                                </div>
                                              </button>
                                              {isOpenSecond === index ? (
                                                <div className="calenderPopup procedure">
                                                  <Calendar
                                                    value={
                                                      value.instruction_date
                                                    }
                                                    onChange={(newDate) =>
                                                      testDateChange(
                                                        newDate,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </div>
                                              ) : null}
                                              <span>
                                                {value.instruction_date === ""
                                                  ? ""
                                                  : moment(
                                                      value.instruction_date
                                                    ).format("DD-MM-YYYY")}
                                              </span>
                                            </div>
                                          )}
                                        </h6>

                                        {value.instruction_note === "" ? (
                                          <div className="adviceButton">
                                            <span
                                              data-note="Done"
                                              onClick={(e) =>
                                                instructionNote(e, index)
                                              }
                                            >
                                              Done
                                            </span>
                                            <span
                                              data-note="Advice"
                                              onClick={(e) =>
                                                instructionNote(e, index)
                                              }
                                            >
                                              Advice
                                            </span>
                                          </div>
                                        ) : null}
                                        <div>
                                          <span
                                            onClick={() =>
                                              removeInstructionList(index)
                                            }
                                          >
                                            <div className="iconsStyle delete">
                                              <MdDelete />
                                            </div>
                                          </span>
                                        </div>
                                      </div>

                                      {/* ============================= Responsive Design ============ */}
                                      <div className="otherDetails d-flex d-sm-none align-items-center justify-content-start ">
                                        {value.instruction_note ===
                                        "Done" ? null : (
                                          <div className="locationSelect d-flex align-items-center">
                                            <span
                                              className="location"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                handleActiveLoction(
                                                  index,
                                                  "Procedures"
                                                )
                                              }
                                            >
                                              <div className="iconsStyle edit">
                                                <CiLocationOn />
                                              </div>
                                            </span>

                                            <span>
                                              {value.instruction_location_name ===
                                              ""
                                                ? null
                                                : value.instruction_location_name}
                                            </span>
                                          </div>
                                        )}

                                        {value.instruction_note ===
                                        "Done" ? null : (
                                          <div className="dateSelect">
                                            <button
                                              onClick={() =>
                                                setIsOpenSecond(index)
                                              }
                                              style={{
                                                background: "transparent",
                                                border: "unset",
                                                fontSize: "1.2rem",
                                                margin: "0 10px",
                                              }}
                                            >
                                              <div className="iconsStyle sucess">
                                                <BsCalendarCheck />
                                              </div>
                                            </button>
                                            {isOpenSecond === index ? (
                                              <div className="calenderPopup procedure">
                                                <Calendar
                                                  value={value.instruction_date}
                                                  onChange={(newDate) =>
                                                    testDateChange(
                                                      newDate,
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>
                                            ) : null}
                                            <span>
                                              {value.instruction_date === ""
                                                ? ""
                                                : moment(
                                                    value.instruction_date
                                                  ).format("DD-MM-YYYY")}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                              </div>
                              <div className="inputBoxInput">
                                <form
                                  onSubmit={instructionSubmit}
                                  data-type="Procedures"
                                >
                                  <div className="row">
                                    <div className="col-11">
                                      <div className="position-relative">
                                        <div className="inputpadding">
                                          <div className="iconadde">
                                            <div className="iconsStyle search">
                                              <BiPlus />
                                            </div>
                                          </div>
                                          <input
                                            autoComplete="off"
                                            type="text"
                                            name="name"
                                            placeholder="Type here..."
                                            value={inputData.proceedure}
                                            onClick={(e) => {
                                              searchDataList.type !==
                                                "Procedures" &&
                                                setSearchDataList({
                                                  text: e.target.value,
                                                  type: "Procedures",
                                                });
                                            }}
                                            onChange={(e) => {
                                              setInputData({
                                                ...inputData,
                                                proceedure: e.target.value,
                                              });
                                              setSearchDataList({
                                                text: e.target.value,
                                                type: "Procedures",
                                              });
                                            }}
                                          />
                                        </div>
                                        {listPopup === "Procedures" ? (
                                          <div className="listSearchDropDown">
                                            {suggestionList &&
                                            suggestionList.length === 0 ? (
                                              <ul ref={popupListRef}>
                                                <li>No Suggestion Found</li>
                                              </ul>
                                            ) : (
                                              <ul ref={popupListRef}>
                                                {suggestionList.map(
                                                  (value, i) => (
                                                    <li
                                                      key={i}
                                                      onClick={() => {
                                                        addDataList(
                                                          value.instruction_id,
                                                          value.instruction_type,
                                                          value.instruction_name,
                                                          value.instruction_patient_date
                                                        );
                                                        setListPopup(false);
                                                        setInputData({
                                                          ...inputData,
                                                          proceedure: "",
                                                          default: "",
                                                        });
                                                      }}
                                                      name={value.type}
                                                    >
                                                      {value.instruction_name}
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-1 col-2 d-flex justify-content-center align-items-center">
                                      <Button
                                        className="checkCircleButton"
                                        type="submit"
                                      >
                                        <BsCheck2Circle />
                                      </Button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            {/*============= Handouts Box =============*/}
                            <HandoutComp addIntoLocal={addIntoLocal} />
                          </div>
                        </Tab>

                        {/* ========== Summary Tab ============= */}
                        <Tab eventKey="SUMMARY" title="SUMMARY">
                          <div className="Treatment-Con m-0 p-0">
                            {/* save print buttons  */}
                            <div className="savePrintButtons d-flex justify-content-between">
                              <div className="savePrintButtons flex-column flex-sm-row">
                                <div className="savePrintButton">
                                  <div className="">
                                    <Button
                                      disabled={
                                        diagnosisList.length > 0 ||
                                        drugList.length > 0 ||
                                        patientInstruction.length > 0
                                          ? false
                                          : true
                                      }
                                      className="Clickbtn"
                                      onClick={saveData}
                                    >
                                      <SaveIcon
                                        style={{
                                          "font-size": "1.2rem",
                                          color: "#fff !important",
                                        }}
                                      />
                                      <span>Save & Print</span>
                                    </Button>
                                  </div>
                                </div>

                                {/* <ReactToPrint
                                  trigger={() => (
                                    <div className="savePrintButton">
                                      <Button className="Clickbtn">
                                        <BsFillPrinterFill />
                                        <span>Print</span>
                                      </Button>
                                    </div>
                                  )}
                                  content={() => domEl.current}
                                /> */}
                              </div>

                              <NextAppointment
                                setKey={setKey}
                                setShowBackdrop={setShowBackdrop}
                              />

                              {/* <div className="headerFooterDiv savePrintButtons">
                              {withHeader === true ? (
                                <div className="savePrintButton">
                                  <button
                                    onClick={() => headerFooterFunction(false)}
                                  >
                                    <span className="savePrintBtnIcon">
                                      <HiTemplate />
                                    </span>
                                    Hide Header & Footer
                                  </button>
                                </div>
                              ) : (
                                <div className="savePrintButton">
                                  <button
                                    className="Clickbtn"
                                    onClick={() => headerFooterFunction(true)}
                                  >
                                    <span className="savePrintBtnIcon">
                                      <HiTemplate />
                                    </span>
                                    Show Header & Footer
                                  </button>
                                </div>
                              )}
                            </div> */}
                            </div>

                            {/* Name and complaints section */}
                            <div ref={domEl}>
                              <ActiveTemplateComp
                                fontSize={templateSetting.fontSize}
                                templateName="Template 1"
                                fontFamily={templateSetting.fontStyle}
                                signature={templateSetting.signature}
                                preview={templateSetting.letterHead}
                                doctorDataFooter={templateSetting.doctorDetails}
                                patientDetail={patientDetail}
                                dateTime={dateTime}
                                withHeader={false}
                                activeTemplate={activeTemplate}
                                diagnosis={
                                  diagnosisList && diagnosisList.length > 0
                                    ? diagnosisList.filter(
                                        (value) => value.type === "Diagnosis"
                                      )
                                    : []
                                }
                                complaints={
                                  diagnosisList && diagnosisList.length > 0
                                    ? diagnosisList.filter(
                                        (value) => value.type === "Complaints"
                                      )
                                    : []
                                }
                                findings={
                                  diagnosisList && diagnosisList.length > 0
                                    ? diagnosisList.filter(
                                        (value) => value.type === "Findings"
                                      )
                                    : []
                                }
                                drugList={drugList}
                                test={
                                  patientInstruction &&
                                  patientInstruction.length > 0
                                    ? patientInstruction.filter(
                                        (value) =>
                                          value.instruction_type === "Test"
                                      )
                                    : []
                                }
                                procedures={
                                  patientInstruction &&
                                  patientInstruction.length > 0
                                    ? patientInstruction.filter(
                                        (value) =>
                                          value.instruction_type ===
                                          "Procedures"
                                      )
                                    : []
                                }
                                instruction={
                                  patientInstruction &&
                                  patientInstruction.length > 0
                                    ? patientInstruction.filter(
                                        (value) =>
                                          value.instruction_type ===
                                          "Instruction"
                                      )
                                    : []
                                }
                                followUp={
                                  otherData && otherData.length > 0
                                    ? otherData.filter(
                                        (value) =>
                                          value.patient_desc_type === "Follow"
                                      )
                                    : []
                                }
                                stepInstructionData={
                                  otherData && otherData.length > 0
                                    ? otherData.filter(
                                        (value) =>
                                          value.patient_desc_type === "Drug"
                                      )
                                    : []
                                }
                                diagnosisNote={
                                  otherData && otherData.length > 0
                                    ? otherData.filter(
                                        (value) =>
                                          value.patient_desc_type ===
                                          "Diagnosis"
                                      )
                                    : []
                                }
                                selectedRefrence={selectedRefrence}
                                selectedReferal={selectedReferal}
                                vitalData={vitalData}
                                languageList={languageList}
                                result={result}
                                handoutList={selectedHandouts}
                              />
                            </div>
                            <div style={parentStyle}>
                              <div id="domElCertificate" ref={domElCertificate}>
                                <Certificate
                                  headerHeight={templateSetting.headerHeight}
                                  footerHeight={templateSetting.footerHeight}
                                  fontSize={templateSetting.fontSize}
                                  dateTime={dateTime}
                                  preview={templateSetting.letterHead}
                                  patientDetail={patientDetail}
                                />
                              </div>
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StickyContainer>
      </SideBar>

      {/* =================================== Discription and Type Modal ================================= */}

      <Dialog
        open={activeModal}
        keepMounted
        onClose={() => setActiveModal(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px", // Set your width here
              height: "450px",
            },
          },
        }}
      >
        {complainDetaile.modal === "discription" ? (
          <DialogContent>
            <Form onSubmit={ClickData}>
              <div className="complaintTypeBox">
                <div className="complaintBoxHead">
                  <h6>{complainDetaile.type} Description</h6>
                </div>
                <div className="complaintBoxContent">
                  <div className="complaintNameText">
                    <div className="row">
                      <div className="col-3 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                        <div className="complaintName">
                          <h6>{complainDetaile.type} Name:</h6>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="complaintNameInput">
                          <input
                            type="text"
                            value={complainDetaile.complainsName}
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="addTypeSection mt-4">
                    <div className="row">
                      <div className="col-3 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                        <div className="addTypeHead">
                          <h6>Text</h6>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="input-style-1 w-100">
                          <textarea
                            className="form-control w-100"
                            rows={3}
                            defaultValue={complainDetaile.discription}
                            type="text"
                            name="discription"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-3 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center"></div>
                      <div className="col-8 mt-4">
                        <button
                          type="submit"
                          onClick={() => setActiveModal(false)}
                          value="submit"
                          className="btn btn-dark"
                        >
                          Update
                        </button>
                      </div>
                      <div className="col-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </DialogContent>
        ) : (
          <DialogContent>
            <div className="complaintTypeBox">
              <div className="complaintBoxHead">
                <h6>{complainDetaile.type} Description</h6>
              </div>
              <div className="complaintBoxContent">
                <div className="complaintNameText">
                  <div className="row">
                    <div className="col-3 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                      <div className="complaintName">
                        <h6>{complainDetaile.type} Name:</h6>
                      </div>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        value={complainDetaile.complainsName}
                        className="form-control"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="addTypeSection">
                  <Form
                    onSubmit={(e) => diagnosisType(e, complainDetaile.type)}
                    id="diagnosisTypeForm"
                  >
                    <div className="row mt-4 mb-2">
                      <div className="col-3 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                        <div className="addTypeHead">
                          <h6>Type</h6>
                        </div>
                      </div>
                      <div className="col-7">
                        <input
                          type="text"
                          name="typeName"
                          placeholder="Add a type"
                        />
                      </div>
                      <div className="col-1">
                        <Button className="checkCircleButton" type="submit">
                          <BsCheck2Circle />
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="addedTpyesSection">
                  <div className="row">
                    <div className="col-3 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                      {/* Empty */}
                    </div>

                    <div className="col-8">
                      <div className="row">
                        {typeData.length > 0 ? (
                          typeData.map((value, i) => (
                            <div key={i} className="col-6">
                              <div className="addedType">
                                <div className="input-style-1">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={checkedValue}
                                    value={value.account_type_name}
                                    id={value.account_data_id}
                                    checked={checkedValueData.includes(
                                      value.account_type_name
                                    )}
                                  />
                                  &nbsp;
                                  <label for={value.account_data_id}>
                                    {value.account_type_name}
                                  </label>
                                </div>
                                <div
                                  className="deleteIcon"
                                  onClick={() =>
                                    removeDataType(i, value.account_data_id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <MdDelete />
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-12 d-flex justify-content-center mt-5">
                            <strong>Not Found</strong>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="buttonSection">
                  <Button onClick={() => setActiveModal(false)}>Close</Button>
                  {activeDiabledButton === true ? (
                    ""
                  ) : (
                    <button className="btn" onClick={saveCheckData}>
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
        )}
      </Dialog>

      {/* =============================== Edit Drug =============================== */}

      <div className="editDrugBox">
        <div>
          <Dialog
            open={viewEditDrug}
            onClose={() => setViewEditDrug(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "800px", // Set your width here
                },
              },
            }}
          >
            {drugList.map((obj, i) =>
              obj.drug_id === moreEditDrug ? (
                <DialogContent>
                  <div key={i} className="popUpFormTitle">
                    <span>Edit Drug </span>
                  </div>
                  <div className="editDrugBody">
                    {/* Frequency section */}
                    <div className="frequencySection">
                      {/* <div className="row"> */}
                      {/* <div className="col-8"> */}
                      <div className="frequencyBox">
                        <div className="row">
                          <div className="col-sm-4 col-12">
                            <h6>Frequency</h6>
                          </div>
                          <div className="col-sm-2 col-4">
                            <h6>Dose</h6>
                          </div>
                          <div className="col-sm-6 col-8">
                            <div className="frequencyBtns">
                              <div className="row">
                                <div className="col-2">
                                  <h6>M</h6>
                                </div>
                                <div className="col-2">
                                  <h6>A</h6>
                                </div>
                                <div className="col-2">
                                  <h6>E</h6>
                                </div>
                                <div className="col-2">
                                  <h6>N</h6>
                                </div>
                                <div className="col-2">
                                  <h6>SOS</h6>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-4 d-none d-sm-block">
                            {/* Empty */}
                          </div>
                          <div className="col-4 col-sm-2">
                            <div className="doseInput">
                              <div className="input-style-1 ">
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={drugUpdate}
                                  defaultValue={obj.drug_dosg}
                                  data-name="dose"
                                  className="form-control paddin5"
                                  data-id={obj.drug_id}
                                />
                              </div>
                              <div className="doseInputText">
                                <p>
                                  {medicineShort.map((value) =>
                                    value.name === obj.drug_catogery
                                      ? value.shrt
                                      : " "
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-8">
                            <div className="frequencyBtns">
                              <div className="row d-flex align-items-center justify-content-center">
                                <div className="col-2">
                                  <div className="input-style-1">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="morning"
                                      value="morning"
                                      onChange={drugUpdate}
                                      data-name="frequencyMorning"
                                      data-id={obj.drug_id}
                                      defaultChecked={
                                        obj.drug_freq_morning === "null"
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-2">
                                  <div className="input-style-1">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="after"
                                      value="after"
                                      onChange={drugUpdate}
                                      data-name="frequencyAfterNoon"
                                      data-id={obj.drug_id}
                                      defaultChecked={
                                        obj.drug_freq_afternoon === "null"
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-2">
                                  <div className="input-style-1">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="evening"
                                      value="evening"
                                      onChange={drugUpdate}
                                      data-name="frequencyEvening"
                                      data-id={obj.drug_id}
                                      defaultChecked={
                                        obj.drug_freq_evening === "null"
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-2">
                                  <div className="input-style-1">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="night"
                                      value="night"
                                      onChange={drugUpdate}
                                      data-name="frequencyNight"
                                      data-id={obj.drug_id}
                                      defaultChecked={
                                        obj.drug_freq_night === "null"
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-2 ">
                                  <div className="input-style-1">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="sos"
                                      value="sos"
                                      onChange={drugUpdate}
                                      data-name="frequencySos"
                                      data-id={obj.drug_id}
                                      defaultChecked={
                                        obj.drug_freq_sos === "null"
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-2"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Duration Section */}
                    <div className="durationSection">
                      <div className="row">
                        <div className="col-sm-4 col-12">
                          <h6>Duration</h6>
                        </div>
                        <div className="col-md-2 col-sm-3 col-6 d-flex align-items-center justify-content-center">
                          <input
                            type="number"
                            name="duration"
                            data-name="drugDays"
                            data-id={obj.drug_id}
                            onChange={drugUpdate}
                            defaultValue={obj.drug_duration_days}
                          />
                        </div>
                        <div className="col-6 col-sm-3 col-md-2 d-flex align-items-center justify-content-center">
                          <select
                            name="duration"
                            id="duration"
                            data-name="drugDuration"
                            data-id={obj.drug_id}
                            onChange={drugUpdate}
                          >
                            {obj.drug_duration_dayType === "days" ? (
                              <option selected value="Days">
                                Days
                              </option>
                            ) : (
                              <option value="Days">Days</option>
                            )}

                            {obj.drug_duration_dayType === "week" ? (
                              <option selected value="Week">
                                Week
                              </option>
                            ) : (
                              <option value="Week">Week</option>
                            )}
                            {obj.drug_duration_dayType === "months" ? (
                              <option selected value="Months">
                                Months
                              </option>
                            ) : (
                              <option value="Months">Months</option>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="onceInWeek mt-4">
                      <div className="row">
                        <div className="col-4">
                          <h6>Once In</h6>
                        </div>
                        <div className="col-4">
                          <select
                            className="form-select"
                            name="duration"
                            id="duration"
                            onChange={drugUpdate}
                            data-name="onceIn"
                            data-id={obj.drug_id}
                          >
                            <option value={null}>Select Once In</option>
                            {obj.once_in === "week" ? (
                              <option value="week" selected>
                                Week
                              </option>
                            ) : (
                              <option value="week">Week</option>
                            )}
                            {obj.once_in === "month" ? (
                              <option value="month" selected>
                                Month
                              </option>
                            ) : (
                              <option value="month">Month</option>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    <hr />

                    {/* Relation with Food */}
                    <div className="relationWithFood">
                      <h6>Relation with Food</h6>
                      <div className="">
                        <div className="row mt-2">
                          <div className="col-lg-2 col-sm-3 col-6">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Before"
                              name="foodRelation"
                              value="Before"
                              onChange={drugUpdate}
                              data-name="foodRelation"
                              data-id={obj.drug_id}
                              checked={
                                obj.drug_relation === "Before" ? true : false
                              }
                            />
                              <label for="Before">Before</label>
                          </div>
                          <div className="col-lg-2 col-sm-3 col-6">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="After"
                              name="foodRelation"
                              value="After"
                              onChange={drugUpdate}
                              data-name="foodRelation"
                              data-id={obj.drug_id}
                              checked={
                                obj.drug_relation === "After" ? true : false
                              }
                            />
                              <label for="After">After</label>
                          </div>
                          <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            {" "}
                            <input
                              type="radio"
                              className="form-check-input"
                              id="AnyTime"
                              name="foodRelation"
                              value="AnyTime"
                              onChange={drugUpdate}
                              data-name="foodRelation"
                              data-id={obj.drug_id}
                              checked={
                                obj.drug_relation === "AnyTime" ? true : false
                              }
                            />
                              <label for="AnyTime">Any&nbsp;Time</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Drug Instruction */}
                    <div className="drugInstruction">
                      <h6>Drug Instructions</h6>
                      <div className="input-style-1 mt-2">
                        <textarea
                          className="form-control"
                          rows={3}
                          type="text"
                          defaultValue={obj.drug_instruction}
                          placeholder="Enter drug instructions"
                          onChange={drugUpdate}
                          data-id={obj.drug_id}
                          data-name="drugInstruction"
                        />
                      </div>
                    </div>

                    <div className="stripDetailSection d-flex align-items-center">
                      <div className="row d-flex align-items-center">
                        <div className="col-sm-3 col-6 m-0 p-0 justify-content-center d-flex align-items-center d-flex align-items-center">
                          <div className="stripText">
                            <h6 style={{ margin: "0" }}>1 Strip =</h6>
                          </div>
                        </div>
                        <div className="col-sm-6 col-3 d-flex align-items-center">
                          <div className="input-style-1">
                            <input
                              className="form-control"
                              type="number"
                              placeholder=""
                              onChange={drugUpdate}
                              data-id={obj.drug_id}
                              data-name="drugStrip"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />

                    {/* Autodose Calculator  */}
                    {/* <div className="autodoseCalSection">
                      <div className="autodoseCalHead">
                        <div className="row">
                          <div className="col-8">
                            <div className="autodoseCalHeadText">
                              <h6>Autodose Calculator</h6>
                              <h6 className="appplyH6">
                                <span>
                                  <BsInfoCircleFill />
                                </span>{" "}
                                Applies for all consultation
                              </h6>
                            </div>
                          </div>
                          <div className="col-3 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                            <div className="autoCalHowMuch">
                              <h6>How Much</h6>
                            </div>
                          </div>
                          <div className="col-1">
                            <div className="resetButton"></div>
                          </div>
                        </div>
                      </div>
                      <div className="autoDoseCalInput">
                        <div className="row">
                          <div className="col-8">
                            <div className="autodoseCalradioBtns">
                              <div className="row">
                                <div className="col-6 d-flex justify-content-start">
                                  <input
                                    type="radio"
                                    id="fixedDose"
                                    name="dose_type"
                                    value="fixedDose"
                                  />
                                  <label for="fixedDose">Fixed Dose</label>
                                </div>
                                <div className="col-6 d-flex justify-content-start">
                                  <input
                                    type="radio"
                                    id="mgKgDose"
                                    name="dose_type"
                                    value="mgKgDose"
                                  />
                                  <label for="mgKgDose">Mg/Kg/Dose</label>
                                </div>
                                <div className="col-6 d-flex justify-content-start">
                                  <input
                                    type="radio"
                                    id="mgKgDay"
                                    name="dose_type"
                                    value="mgKgDay"
                                  />
                                  <label for="mgKgDay">Mg/Kg/Day</label>
                                </div>
                                <div className="col-6 d-flex justify-content-start">
                                  <input
                                    type="radio"
                                    id="mdmsqdose"
                                    name="dose_type"
                                    value="mdmsqdose"
                                  />
                                  <label for="mdmsqdose">
                                    Mg/m <sup>2</sup>/Dose
                                  </label>
                                </div>
                                <div className="col-6 d-flex justify-content-start">
                                  <input
                                    type="radio"
                                    id="mgmsqday"
                                    name="dose_type"
                                    value="mgmsqday"
                                  />
                                  <label for="mgmsqday">
                                    Mg/m<sup>2</sup>/Day
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="autoCalHowMuch">
                              <div className="input-style-1">
                                <input  className="" type="text" />
                              </div>
                            </div>
                          </div>
                          <div className="col-1">
                            <div className="resetButton">
                              <button>Reset</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div> */}

                    {/* Taper Dose */}
                    <div className="taperDoseSection">
                      <div className="taperSectionHead">
                        <h6>Taper Dose</h6>
                        <h6
                          className="appplyH6"
                          style={{ color: "rgb(9, 57, 101)" }}
                        >
                          <span>
                            <BsInfoCircleFill />
                          </span>{" "}
                          Applies for all consultation
                        </h6>
                      </div>
                      <div className="taperDataTitles mt-3">
                        <div className="row d-none d-sm-flex">
                          <div className="col-md-3 col-6 d-flex d-flex align-items-center">
                            <div className="taperTitle">
                              <p>Start Date</p>
                            </div>
                          </div>
                          <div className="col-md-2 col-6 d-flex d-flex align-items-center">
                            <div className="taperTitle">
                              <p>End Date</p>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="row">
                              <div className="col-6">
                                <div className="taperTitle">
                                  <h6>Day</h6>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="taperTitle">
                                  <h6>Dose</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="taperTime">
                              <div className="row">
                                <div className="col-2 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                                  <h6>M</h6>
                                </div>
                                <div className="col-2 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                                  <h6>A</h6>
                                </div>
                                <div className="col-2 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                                  <h6>E</h6>
                                </div>
                                <div className="col-2 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                                  <h6>N</h6>
                                </div>
                                <div className="col-2 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                                  <h6>SOS</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-1">
                            <div className="taperBtns">{/* Empty  */}</div>
                          </div>
                        </div>
                      </div>
                      <div className="taperDataInput">
                        {taperList &&
                          taperList.map((value, index) =>
                            value.drug_id === obj.drug_id ? (
                              <div key={index} className="row mb-4">
                                <div className="col-md-3 col-5 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center flex-column flex-sm-row">
                                  <div className="taperTitle d-block d-sm-none">
                                    <p style={{ margin: "0" }}>Start Date</p>
                                  </div>
                                  <div className="taperTitleInput">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        label="From Date"
                                        inputFormat="DD-MM-YYYY"
                                        value={value.fromDate}
                                        onChange={(newDate) =>
                                          handleFromDateChange(newDate, index)
                                        }
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                </div>
                                <div className="col-md-2 col-6 d-flex align-items-center m-0 p-0 justify-content-center flex-column">
                                  <div className="taperTitle d-block d-sm-none">
                                    <p style={{ margin: "0" }}>End Date</p>
                                  </div>
                                  <div className="taperTitleInput">
                                    <h6>
                                      {value.toDate !== ""
                                        ? moment(value.toDate).format(
                                            "DD-MM-YYYY"
                                          )
                                        : "DD-MM-YYYY"}
                                    </h6>
                                  </div>
                                </div>
                                <div className="col-md-3 col-12">
                                  <div className="row">
                                    <div className="col-6 d-flex justify-content-center align-items-center flex-column">
                                      <div className="taperTitle d-block d-sm-none">
                                        <p style={{ margin: "0" }}>Day</p>
                                      </div>
                                      <div className="taperTitleInput">
                                        <input
                                          type="number"
                                          className="form-control"
                                          onChange={(e) =>
                                            handleDaysChange(e, index)
                                          }
                                          value={value.days}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6 d-flex justify-content-center align-items-center flex-column">
                                      <div className="taperTitle d-block d-sm-none">
                                        <p style={{ margin: "0" }}>Dosg</p>
                                      </div>
                                      <div className="taperTitleInput">
                                        <input
                                          type="number"
                                          className="form-control"
                                          onInput={(e) =>
                                            handleDoseChange(e, index)
                                          }
                                          defaultValue={value.dose}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 col-12">
                                  <div className="taperTimeInput">
                                    <div className="row">
                                      <div className="col-2 d-flex align-items-center justify-content-center flex-column">
                                        <div className="taperTitle d-block d-sm-none">
                                          <p style={{ margin: "0" }}>M</p>
                                        </div>
                                        <input
                                          data-name="tapperMorning"
                                          type="checkbox"
                                          className="form-check-input"
                                          value="morning"
                                          name="dose_time"
                                          checked={
                                            value.taper_freq_morning !== "null"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            tapperFrequency(e, index)
                                          }
                                        />
                                      </div>
                                      <div className="col-2 d-flex align-items-center justify-content-center flex-column">
                                        <div className="taperTitle d-block d-sm-none">
                                          <p style={{ margin: "0" }}>A</p>
                                        </div>
                                        <input
                                          data-name="tapperAfterNoon"
                                          type="checkbox"
                                          className="form-check-input"
                                          value="after"
                                          name="dose_time"
                                          checked={
                                            value.taper_freq_afternoon !==
                                            "null"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            tapperFrequency(e, index)
                                          }
                                        />
                                      </div>
                                      <div className="col-2 d-flex align-items-center justify-content-center flex-column">
                                        <div className="taperTitle d-block d-sm-none">
                                          <p style={{ margin: "0" }}>E</p>
                                        </div>
                                        <input
                                          data-name="tapperEvening"
                                          type="checkbox"
                                          className="form-check-input"
                                          value="evening"
                                          name="dose_time"
                                          checked={
                                            value.taper_freq_evening !== "null"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            tapperFrequency(e, index)
                                          }
                                        />
                                      </div>
                                      <div className="col-2 d-flex align-items-center justify-content-center flex-column">
                                        <div className="taperTitle d-block d-sm-none">
                                          <p style={{ margin: "0" }}>N</p>
                                        </div>
                                        <input
                                          data-name="tapperNight"
                                          type="checkbox"
                                          className="form-check-input"
                                          value="night"
                                          name="dose_time"
                                          checked={
                                            value.taper_freq_night !== "null"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            tapperFrequency(e, index)
                                          }
                                        />
                                      </div>
                                      <div className="col-2 d-flex align-items-center justify-content-center flex-column">
                                        <div className="taperTitle d-block d-sm-none">
                                          <p style={{ margin: "0" }}>SOS</p>
                                        </div>
                                        <input
                                          data-name="tapperSOS"
                                          type="checkbox"
                                          className="form-check-input"
                                          value="sos"
                                          name="dose_time"
                                          checked={
                                            value.taper_freq_sos !== "null"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            tapperFrequency(e, index)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-1">
                                  <div className="row">
                                    <div className="col-6">
                                      <button
                                        className="iconsStyle delete"
                                        onClick={() => removeTaper(index)}
                                      >
                                        <MdDelete />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          )}
                      </div>
                    </div>

                    <hr />

                    {/* Add Taper Button */}
                    <div className="addTaperDose">
                      <button
                        className="Clickbtn"
                        onClick={() => addTaperList(obj.drug_id)}
                      >
                        + Add Taper Dose
                      </button>
                    </div>

                    {/* == */}
                  </div>

                  <div className="buttonGroups">
                    <button
                      className="Cancelbtn "
                      onClick={() => setViewEditDrug(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="Clickbtn"
                      onClick={() => updateFinalDrug(obj.drug_id, i)}
                    >
                      Save
                    </button>
                  </div>
                </DialogContent>
              ) : (
                " "
              )
            )}
          </Dialog>
        </div>
      </div>

      <button
        className="otherDetailAppointment d-flex d-xl-none"
        onClick={() => setStateAgain(true)}
      >
        <FaAngleRight />
      </button>
      <Drawer
        sx={{ zIndex: "1000000" }}
        anchor="left"
        open={stateAgain}
        onClose={() => setStateAgain(false)}
      >
        <Box
          sx={{ width: 400 }}
          role="presentation"
          onKeyDown={() => setStateAgain(false)}
        >
          <div className="prescriptionSuggestSummary">
            <div className="sugguestionTitle d-flex justify-content-between align-items-center px-2 my-3">
              <h5>Priscription Suggestions</h5>
              <button
                className="Cancelbtn"
                onClick={() => setStateAgain(false)}
              >
                Close
              </button>
            </div>
            <Accordion>
              {/* ============ Follow Up After ============ */}
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="accordionIcon">
                    <BsFillCalendarCheckFill />
                  </div>{" "}
                  Follow Up After
                </Accordion.Header>
                <Accordion.Body>
                  <div className="summaryAccordianBox">
                    <div className="followupDays">
                      <div className="followDaysButtons">
                        <div className="followDayButton">
                          <input
                            type="button"
                            value="1"
                            className="form-control"
                            onClick={(e) =>
                              followDateChange(e, "number", "day")
                            }
                          />
                        </div>
                        <div className="followDayButton">
                          <input
                            type="button"
                            value="2"
                            className="form-control"
                            onClick={(e) =>
                              followDateChange(e, "number", "day")
                            }
                          />
                        </div>
                        <div className="followDayButton">
                          <input
                            type="button"
                            value="3"
                            className="form-control"
                            onClick={(e) =>
                              followDateChange(e, "number", "day")
                            }
                          />
                        </div>
                        <div className="followDayButton">
                          <input
                            type="button"
                            value="4"
                            className="form-control"
                            onClick={(e) =>
                              followDateChange(e, "number", "day")
                            }
                          />
                        </div>
                        <div className="followDayButton">
                          <input
                            type="button"
                            value="5"
                            className="form-control"
                            onClick={(e) =>
                              followDateChange(e, "number", "day")
                            }
                          />
                        </div>

                        <div className="followDayText d-flex align-items-center">
                          <h6 style={{ color: "rgb(9, 57, 101)" }}>Days</h6>
                        </div>
                        <div className="followDayResetBtn">
                          <button
                            className="Clickbtn"
                            onClick={(e) => followDateChange(e, "reset")}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      <hr />
                      <div className="followWeekButtons mt-3">
                        <div className="row">
                          <div className="followweekButton col-6 col-xl-4 p-xl-0 mb-2 mb-xl-0 px-2">
                            <input
                              type="button"
                              value="1 Week"
                              className="form-control"
                              onClick={(e) =>
                                followDateChange(e, "number", "week")
                              }
                            />
                          </div>
                          <div className="followweekButton col-6 col-xl-4 p-xl-0 mb-2 mb-xl-0 px-2">
                            <input
                              type="button"
                              value="1 Month"
                              className="form-control"
                              onClick={(e) =>
                                followDateChange(e, "number", "month")
                              }
                            />
                          </div>
                          <div className="followweekButton col-6 col-xl-4 p-xl-0 mb-2 mb-xl-0 px-2">
                            <input
                              type="button"
                              value="1 Year"
                              className="form-control"
                              onClick={(e) =>
                                followDateChange(e, "number", "year")
                              }
                            />
                          </div>
                          <div className="followTimeButton col-6 col-xl-4 p-xl-0 mb-2 mb-xl-0 px-2">
                            <input
                              type="date"
                              className="form-control"
                              value={otherData
                                .filter(
                                  (value) =>
                                    value.patient_desc_type === "Follow"
                                )
                                .map((value) => value.follow_up)}
                              onChange={(e) => followDateChange(e, "date")}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="followInput">
                        <div className="inputBoxInput">
                          <form
                            onSubmit={(e) =>
                              followDateChange(e, "selectedDate")
                            }
                          >
                            <div className="row">
                              <div className="col-5">
                                <div className="input-style-1">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="daysNumber"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                              <div className="col-5">
                                <div className="input-style-1">
                                  <select
                                    name="duration"
                                    id="duration"
                                    className="form-select"
                                  >
                                    <option value="days">Days</option>
                                    <option value="weeks">Weeks</option>
                                    <option value="months">Months</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-2 d-flex justify-content-center align-items-center">
                                <Button
                                  className="checkCircleButton"
                                  type="submit"
                                >
                                  <BsCheck2Circle />
                                </Button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <hr />
                      <div className="followInputBox">
                        <div className="inputBoxInput">
                          <form onSubmit={(e) => followDateChange(e, "note")}>
                            <div className="row">
                              <div className="col-10">
                                <div className="input-style-1">
                                  <textarea
                                    className="form-control"
                                    rows={3}
                                    name="noteInput"
                                    defaultValue={otherData
                                      .filter(
                                        (value) =>
                                          value.patient_desc_type === "Follow"
                                      )
                                      .map((value) => value.patient_desc_text)}
                                    type="text"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                              <div className="col-2 d-flex justify-content-center align-items-center">
                                <Button
                                  className="checkCircleButton"
                                  type="submit"
                                >
                                  <BsCheck2Circle />
                                </Button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              {/* =============== References ================ */}
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div className="accordionIcon">
                    <BsArrowDownLeftCircleFill />
                  </div>
                  References
                </Accordion.Header>
                <Accordion.Body>
                  <div className="referencesAccordian w-100">
                    <div className="referencesSearchBox w-100">
                      <div className="row">
                        <div className="col-9">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search References"
                          />
                        </div>
                        <div className="col-3">
                          <div className="referenceAddButton">
                            <button
                              className="Clickbtn"
                              onClick={() => referalRefrence("References")}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="referencesList">
                      <div className="row">
                        {referalDataList && referalDataList.length > 0 ? (
                          referalDataList.map((value, i) =>
                            value.doctor_references_type === "References" ? (
                              <div key={i} className="col-6">
                                <Form.Check
                                  onChange={(e) =>
                                    referalRefrenceSelected(
                                      e,
                                      value.doctor_references_id,
                                      value.doctor_references_type
                                    )
                                  }
                                  type="radio"
                                  name="refrences"
                                  id={`default-${value.doctor_references_id}`}
                                  label={value.doctor_references_name}
                                />
                              </div>
                            ) : (
                              ""
                            )
                          )
                        ) : (
                          <h6>No Data Found</h6>
                        )}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              {/* ================ Referrals  ===============  */}
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div className="accordionIcon">
                    <BsArrowUpRightCircleFill />
                  </div>
                  Referrals
                </Accordion.Header>
                <Accordion.Body>
                  <div className="referencesAccordian">
                    <div className="referencesSearchBox">
                      <div className="row">
                        <div className="col-9">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search Referrals"
                          />
                        </div>
                        <div className="col-3 d-flex align-items-center m-0 p-0 justify-content-center d-flex align-items-center">
                          <div className="referenceAddButton">
                            <button
                              className="Clickbtn"
                              onClick={() => referalRefrence("Referrals")}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="referencesList">
                      <div className="row">
                        {referenceDataList && referenceDataList.length > 0 ? (
                          referenceDataList.map((value, i) =>
                            value.doctor_references_type === "Referrals" ? (
                              <div key={i} className="col-6">
                                <Form.Check
                                  onChange={(e) =>
                                    referalSelected(
                                      e,
                                      value.doctor_references_id,
                                      value.doctor_references_type
                                    )
                                  }
                                  type="radio"
                                  name="refrels"
                                  id={`default-${value.doctor_references_id}`}
                                  label={value.doctor_references_name}
                                />
                              </div>
                            ) : (
                              ""
                            )
                          )
                        ) : (
                          <h6>No Data Found</h6>
                        )}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              {/* ================ Change Language ============ */}
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div className="accordionIcon">
                    <SiGoogletranslate />
                  </div>
                  Change Language
                </Accordion.Header>
                <Accordion.Body>
                  <div className="languageOptionAccordian">
                    <div className="row">
                      <div className="col-6">
                        <Form.Check
                          type="radio"
                          name="language"
                          id="english"
                          value="english"
                          label="English"
                          checked={selectLanguage === "english"}
                          onChange={selectedFunction}
                        />
                      </div>
                      <div className="col-6">
                        <Form.Check
                          type="radio"
                          name="language"
                          id="hindi"
                          value="hindi"
                          label="Hindi"
                          checked={selectLanguage === "hindi"}
                          onChange={selectedFunction}
                        />
                      </div>
                      <div className="col-6">
                        <Form.Check
                          type="radio"
                          name="language"
                          id="marathi"
                          value="marathi"
                          label="Marathi"
                          checked={selectLanguage === "marathi"}
                          onChange={selectedFunction}
                        />
                      </div>
                      <div className="col-6">
                        <Form.Check
                          type="radio"
                          name="language"
                          id="gujrati"
                          value="gujrati"
                          label="Gujrati"
                          checked={selectLanguage === "gujrati"}
                          onChange={selectedFunction}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              {/* =========== Upload Records ===============   */}
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <div className="accordionIcon">
                    <BsFillFileEarmarkMedicalFill />
                  </div>
                  Generate Certificate
                </Accordion.Header>
                <Accordion.Body>
                  <div className="generateCertificate">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="accordionIcon">
                            <FaBriefcaseMedical />
                          </div>
                          Medical Certificate
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-6">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Suffering From"
                                  inputFormat="DD-MM-YYYY"
                                  value={mediacalDate.sufferingFrom}
                                  onChange={(newDate) =>
                                    setMediacalDate({
                                      ...mediacalDate,
                                      sufferingFrom: newDate,
                                    })
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className="col-6">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Suffering To"
                                  inputFormat="DD-MM-YYYY"
                                  value={mediacalDate.sufferingTo}
                                  onChange={(newDate) =>
                                    setMediacalDate({
                                      ...mediacalDate,
                                      sufferingTo: newDate,
                                    })
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-6">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Rest Till"
                                  inputFormat="DD-MM-YYYY"
                                  value={mediacalDate.restTill}
                                  onChange={(newDate) =>
                                    setMediacalDate({
                                      ...mediacalDate,
                                      restTill: newDate,
                                    })
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className="col-6">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Resume From"
                                  inputFormat="DD-MM-YYYY"
                                  value={mediacalDate.resumeFrom}
                                  onChange={(newDate) =>
                                    setMediacalDate({
                                      ...mediacalDate,
                                      resumeFrom: newDate,
                                    })
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <hr />
                          {addMore ? (
                            <div className="addMoreDetail">
                              <textarea
                                className="form-control"
                                value={mediacalDate.moreDetail}
                                onChange={(e) =>
                                  setMediacalDate({
                                    ...mediacalDate,
                                    moreDetail: e.value,
                                  })
                                }
                                rows="3"
                              ></textarea>
                              <hr />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="row">
                            <div className="col-6">
                              {addMore ? (
                                ""
                              ) : (
                                <button
                                  className="btn btn-dark"
                                  onClick={() => setAddMore(true)}
                                >
                                  Add More
                                </button>
                              )}
                            </div>
                            <div className="col-6 d-flex justify-content-end align-items-center">
                              <button
                                className="btn btn-success"
                                onClick={certificatePrint}
                              >
                                Save & Print
                              </button>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <div className="accordionIcon">
                            <FaClinicMedical />
                          </div>
                          Leave Certificate
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-6">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Suffering From"
                                  inputFormat="DD-MM-YYYY"
                                  value={mediacalDate.sufferingFrom}
                                  onChange={(newDate) =>
                                    setMediacalDate({
                                      ...mediacalDate,
                                      sufferingFrom: newDate,
                                    })
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className="col-6">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                  <DesktopDatePicker
                                    label="Suffering To"
                                    inputFormat="DD/MM/YYYY"
                                    value={mediacalDate.sufferingTo}
                                    onChange={(newDate) =>
                                      setMediacalDate({
                                        ...mediacalDate,
                                        sufferingTo: newDate,
                                      })
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </Stack>
                              </LocalizationProvider>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-6">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Rest Till"
                                  inputFormat="DD-MM-YYYY"
                                  value={mediacalDate.restTill}
                                  onChange={(newDate) =>
                                    setMediacalDate({
                                      ...mediacalDate,
                                      restTill: newDate,
                                    })
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className="col-6">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Resume From"
                                  inputFormat="DD-MM-YYYY"
                                  value={mediacalDate.resumeFrom}
                                  onChange={(newDate) =>
                                    setMediacalDate({
                                      ...mediacalDate,
                                      resumeFrom: newDate,
                                    })
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <hr />
                          {addMore ? (
                            <div className="addMoreDetail">
                              <textarea
                                className="form-control"
                                value={mediacalDate.moreDetail}
                                onChange={(e) =>
                                  setMediacalDate({
                                    ...mediacalDate,
                                    moreDetail: e.value,
                                  })
                                }
                                rows="3"
                              ></textarea>
                              <hr />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="row">
                            <div className="col-6">
                              {addMore ? (
                                ""
                              ) : (
                                <button
                                  className="btn btn-dark"
                                  onClick={() => setAddMore(true)}
                                >
                                  Add More
                                </button>
                              )}
                            </div>
                            <div className="col-6 d-flex justify-content-end align-items-center">
                              <button
                                className="btn btn-success"
                                onClick={certificatePrint}
                              >
                                Save & Print
                              </button>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Box>
      </Drawer>
      {/* ====================== Snack bar ================ */}
      <Snackbar
        open={alertDiloge}
        autoHideDuration={6000}
        onClose={() => setAlertDiloge(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        key="right"
      >
        <Alert
          onClose={() => setAlertDiloge(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AppointmentDetail;
