import { Button, message, notification } from "antd";
import React, { createContext, useContext, useEffect, useState } from "react";
import { FaWifi } from "react-icons/fa";
import { MdOutlineWifiOff } from "react-icons/md";

const CheckInterContext = createContext();

export const useCheckInternet = () => useContext(CheckInterContext);

const CheckInternet = ({ children }) => {
  const [isConnected, setIsConnected] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsConnected(navigator.onLine);
    };

    // Add event listeners for online/offline events
    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    // Cleanup the event listeners when the component is unmounted
    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
    // eslint-disable-next-line
  }, []);

  const contextValue = {
    isConnected,
    setIsConnected,
  };

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (isConnected) {
      // openNotification("top", "Online mode start", "online");
    } else {
      openNotification("top", "No Internet please check", "offline");
    }
  }, [isConnected]);

  const openNotification = (placement, title, type) => {
    api.info({
      message: title,
      // description: type === "online" && (
      //   <div>
      //     <p>You have saved some data in local storage sync data to server</p>
      //     <Button onClick={saveToDServer}>Sync Data</Button>
      //   </div>
      // ),
      placement,
      icon: type ? (
        <FaWifi
          style={{
            color: "#111",
          }}
        />
      ) : (
        <MdOutlineWifiOff
          style={{
            color: "#111",
          }}
        />
      ),
    });
  };

  const saveToDServer = () => {
    message
      .open({
        type: "loading",
        content: "Uploading data...",
        duration: 2.5,
      })
      .then(() => message.success("Uploded successfully", 2.5));
      localStorage.removeItem("priscriptionSave")
  };

  return (
    <CheckInterContext.Provider value={contextValue}>
      {contextHolder}
      {children}
    </CheckInterContext.Provider>
  );
};

export default CheckInternet;
