import { Avatar, Tooltip } from "@mui/material";
import { Popover } from "antd";
import moment from "moment";
import React from "react";
import { FaWallet } from "react-icons/fa";
import { TbReport } from "react-icons/tb";
import { PiCurrencyInrBold } from "react-icons/pi";

export default function FirstDesing({
  onAppointmentFormOpening,
  bookedList,
  formattedDate,
  slotList,
  hospitalId,
  VitalFunction,
}) {
  return (
    <div className="row">
      {slotList.map((obj, index) => (
        <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12 p-2">
          <div className="Shedular-box-con-main">
            <div
              className={`Shedular-box-type-card ${
                moment().isAfter(
                  moment(formattedDate + " " + obj, "YYYY-MM-DD h:mm A")
                )
                  ? bookedList.some(
                      (value) => value.appointment_start_time === obj
                    )
                    ? bookedList.filter(
                        (valueData) => valueData.appointment_start_time === obj
                      )[0].appointment_status === "visit"
                      ? "Success"
                      : "Booked"
                    : "Done"
                  : bookedList.some(
                      (value) => value.appointment_start_time === obj
                    )
                  ? bookedList.filter(
                      (valueData) => valueData.appointment_start_time === obj
                    )[0].appointment_status === "visit"
                    ? "Success"
                    : "Booked"
                  : "Availeble"
              }`}
              onDoubleClick={() =>
                onAppointmentFormOpening(
                  moment().isAfter(
                    moment(formattedDate + " " + obj, "YYYY-MM-DD h:mm A")
                  )
                    ? bookedList.some(
                        (value) => value.appointment_start_time === obj
                      )
                      ? "navigateData"
                      : true
                    : bookedList.some(
                        (value) => value.appointment_start_time === obj
                      )
                    ? "navigateData"
                    : false,
                  obj,
                  hospitalId,
                  formattedDate,
                  obj,
                  "online"
                )
              }
            >
              {bookedList.map((value, index) =>
                value.appointment_start_time === obj ? (
                  <div key={index} className="row">
                    <div className="col-2">
                      <div className="Shedular-Box-card-head">
                        <Avatar
                          src={value.patient_profile_Image}
                          alt={value.patient_first_name}
                        />
                      </div>
                    </div>

                    <div className="col-10 Shedular-Box-card-Deatils">
                      <div className="Shedular-Box-card-data">
                        <h6>
                          {value.patient_first_name}&nbsp;
                          {value.patient_last_name}(
                          {moment().diff(value.pateint_date_of_birth, "Years") +
                            ` Y`}
                          )
                        </h6>
                        <h5>
                          <span>
                            +91&nbsp;
                            {value.patient_phone_no}
                          </span>
                          &nbsp;
                          <span>{value.purpose_of_visit}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : null
              )}
              <div className="Shedular-Box-card-Date">
                <p>{obj}</p>
                {bookedList.map((value, index) =>
                  value.appointment_start_time === obj ? (
                    <div
                      key={index}
                      className="afterBookedDetail d-flex justify-content-end align-items-center"
                    >
                      {/* <Popover
                        content={
                          <div>
                            <p style={{color:"darkorange"}}>Balance to pay</p>
                            <p className="d-flex justify-content-between align-items-center"><div className="paymentDetail d-flex justify-content-between align-items-center"><PiCurrencyInrBold /> 500</div>  <button className="btn Clickbtn">Paid</button></p>
                           
                          </div>
                        }
                        title="Fees Details"
                        trigger="click"
                      >
                        <Tooltip title="500 Remainaing">
                          <button className="btn Cancelbtn">
                            <FaWallet />
                          </button>
                        </Tooltip>
                      </Popover> */}
                      <Tooltip title="Add Vital">
                        <button
                          className="btn Clickbtn"
                          onClick={() => VitalFunction(value.patient_id_reff)}
                        >
                          <TbReport />
                        </button>
                      </Tooltip>
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
