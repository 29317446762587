import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/Sidebar/SideBar";
import { Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import {
  useAddNewHospitalMutation,
  useViewOnlyHospitalQuery,
  useEditHospitalMutation,
} from "../services/userAuthAPI";
import "../css/AddHospital.css";
import { useParams } from "react-router-dom";
import HospitalVector from "../Images/hospital-vector.webp";
import NavbarComp from "../components/NavbarComp/NavbarComp.js";
import { getToken } from "../services/localStorageServices";
import { useImageResizer } from "../context/imageResizer.js";
import { Spin } from "antd";

const AddHospital = () => {
  const [file, setFile] = useState();
  const [imageFile, setImageFile] = useState(null);
  const navigate = useNavigate();
  const { access_token } = getToken();
  const { id } = useParams();
  const [addNewHospital] = useAddNewHospitalMutation();
  const [editHospital] = useEditHospitalMutation();
  const { data } = useViewOnlyHospitalQuery({
    token: access_token,
    hospital_ID: id,
  });
  const [listData, setListData] = useState([]);
  const [description, setDescription] = useState();
  const { resizeFile } = useImageResizer();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id !== "add") {
      const hospital_list = JSON.parse(sessionStorage.getItem("hospaitl_list"));

      if (hospital_list === null || hospital_list.length === 0) {
        setListData(data);
        setImageFile(data[0].logo);
      } else {
        const filterHospital = hospital_list.filter(
          (value) => value.list_of_account === id
        );
        setListData(filterHospital);
        setDescription(filterHospital[0].description);
        setImageFile(filterHospital[0].logo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async (e) => {
    const maxWidth = 300;
    const maxHeight = 300;
    const targetFileSize = 150 * 1024; 

    const compresedFile = await resizeFile(e.target.files[0],maxWidth, maxHeight, targetFileSize);
    setFile(compresedFile);
    setImageFile(URL.createObjectURL(compresedFile));
  };
  // ========================= New Hospital Add ===================

  const newHospital = (id, data) => {
    var newHospital = [];
    const getHospital = JSON.parse(sessionStorage.getItem("hospaitl_list"));
    if (getHospital === null || getHospital.length === 0) {
      newHospital = [];
    } else {
      newHospital = getHospital;
    }

    const newData = {
      first_name: data.get("name"),
      address: data.get("address"),
      city: data.get("city"),
      description: data.get("shortDesc"),
      gmb_link: data.get("gmblink"),
      logo: imageFile,
      mobile: data.get("phone"),
      pincode: data.get("pincode"),
      state: data.get("state"),
      list_of_account: id,
      hospital_shedule: [],
    };
    newHospital.push(newData);
    sessionStorage.setItem("hospaitl_list", JSON.stringify(newHospital));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData(e.currentTarget);
    const formData = new FormData();
    formData.append("logo", file);
    formData.append("hospital_Name", data.get("name"));
    formData.append("address", data.get("address"));
    formData.append("phone_no", data.get("phone"));
    formData.append("Description", data.get("shortDesc"));
    formData.append("city", data.get("city"));
    formData.append("pincode", data.get("pincode"));
    formData.append("link_address", data.get("gmblink"));
    formData.append("state", data.get("state"));
    formData.append("token", access_token);

    const req = await addNewHospital(formData);
    if (req.data) {
      setLoading(false);

      newHospital(req.data.id, data);
      navigate("/hospitals");
    }
  };

  // ================================= Hospital Update =============================

  const updateHospitalLocal = (id, data) => {
    const sessionData = JSON.parse(sessionStorage.getItem("hospaitl_list"));
    const index = sessionData.findIndex(
      (value) => value.list_of_account === id
    );
    // Update the value of the specific item
    sessionData[index] = {
      ...sessionData[index],
      first_name: data.get("name"),
      address: data.get("address"),
      city: data.get("city"),
      description: data.get("shortDesc"),
      gmb_link: data.get("gmblink"),
      logo: imageFile,
      mobile: data.get("phone"),
      pincode: data.get("pincode"),
      state: data.get("state"),
    };

    sessionStorage.setItem("hospaitl_list", JSON.stringify(sessionData));
  };

  const EditHospital = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData(e.currentTarget);
    const formData = new FormData();
    formData.append("profile_Image", file);
    formData.append("hospitalName", data.get("name"));
    formData.append("address", data.get("address"));
    formData.append("phone_no", data.get("phone"));
    formData.append("Description", data.get("shortDesc"));
    formData.append("city", data.get("city"));
    formData.append("pincode", data.get("pincode"));
    formData.append("link_address", data.get("gmblink"));
    formData.append("state", data.get("state"));
    formData.append("token", access_token);
    formData.append("list_of_Account", id);

    const req = await editHospital(formData);

    if (req.data) {
      setLoading(false);
      updateHospitalLocal(id, data);
      navigate("/hospitals");
    }
  };

  // ===================================== Validation =========================
  const [characters, setCharacters] = useState(0);
  const [checkValid, setCheckValid] = useState({
    name: true,
    phone: true,
    address: true,
    city: true,
    state: true,
    pincode: true,
    gmbLink: true,
    description: true,
  });

  const required = (value) => {
    if (!value.toString().trim().length) {
      // Return JSX with a style attribute for red text
      return <span style={{ color: "red" }}>This field is required</span>;
    }
  };

  const phone = (value) => {
    const phoneNumber = value.toString().trim();

    if (!phoneNumber.length) {
      return <span style={{ color: "red" }}>This field is required</span>;
    }

    if (!/^\d{10}$/.test(phoneNumber)) {
      return (
        <span style={{ color: "red" }}>Phone number must be 10 digits</span>
      );
    }
  };

  const pincode = (value) => {
    const pincodeData = value.toString().trim();

    if (!pincodeData.length) {
      return <span style={{ color: "red" }}>This field is required</span>;
    }

    if (!/^\d{6}$/.test(pincodeData)) {
      return <span style={{ color: "red" }}>Please enter valid pincode</span>;
    }
  };

  const url = (value) => {
    const urlValidation = value.toString().trim();

    if (!urlValidation.length) {
      return <span style={{ color: "red" }}>This field is required</span>;
    }

    if (!urlValidation.startsWith("https://")) {
      return <span style={{ color: "red" }}>Please enter valid url</span>;
    }
  };

  const character = (value) => {
    const charValidation = value.toString().trim();

    setCharacters(charValidation.length);
    if (!charValidation.length) {
      return <span style={{ color: "red" }}>This field is required</span>;
    }

    if (charValidation.length > 500) {
      return <span style={{ color: "red" }}>Description is out of limit</span>;
    }
  };

  return (
    <div>
      <SideBar>
        <NavbarComp />
        {/* =========== Add Hospital Form ================ */}
        <Spin spinning={loading}>
        <section className="doctorDetails navbarBodyMargin">
          <div className="container-fluid">
            <div className="AddHospital-main-Head">
              <div className="AddHospital-Heading">
                <h1 className="pageTitle">
                  {id === "add" ? "Add Hospital" : "Edit Hospital"}
                </h1>
              </div>
              <div className="breadcrumb-con">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      class="breadcrumb-item active"
                      style={{ color: "#808080" }}
                      aria-current="page"
                    >
                      {id === "add" ? "Add Hospital" : "Edit Hospital"}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <div className="AddHospital-head-con">
                  <div className="EditHOspital-ImageSelectcom">
                    <div>
                      <div className="col-12 mb-5">
                        <input
                          type="file"
                          id="ProfileImamges"
                          className="productImages"
                          onChange={handleChange}
                          name="image"
                          placeholder=""
                          accept=".jpg, .png, jpeg"
                        />
                        <link
                          href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
                          rel="stylesheet"
                        />

                        <div className="profileImage-con">
                          <div className="profileImage">
                            <img
                              src={imageFile ? imageFile : HospitalVector}
                              className="main-profile-img fatchImage"
                              alt="hospital"
                            />
                            <label className="iputIcon" for="ProfileImamges">
                              <i className="fa fa-edit"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-8">
                <div className="card-style settings-card-2 mb-30">
                  {id === "add" ? (
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 col-lg-6">
                          <div className="input-style-1">
                            <label>
                              Hospital Name{" "}
                              <span style={{ color: "red" }}>*</span>{" "}
                            </label>
                            <Input
                              type="text"
                              className="name1"
                              name="name"
                              placeholder="Full Name"
                              validations={[required]}
                              onChange={(e) =>
                                setCheckValid({
                                  ...checkValid,
                                  name:
                                    e.target.value.length > 0 ? false : true,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6">
                          <div className="input-style-1">
                            <label>
                              Phone No <span style={{ color: "red" }}>*</span>{" "}
                            </label>
                            <Input
                              type="number"
                              name="phone"
                              placeholder="Phone No"
                              validations={[required, phone]}
                              onChange={(e) =>
                                setCheckValid({
                                  ...checkValid,
                                  phone:
                                    e.target.value.length > 0 ? false : true,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-12 col-lg-12">
                          <div className="input-style-1">
                            <label>
                              Address <span style={{ color: "red" }}>*</span>{" "}
                            </label>
                            <Input
                              type="text"
                              name="address"
                              placeholder="Enter Address"
                              validations={[required]}
                              onChange={(e) =>
                                setCheckValid({
                                  ...checkValid,
                                  address:
                                    e.target.value.length > 0 ? false : true,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6">
                          <div className="input-style-1">
                            <label>
                              City <span style={{ color: "red" }}>*</span>{" "}
                            </label>
                            <Input
                              type="text"
                              name="city"
                              placeholder="Enter City Name"
                              validations={[required]}
                              onChange={(e) =>
                                setCheckValid({
                                  ...checkValid,
                                  city:
                                    e.target.value.length > 0 ? false : true,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="input-style-1">
                            <label>
                              State <span style={{ color: "red" }}>*</span>{" "}
                            </label>
                            <Input
                              type="text"
                              name="state"
                              placeholder="Enter State"
                              validations={[required]}
                              onChange={(e) =>
                                setCheckValid({
                                  ...checkValid,
                                  state:
                                    e.target.value.length > 0 ? false : true,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="input-style-1">
                            <label>
                              Pincode <span style={{ color: "red" }}>*</span>{" "}
                            </label>
                            <Input
                              type="number"
                              name="pincode"
                              placeholder="Enter Pincode"
                              validations={[required, pincode]}
                              onChange={(e) =>
                                setCheckValid({
                                  ...checkValid,
                                  pincode:
                                    e.target.value.length > 0 ? false : true,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="input-style-1">
                            <label>
                              GMB Link <span style={{ color: "red" }}>*</span>{" "}
                            </label>
                            <Input
                              type="url"
                              name="gmblink"
                              placeholder="Google MyBusiness Link"
                              validations={[required, url]}
                              onChange={(e) =>
                                setCheckValid({
                                  ...checkValid,
                                  gmbLink:
                                    e.target.value.length > 0 ? false : true,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="input-style-1">
                            <label>
                              Hospital Short Description{" "}
                              <span style={{ color: "red" }}>*</span>{" "}
                            </label>
                            <Textarea
                              name="shortDesc"
                              placeholder="Type here"
                              rows={6}
                              validations={[required, character]}
                              onChange={(e) =>
                                setCheckValid({
                                  ...checkValid,
                                  description:
                                    e.target.value.length > 0 ? false : true,
                                })
                              }
                            />
                            <div className="d-flex justify-content-end">
                              <span>{characters}/500</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            disabled={
                              checkValid.name ||
                              checkValid.address ||
                              checkValid.city ||
                              checkValid.phone ||
                              checkValid.description ||
                              checkValid.pincode ||
                              checkValid.state ||
                              checkValid.gmbLink
                            }
                            type="submit"
                            className="Clickbtn"
                          >
                            Add Hospital
                          </button>
                        </div>
                      </div>
                    </Form>
                  ) : null}
                  {listData &&
                    listData.map((workObj, index) => (
                      <Form key={index} onSubmit={EditHospital}>
                        <div className="row">
                          <div className="col-12 col-lg-6">
                            <div className="input-style-1">
                              <label>
                                Hospital Name{" "}
                                <span style={{ color: "red" }}>*</span>{" "}
                              </label>
                              <input
                                type="text"
                                className="name1"
                                defaultValue={workObj.first_name}
                                name="name"
                                placeholder="Full Name"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="input-style-1">
                              <label>
                                Phone No <span style={{ color: "red" }}>*</span>{" "}
                              </label>
                              <input
                                type="number"
                                defaultValue={workObj.mobile}
                                name="phone"
                                placeholder="Phone No"
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="input-style-1">
                              <label>
                                Address <span style={{ color: "red" }}>*</span>{" "}
                              </label>
                              <input
                                type="text"
                                name="address"
                                defaultValue={workObj.address}
                                placeholder="Enter Address"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-lg-6">
                            <div className="input-style-1">
                              <label>
                                City <span style={{ color: "red" }}>*</span>{" "}
                              </label>
                              <input
                                type="text"
                                defaultValue={workObj.city}
                                name="city"
                                placeholder="Enter City Name"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="input-style-1">
                              <label>
                                State <span style={{ color: "red" }}>*</span>{" "}
                              </label>
                              <input
                                type="text"
                                defaultValue={workObj.state}
                                name="state"
                                placeholder="Enter State"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="input-style-1">
                              <label>
                                Pincode <span style={{ color: "red" }}>*</span>{" "}
                              </label>
                              <Input
                                type="number"
                                value={workObj.pincode}
                                name="pincode"
                                placeholder="Enter Pincode"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="input-style-1">
                              <label>
                                GMB Link <span style={{ color: "red" }}>*</span>{" "}
                              </label>
                              <Input
                                type="url"
                                value={workObj.gmb_Link}
                                name="gmblink"
                                placeholder="Google MyBusiness Link"
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="input-style-1">
                              <label>
                                Hospital Short Description{" "}
                                <span style={{ color: "red" }}>*</span>{" "}
                              </label>
                              <Textarea
                                name="shortDesc"
                                placeholder="Type here"
                                rows={6}
                                value={description}
                                validations={[required, character]}
                                onChange={(e) => {
                                  setCheckValid({
                                    ...checkValid,
                                    description:
                                      e.target.value.length > 0 ? false : true,
                                  });
                                  setDescription(e.target.value);
                                }}
                              />
                              <div className="d-flex justify-content-end">
                                <span>{characters}/500</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <button type="submit" className="Clickbtn">
                              Update Data
                            </button>
                          </div>
                        </div>
                      </Form>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        </Spin>
      </SideBar>
    </div>
  );
};

export default AddHospital;
