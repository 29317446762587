import { Avatar } from "@mui/material";
import moment from "moment";
import React from "react";
import { FiPlusCircle } from "react-icons/fi";

export default function FirstDesign({
  onAppointmentFormOpening,
  bookedList,
  formattedDate,
  slotList,
  hospitalId,
  appointmentTime,
}) {
  console.log(bookedList)
  return (
    <div className="row">
      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2">
        <div className="Shedular-box-con-main">
          <div
            className="Shedular-box-type-card Availeble d-flex justify-content-center align-items-center"
            onDoubleClick={() =>
              onAppointmentFormOpening(
                false,
                bookedList && bookedList.length > 0
                  ? appointmentTime(
                      bookedList[bookedList.length - 1].appointment_start_time
                    )
                  : appointmentTime(slotList[slotList.length - 1]),
                hospitalId,
                formattedDate,
                bookedList && bookedList.length > 0
                  ? appointmentTime(
                      bookedList[bookedList.length - 1].appointment_start_time
                    )
                  : appointmentTime(slotList[slotList.length - 1]),
                "offline"
              )
            }
          >
            <FiPlusCircle style={{ fontSize: "2.5rem" }} />
          </div>
        </div>
      </div>
      {bookedList.map((value, index) => (
        <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12 p-2">
          <div className="Shedular-box-con-main">
            <div
              className={`Shedular-box-type-card ${
                value.appointment_status === "visit" ? "Success" : "Booked"
              }`}
              onDoubleClick={() =>
                onAppointmentFormOpening(
                  "navigateData",
                  value.appointment_start_time,
                  hospitalId,
                  formattedDate,
                  value.appointment_start_time,
                  null
                )
              }
            >
              <div className="row">
                <div className="col-2">
                  <div className="Shedular-Box-card-head">
                    <Avatar
                      src={value.patient_profile_Image}
                      alt={value.patient_first_name}
                    />
                  </div>
                </div>

                <div className="col-10 Shedular-Box-card-Deatils">
                  <div className="Shedular-Box-card-data">
                    <h6>
                      {value.patient_first_name}&nbsp;
                      {value.patient_last_name}(
                      {moment().diff(value.pateint_date_of_birth, "Years") +
                        ` Y`}
                      )
                    </h6>
                    <h5>
                      <span>
                        +91&nbsp;
                        {value.patient_phone_no}
                      </span>
                      &nbsp;
                      <span>{value.purpose_of_visit}</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="Shedular-Box-card-Date">
                <p>{value.appointment_start_time}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
