const storeToken = (value) => {
    if (value) {
        sessionStorage.setItem("access_token", value);
    }
};

const getToken = () => {
    let access_token = sessionStorage.getItem("access_token");
    return { access_token };
};

const removeToken = () => {
    sessionStorage.clear();
    localStorage.clear();
};

// ================================== Get Hospital Details ===============================

const doctorDetails = () => {
    let dataList = sessionStorage.getItem("doctorData");
    let dataGet = JSON.parse(dataList);
    let data = {
        first_name : dataGet.first_name,
        last_name : dataGet.last_name,
        profile_image: dataGet.logo,
        phone_number: dataGet.mobile,
        gender:dataGet.gender,
        email:dataGet.email,
        degree:dataGet.degree,
        date_of_birth:dataGet.date_of_birth,
        addistional_qualification:dataGet.additional_qaulification,
        address:dataGet.address,
        description:dataGet.description,
        specialtion:dataGet.specialtion,
        singnature: dataGet.singnature
       } 

    return{data}
}

export { storeToken, getToken, removeToken,doctorDetails };