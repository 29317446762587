import { Avatar } from "@mui/material";
import moment from "moment";
import React from "react";
import { IoMdAdd } from "react-icons/io";

export default function SecondDesign({
  onAppointmentFormOpening,
  bookedList,
  formattedDate,
  slotList,
  hospitalId,
  appointmentTime,
}) {
  return (
    <>
      <div className="row mt-3 mb-3">
        <div className="col-md-2 col-12 mb-md-0 mb-2">
          <div className="TimeDisplay">
            <h6>
              New Appt
            </h6>
          </div>
        </div>
        <div className="col-md-1 d-md-flex d-none align-items-center">
          <strong>-</strong>
        </div>
        <div className="col-md-9 col-12" style={{ position: "relative" }}>
          <div className="Shedular-List-Data-main-con" style={{cursor:"pointer"}}>
            <div
              className={`Shedular-List-Data-con ${"Available-List-Card"}`}
              onDoubleClick={() =>
                onAppointmentFormOpening(
                  false,
                  bookedList && bookedList.length > 0 ? appointmentTime(bookedList[bookedList.length - 1].appointment_start_time)
                  : appointmentTime(slotList[slotList.length - 1]),
                  hospitalId,
                  formattedDate,
                   bookedList
                  ? appointmentTime(bookedList[bookedList.length - 1].appointment_start_time)
                  : appointmentTime(slotList[slotList.length - 1]),
                  "offline"
                )
              }
            >
              <div className="Shedular-List-Data-profile-con-Main">
                <div className="Shedular-List-Data-profile-con">
                <div className="Shedular-List-Data-profile">
                    <IoMdAdd style={{fontSize:"2rem"}} />
                  </div>
                  <div className="Shedular-List-Data-profile-Name">
                    <h5>Add New Appointment
                    </h5>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {bookedList.map((value, index) => (
        <div key={index} className="row mt-3 mb-3">
          <div className="col-md-2 col-12 mb-md-0 mb-2">
            <div className="TimeDisplay">
              <h6>{value.appointment_start_time}</h6>
            </div>
          </div>
          <div className="col-md-1 d-md-flex d-none align-items-center">
            <strong>-</strong>
          </div>
          <div className="col-md-9 col-12" style={{ position: "relative" }}>
            <div className="Shedular-List-Data-main-con ">
              <div
                className={`Shedular-List-Data-con ${
                  value.appointment_status === "visit"
                    ? "Success-list-card"
                    : "Booked-List-Card"
                }`}
                onDoubleClick={() =>
                  onAppointmentFormOpening(
                    "navigateData",
                    value.appointment_start_time,
                    hospitalId,
                    formattedDate,
                    value.appointment_start_time,
                    null
                  )
                }
              >
                <div className="Shedular-List-Data-profile-con-Main">
                  <div className="Shedular-List-Data-profile-con">
                    <div className="Shedular-List-Data-profile-icon">
                      <Avatar
                        src={value.patient_profile_Image}
                        alt={value.patient_first_name}
                      />
                    </div>
                    <div className="Shedular-List-Data-profile-Name">
                      <h5>
                        <span>
                          {value.patient_first_name}
                          &nbsp;
                          {value.patient_last_name}
                        </span>
                        &nbsp;
                        <span>
                          ({moment().diff(value.pateint_date_of_birth, "Years")}
                          &nbsp;Y&nbsp;)
                        </span>
                      </h5>
                      <h5>+91 {value.patient_phone_no}</h5>
                      <h6 className="d-sm-none d-block ">
                        {value.purpose_of_visit !== ""
                          ? `(` + value.purpose_of_visit + `)`
                          : ""}
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="Shedular-List-Data-Age-con-Main">
                  <div className="Shedular-List-Data-Age-con">
                    <div className="Shedular-List-Data-Age-Name">
                      <h6 className="d-sm-block d-none">
                        {value.purpose_of_visit !== ""
                          ? `(` + value.purpose_of_visit + `)`
                          : ""}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
