import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { getToken } from "../../services/localStorageServices";
import { BsCheck2Circle } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  useHospitalScheduleMutation,
  useDeletehospitalScheduleDayMutation,
} from "../../services/userAuthAPI";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import "./sheduleCss.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import { Spin } from "antd";

// ============= Select Setting =================
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function AlertDialogSlide({
  hospitalShedule,
  setHospitalShedule,
  hospitalID,
  token,
  openModal,
  close,
  hospitalName,
  loading,
  successMessage,
  errorMessage,
  loadingUpload,
  setSuccessMessage,
  setErrorMessage,
  setLoadingUpload,
  setStartTime,
  setEndTime,
  setDays,
  days,
  endTime,
  startTime,
}) {
  const [hospitalSchedule] = useHospitalScheduleMutation();
  const [validation, setValidation] = React.useState({
    day: true,
    startTime: true,
    endTime: true,
    slot: true,
  });
  const { access_token } = getToken();
  const [selectedDays, setSelectedDays] = React.useState([]);

  const addDayTime = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");
    setLoadingUpload(true);
    const data = new FormData(e.currentTarget);

    const InsertData = {
      hospital_Week_name: selectedDays,
      hospital_Start_Time: moment(new Date(startTime)).format("h:mm a"),
      hospital_End_Time: moment(new Date(endTime)).format("h:mm a"),
      hospital_Slot_Duration: data.get("slotDuration"),
      hospital_id: hospitalID,
      token: token,
    };

    const req = await hospitalSchedule(InsertData);

    if (req.error) {
      setSuccessMessage("");
      setLoadingUpload(false);
      setErrorMessage(req.error.data.status);
    }

    if (req.data) {
      setLoadingUpload(false);
      setErrorMessage(null);
      // ===================== Add In Array ===========================

      const hospitalSheduleData = selectedDays.map((value) => ({
        hospital_schedule_Id: req.data.sheduleIds.find(
          (sheduleId) => sheduleId.hospital_weeek_name === value
        ).hospital_schedule_Id,
        hospital_weeek_name: value,
        hospital_start_time: moment(new Date(startTime)).format("h:mm a"),
        hospital_end_time: moment(new Date(endTime)).format("h:mm a"),
        hospital_slot_duration: data.get("slotDuration"),
        hospital_id: hospitalID,
      }));

      setHospitalShedule((prev) => [...prev, ...hospitalSheduleData]);
      setSuccessMessage("Uploded Succesfully");

      const hospaitl_list = JSON.parse(sessionStorage.getItem("hospaitl_list"));
      const indexToUpdate = hospaitl_list.findIndex(
        (item) => item.list_of_account === hospitalID
      );

      if (indexToUpdate !== -1) {
        // The item was found, so you can update it
        const newData = [...hospitalShedule, ...hospitalSheduleData]; // Replace with the new data
        hospaitl_list[indexToUpdate].hospital_shedule = newData;

        // Store the updated object back in sessionStorage
        sessionStorage.setItem("hospaitl_list", JSON.stringify(hospaitl_list));
      } else {
        // Item not found
        console.log("Item not found in the array.");
      }
      setSelectedDays([]);
      setStartTime(null);
      setEndTime(null);
    }
    e.target.reset();
  };

  // ============ Shedule day delete =====================

  const [deleteScheduleDay] = useDeletehospitalScheduleDayMutation();

  const deleteDayTime = async (hospital_schedule_Id, indexData) => {
    if (window.confirm("Are you sure you want to delete this schedule day?")) {
      setLoadingUpload(true);
      const formData = new FormData();
      formData.append("token", access_token);
      formData.append("Hospital_Sedule_Id", hospital_schedule_Id);
      const req = await deleteScheduleDay(formData);

      if (req.data) {
        setLoadingUpload(false);
        hospitalShedule.splice(indexData, 1);
        setHospitalShedule([...hospitalShedule]);

        const hospaitl_list = JSON.parse(
          sessionStorage.getItem("hospaitl_list")
        );
        const index = hospaitl_list.findIndex(
          (value) => value.list_of_account === hospitalID
        );
        hospaitl_list[index].hospital_shedule = hospitalShedule;
        console.log(hospaitl_list);
        sessionStorage.setItem("hospaitl_list", JSON.stringify(hospaitl_list));
      }
    }
  };

  // Define a custom sorting order for days
  const daysOrder = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  React.useEffect(() => {
    hospitalShedule.sort((a, b) => {
      return (
        daysOrder.indexOf(a.hospital_weeek_name) -
        daysOrder.indexOf(b.hospital_weeek_name)
      );
    });

    setSuccessMessage("");
    setErrorMessage("");
    // eslint-disable-next-line
  }, [hospitalShedule]);

  // ==================== Multi Days Select =================
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDays(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openModal}
        keepMounted
        onClose={close}
        aria-describedby="alert-dialog-slide-description"
      >
        <Spin spinning={loadingUpload}>
          <DialogContent>
            <div className="AddScheduleHead">
              <h6>{hospitalName} Day and Time</h6>
            </div>

            <div className="addDayTime">
              <div className="inputBox">
                {hospitalShedule.length > 0 ? (
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Days</th>
                        <th scope="col">Start Time</th>
                        <th scope="col">End Time</th>
                        <th scope="col">Slots In Minute</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {hospitalShedule.map((listObject, index) => (
                        <tr key={index}>
                          <th scope="row">{listObject.hospital_weeek_name}</th>
                          <td>{listObject.hospital_start_time}</td>
                          <td>{listObject.hospital_end_time}</td>
                          <td>{listObject.hospital_slot_duration} Minute</td>
                          <td>
                            <div
                              onClick={() =>
                                deleteDayTime(
                                  listObject.hospital_schedule_Id,
                                  index
                                )
                              }
                              style={{ color: "red" }}
                            >
                              <MdDelete />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h3 style={{ textAlign: "center", padding: "10px" }}>
                    No Shedule Found
                  </h3>
                )}
                {/* ======== Input Box for Say and Time ====== */}
                <form onSubmit={addDayTime}>
                  <div className="inputBoxInput">
                    <div className="inputBoxInoutInner d-flex flex-wrap">
                      <div className="inputBoxInoutInnerDiv">
                        <div className="input-style-1">
                          <FormControl sx={{ width: 200 }}>
                            <Select
                              multiple
                              displayEmpty
                              value={selectedDays}
                              onChange={handleChange}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <>Select Days</>;
                                }

                                return selected.join(", ");
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem disabled value="">
                                Select Days
                              </MenuItem>
                              {names.map((name) =>
                                hospitalShedule.some(
                                  (value) => value.hospital_weeek_name === name
                                ) ? null : (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, selectedDays, theme)}
                                  >
                                    {name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className="inputBoxInoutInnerDiv">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="Start Time"
                            name="startTime"
                            inputProps={{ timezone: "" }}
                            value={startTime}
                            onChange={(newValue) => {
                              setStartTime(newValue);
                              setValidation({
                                ...validation,
                                startTime: false,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="inputBoxInoutInnerDiv">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="End Time"
                            name="endTime"
                            inputProps={{ timezone: "" }}
                            value={endTime}
                            onChange={(newValue) => {
                              setEndTime(newValue);
                              setValidation({ ...validation, endTime: false });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="inputBoxInoutInnerDiv">
                        <div className="input-style-1">
                          <TextField
                            type="number"
                            id="outlined-basic"
                            label="Slots In Minute"
                            variant="outlined"
                            name="slotDuration"
                            onChange={() =>
                              setValidation({ ...validation, slot: false })
                            }
                          />
                        </div>
                      </div>
                      <div className="inputBoxInoutInnerDiv d-flex justify-content-center align-items-center">
                        <button
                          disabled={
                            validation.startTime ||
                            validation.endTime ||
                            validation.slot
                          }
                          type="submit"
                          className="Clickbtn"
                        >
                          <BsCheck2Circle style={{ color: "#fff" }} />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </DialogContent>
        </Spin>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          {successMessage !== "" ? (
            <span style={{ color: "green" }}>{successMessage}</span>
          ) : null}
          {errorMessage !== "" ? (
            <span style={{ color: "red" }}>{errorMessage}</span>
          ) : null}

          {validation.startTime || validation.endTime || validation.slot ? (
            <span style={{ color: "red" }}>All fields are required !</span>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialogSlide;
