import React, { useState, useEffect } from "react";
import { getToken, removeToken } from "../../services/localStorageServices";
import LOGO from "../../Images/LOGO.webp";
import { AiOutlineMenu } from "react-icons/ai";
import { useSideBar } from "../../context/sideBar";
import { VscSignOut } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar } from "@mui/material";

const NavbarComp = (prop) => {
  const { page } = useParams();
  const { access_token } = getToken();
  const [checkLoginType, setCheckLoginType] = useState(null);

  const [doctorData, setDoctorData] = useState({
    image: "",
    firstname: "",
    lastname: "",
  });

  const fetchDoctorData = () => {
    fetch("https://skdm.in/skddev/DrCrm/Dr_Profile_View.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then((apiData) => {
        setDoctorData({
          image: apiData.logo,
          firstname: apiData.first_name,
          lastname: apiData.last_name,
          singnature: apiData.singnature,
          degree: apiData.degree,
          phone: apiData.account_phone,
          additional_qaulification: apiData.additional_qaulification,
          specialtion: apiData.specialtion,
        });
        sessionStorage.setItem("doctorData", JSON.stringify(apiData));
      });
  };

  useEffect(() => {
    const doctorDetails = JSON.parse(sessionStorage.getItem("doctorData"));
    if (doctorDetails === null || doctorDetails.length === 0) {
      fetchDoctorData();
    } else {
      const doctorData = JSON.parse(sessionStorage.getItem("doctorData"));
      if (doctorData.status) {
        fetchDoctorData();
      } else {
        setDoctorData({
          image: doctorData.logo,
          firstname: doctorData.first_name,
          lastname: doctorData.last_name,
          singnature: doctorData.singnature,
          degree: doctorData.degree,
          phone: doctorData.account_phone,
          additional_qaulification: doctorData.additional_qaulification,
          specialtion: doctorData.specialtion,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token]);

  useEffect(() => {
    if (prop.data) {
      setDoctorData({
        image: prop.data.logo,
        firstname: prop.data.first_name,
        lastname: prop.data.last_name,
        singnature: prop.data.singnature,
        degree: prop.data.degree,
      });
    }
  }, [prop.data]);

  const { toggle } = useSideBar();
  const navigate = useNavigate();

  const logOutFunction = () => {
    removeToken();
    navigate("/", { replace: true });
  };


  const handleLogoutAccess = () => {
    navigate("/admin-doctor-list");
    sessionStorage.clear();
  };

  return (
    <div className="main-wrapper">
      <div
        className="loginDetail d-flex justify-content-between align-items-center"
        style={{ background: "green", padding: "3px 10px 3px 50px" }}
      >
        <h6 style={{ color: "#fff" }}>
          Access to - Dr. {doctorData.firstname + " " + doctorData.lastname}
          &nbsp;(+91 {doctorData.phone})
        </h6>
        <button
          className="d-flex justify-content-center align-items-center"
          style={{
            background: "transparent",
            border: "1px solid #fff",
            borderRadius: "3px",
            padding: "3px",
            color: "#fff",
            fontSize: "1rem",
          }}
          onClick={handleLogoutAccess}
        >
          {" "}
          Log out&nbsp;
          <VscSignOut style={{ fontSize: "1.4rem", marginRight: "5px" }} />
        </button>
      </div>
      <div style={{ background: "#033362", padding: "5px" }}>
        <div className="container d-flex justify-content-between align-item-center">
          {checkLoginType && checkLoginType === "reception" ? (
            <button
              onClick={logOutFunction}
              style={{
                fontSize: "16px",
                color: "#fff",
                border: "unset",
                background: "transparent",
                padding: "0 7px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <VscSignOut style={{ fontSize: "1.4rem", marginRight: "5px" }} />
              Log Out
            </button>
          ) : null}
          <div className="headerLogo d-none d-sm-block">
            <img style={{ maxWidth: "50px" }} alt="doctar" src={LOGO} />
          </div>
          {page === "reception" ? null : (
            <button
              onClick={toggle}
              className="d-block d-sm-none"
              style={{
                fontSize: "20px",
                color: "#fff",
                border: "unset",
                background: "transparent",
                padding: "0 10px",
              }}
            >
              <AiOutlineMenu sx={{ size: 25 }} />
            </button>
          )}
          <div className="profile-box ml-15">
            <div className="profile-info">
              <div className="info">
                <div className="image mx-2">
                  <Avatar src={doctorData.image} alt="dr image" />
                </div>
                <div className="titleData" style={{ textAlign: "left" }}>
                  <h5 style={{ color: "#fff", margin:"unset" }}>
                    Dr. {doctorData.firstname + " " + doctorData.lastname}
                  </h5>
                  <span style={{ color: "#eee" }}>
                    {doctorData.degree + ", " + doctorData.specialtion+", "+doctorData.additional_qaulification}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarComp;
