import React, { useEffect, useState } from "react";
import AdminHeader from "../components/admin_header";
import grapesjs from "grapesjs";
import "../css/new_template.css";
import "grapesjs/dist/css/grapes.min.css";
import plugin from "grapesjs-preset-newsletter";
import { Button, Form, Input, message } from "antd";

export default function AddTemplate() {
  const [editor, setEditor] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const editor = grapesjs.init({
      // Indicate where to init the editor. You can also pass an HTMLElement
      container: "#editor",
      height: "88vh",
      plugins: [plugin],
      storageManager: {
        // Other storage manager options
        noticeOnClean: true, // This enables a confirmation message on clean
        textCleanCanvas: "Are you sure to clean the design?", // Add your custom message here
      },
      plugins: [
        (editor) =>
          plugin(editor, {
            /* options */
          }),
      ],
    });
    setEditor(editor);
  }, []);

  function saveHTMLToAPI(htmlContent, cssContent, name) {
    if (window.confirm("Are sure you want save changes ?")) {
      // Create a Blob containing the combined HTML and CSS
      const combinedContent = `
      <?php 
      include(priscriptionData.php);

      $patientNameVar = "Arbaz Ansari";
      $ageVar = "24/Male";
      $idVar = "PID125";
      $addressVar = "Mohid heights 400054";
      $dateVar = "23 Mar 2023";
      $mobileVar = "0987654321";
      $timeVar = "03:00 PM";
      ?>

    <html>
      <head>
        <style>
        <?php echo $dynamicStyle ?>
        ${cssContent}
        </style>
      </head>
        ${htmlContent}
    </html>
  `;

      const replaceWordsList = [
        { previous: "patientNameValue", replace: "<?php echo $patientNameVar; ?>" },
        { previous: "ageValue", replace: "<?php echo $ageVar; ?>" },
        { previous: "idValue", replace: "<?php echo $idVar; ?>" },
        { previous: "addressValue", replace: "<?php echo $addressVar; ?>" },
        { previous: "dateValue", replace: "<?php echo $dateVar; ?>" },
        { previous: "mobileValue", replace: "<?php echo $mobileVar; ?>" },
        { previous: "timeValue", replace: "<?php echo $timeVar; ?>" },
      ];

      const replaceWords = (html, replacements) => {
        let updatedHTML = html;
    
        replacements.forEach(replacement => {
          const { previous, replace } = replacement;
          const regex = new RegExp(previous, 'g');
          updatedHTML = updatedHTML.replace(regex, replace);
        });
    
        return updatedHTML;
      }

      const finalTemplate = replaceWords(combinedContent, replaceWordsList)

      const blob = new Blob([finalTemplate], { type: "text/html" });

      // Send the file to the API
      sendFileToAPI(blob, name);
    }
  }

  const sendFileToAPI = async (blob, templateName) => {
    const formData = new FormData();
    formData.append("file", blob, "grapejs_content.html");
    formData.append("templateName", templateName);
  
    try {
      const response = await fetch("https://skdm.in/skddev/DrCrm/templates/save-template.php", {
        method: "POST",
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        
        message.success("Template saved successfully");
  
        if (window.confirm("Would you like to see your template?")) {
          window.open(data.fileLink, '_blank');
        }
      } else {
        // Handle errors
        message.error("Unable to save template. Please try again.");
        throw new Error("API request failed");
      }
    } catch (error) {
      // Handle any other errors that may occur during the request or processing
      console.error(error);
      message.error("An error occurred. Please try again.");
    }
  };
  

  const onFinish = (values) => {
    const htmlContent = editor.getHtml();
    const cssContent = editor.getCss();

    saveHTMLToAPI(htmlContent, cssContent, values.name);
  };

  return (
    <>
      <AdminHeader />

      <div className="templateBuilder navbarBodyMargin">
        <Form
          form={form}
          className="mb-3"
          name="horizontal_login"
          layout="inline"
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please input template name!",
              },
            ]}
          >
            <Input placeholder="Template Name" />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button type="primary" htmlType="submit">
                Save Template
              </Button>
            )}
          </Form.Item>
        </Form>

        <div id="editor"></div>
      </div>
    </>
  );
}
