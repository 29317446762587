import React, { useEffect, useState } from "react";
import AdminHeader from "../components/admin_header";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { MdDoDisturbAlt } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import { AiOutlineCheckCircle, AiOutlineFundView } from "react-icons/ai";
import NoDataFound from "../../pages/NoDataFound";
import moment from "moment";
import { Pagination, Skeleton } from "@mui/material";

export default function TemplateSetting() {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [totalPages, setTotalPage] = useState(0);
  const [notFound, setNotFound] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState({
    message: "",
    type: "",
  });

  const TemplateData = (limit, page, searchText) => {
    setLoading(true);
    fetch(
      "https://skdm.in/skddev/DrCrm/template_layout_view.php?page=" +
        page +
        "&limit=" +
        limit +
        "&search=" +
        searchText,
      {
        method: "GET",
      }
    )
      .then((resp) => resp.json())
      .then((apiData) => {
        if (apiData.status === "success") {
          setLoading(false);
          setListData(apiData.data);
          setTotalPage(apiData.totalPages);
        } else {
          setNotFound(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching drug data:", error);
      });
  };

  useEffect(() => {
    TemplateData(limit, page, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  return (
    <div>
      <AdminHeader />
      <div className="PatientsListSection navbarBodyMargin">
        <div className="patientListHead">
          <h1 className="pageTitle">Template List</h1>
          <div className="addPatientBtn d-flex align-items-center">
            <Link to="/add-doctor">
              <Tooltip title="Add New Template" arrow>
                <NavLink to="/add-new-template">
                  {" "}
                  <button
                    className="Clickbtn"
                    data-bs-toggle="modal"
                    data-bs-target="#addTaskModal"
                  >
                    + New Template
                  </button>
                </NavLink>
              </Tooltip>
            </Link>
          </div>
        </div>
        {/* =========== Drugs Search Box ========================= */}
        <div className="DrugsPageSearchBox">
          <div className="searchBoxes">
            <form>
              <div className="row">
                <div className="col-lg-3 col-md-3 col-12 mt-2">
                  <div className="searchDiv">
                    <input
                      className="form-control"
                      placeholder="Search Doctor"
                      type="text"
                      variant="outlined"
                      name="searchText"
                      fullWidth
                      auto
                      size="small"
                      autoComplete="off"
                    />
                    <div className="iconsStyle search">
                      <SearchIcon />
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-6 mt-2">
                  <div className="searchDiv">
                    <select
                      className="form-select"
                      name="selectedCatogery"
                      aria-label="demo-simple-select-label"
                    >
                      <option selected> Search By</option>
                      <option value="id">Doctor ID</option>
                      <option value="name">Doctor Name</option>

                      <option value="phone"> Doctor Phone No</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-6 mt-2 p-0 d-flex justify-content-start align-items-center">
                  <div className="searchDrugBtn d-flex align-items-center">
                    <button type="submit" className="Clickbtn">
                      Search
                    </button>
                    &nbsp;
                    <button type="reset" className="Cancelbtn">
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="patientsListTable d-lg-block d-md-none d-none">
          {notFound ? (
            <NoDataFound />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Doctor ID</th>
                  <th scope="col">Profile</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Number</th>
                  <th scope="col">Join Date</th>
                  <th scope="col">Renew At</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
            </table>
          )}
          <div
            className="paginationDetail d-flex align-items-center justify-content-between"
            style={{
              boxShadow: "3px 7px 10px #eee",
              background: "#fff",
              padding: "10px",
            }}
          >
            <div className="limitDetail d-flex align-items-center">
              <span>Per&nbsp;Page:</span>&nbsp;
              <select name="" id="" className="form-select">
                {limit === 10 ? (
                  <option value="10" selected>
                    10
                  </option>
                ) : (
                  <option value="10">10</option>
                )}
                {limit === 20 ? (
                  <option value="20" selected>
                    20
                  </option>
                ) : (
                  <option value="20">20</option>
                )}
                {limit === 50 ? (
                  <option value="50" selected>
                    50
                  </option>
                ) : (
                  <option value="50">50</option>
                )}
                {limit === 100 ? (
                  <option value="100" selected>
                    100
                  </option>
                ) : (
                  <option value="100">100</option>
                )}
              </select>
            </div>
            <Pagination count={totalPages} page={page} color="primary" />{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
