import React, { useState } from "react";
import "../css/admin_steps.css";
import AccountForm from "../components/accountForm";
import PersonalInfo from "../components/personalInfo";
import HospitalDetail from "../components/hospitalDetail";
import AdminHeader from "../components/admin_header";

export default function AddDoctorAdmin() {
  const [activeSteps, setActivesteps] = useState({
    step1: "active",
    step2: "",
    step3: "",
  });
  return (
    <>
      <AdminHeader />
      <div className="doctor_add_steps navbarBodyMargin">
        <div className="doctor_steps">
          <div className="steps_list">
            <ul>
              <li className={`${activeSteps.step1} step1`}>
                <span>01</span>
                <div className="stepData">
                  <h5>Login Details</h5>
                  <p>Create login details to add data accordingly.</p>
                </div>
              </li>
              <li className={`${activeSteps.step2} step2`}>
                <span>02</span>
                <div className="stepData">
                  <h5>Personal Detail</h5>
                  <p>Create Doctor profile and information.</p>
                </div>
              </li>
              <li className={`${activeSteps.step3} step3`}>
                <span>03</span>
                <div className="stepData">
                  <h5>Hospitals Detail</h5>
                  <p>Add hospital to start appointment booking.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="doctor_add_form">
          {activeSteps.step1 === "active" ? (
            <AccountForm
              setActivesteps={setActivesteps}
              activeSteps={activeSteps}
            />
          ) : null}
          {activeSteps.step2 === "active" ? (
            <PersonalInfo
              setActivesteps={setActivesteps}
              activeSteps={activeSteps}
            />
          ) : null}

          {activeSteps.step3 === "active" ? (
            <HospitalDetail
              setActivesteps={setActivesteps}
              activeSteps={activeSteps}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
