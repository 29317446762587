import React, { useState } from "react";
import { useProfileCreateMutation } from "../../services/userAuthAPI";
import { useNavigate } from "react-router-dom";

export default function PersonalInfo({ setActivesteps, activeSteps }) {
  const [profileInfo, setProfileInfo] = useState({
    firstName: null,
    lastName: null,
    dob: null,
    gender: null,
    degree: null,
    speciallization: null,
    additionalQualification: null,
    image: null,
    description: null,
    address: null,
  });

  const [validation, setValidation] = useState({
    firstName: true,
    lastName: true,
    dob: true,
    gender: true,
    degree: true,
    speciallization: true,
    additionalQualification: true,
    image: true,
    description: true,
    address: true,
  });

  // ======================== Profile Create =========================

  const [profileCreate] = useProfileCreateMutation();
  const navigate = useNavigate();

  const handleSubmitForm = async () => {
    const formData = new FormData();

    formData.append("first_name", profileInfo.firstName);
    formData.append("last_name", profileInfo.lastName);
    formData.append("dob", profileInfo.dob);
    formData.append("gender", profileInfo.gender);
    formData.append("specialization", profileInfo.speciallization);
    formData.append("degree", profileInfo.degree);
    formData.append(
      "addional_qualification",
      profileInfo.additionalQualification
    );
    formData.append("description", profileInfo.description);
    formData.append("profile_image", profileInfo.image);
    formData.append("address", profileInfo.address);
    formData.append("id", sessionStorage.getItem("accointId"));

    const req = await profileCreate(formData);

    if (req.data) {
      setActivesteps({
        ...activeSteps,
        step2: "submited",
        step3:"active"
      })
    }

    if (req.error) {
      alert("something went wrong we are unable create profile !")
    }
  };

  return (
    <div>
      <h3 className="pageTitle">Personal Details</h3>
      <div className="perosnal_detail">
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="inputTpye">
              <label htmlFor="">
                Enter First Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                onChange={(e) =>
                  e.target.value.length > 0
                    ? (setProfileInfo({
                        ...profileInfo,
                        firstName: e.target.value,
                      }),
                      setValidation({ ...validation, firstName: false }))
                    : (setProfileInfo({ ...profileInfo, firstName: "" }),
                      setValidation({ ...validation, firstName: true }))
                }
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="inputTpye">
              <label htmlFor="">
                Enter Last Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                onChange={(e) =>
                  e.target.value.length > 0
                    ? (setProfileInfo({
                        ...profileInfo,
                        lastName: e.target.value,
                      }),
                      setValidation({ ...validation, lastName: false }))
                    : (setProfileInfo({ ...profileInfo, lastName: "" }),
                      setValidation({ ...validation, lastName: true }))
                }
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="inputTpye">
              <label htmlFor="">
                Select DOB <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="date"
                className="form-control"
                placeholder="DOB"
                onChange={(e) =>
                  e.target.value.length > 0
                    ? (setProfileInfo({
                        ...profileInfo,
                        dob: e.target.value,
                      }),
                      setValidation({ ...validation, dob: false }))
                    : (setProfileInfo({ ...profileInfo, dob: "" }),
                      setValidation({ ...validation, dob: true }))
                }
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="inputTpye">
              <label htmlFor="">
                Select Gender <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name=""
                className="form-select"
                id=""
                onChange={(e) =>
                  e.target.value.length > 0
                    ? (setProfileInfo({
                        ...profileInfo,
                        gender: e.target.value,
                      }),
                      setValidation({ ...validation, gender: false }))
                    : (setProfileInfo({ ...profileInfo, gender: "" }),
                      setValidation({ ...validation, gender: true }))
                }
              >
                <option selected disabled>
                  Select Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="inputTpye">
              <label htmlFor="">
                Enter Degree <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Degrees"
                onChange={(e) =>
                  e.target.value.length > 0
                    ? (setProfileInfo({
                        ...profileInfo,
                        degree: e.target.value,
                      }),
                      setValidation({ ...validation, degree: false }))
                    : (setProfileInfo({ ...profileInfo, degree: "" }),
                      setValidation({ ...validation, degree: true }))
                }
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="inputTpye">
              <label htmlFor="">
                Enter Specialization <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Specialization"
                onChange={(e) =>
                  e.target.value.length > 0
                    ? (setProfileInfo({
                        ...profileInfo,
                        speciallization: e.target.value,
                      }),
                      setValidation({ ...validation, speciallization: false }))
                    : (setProfileInfo({ ...profileInfo, speciallization: "" }),
                      setValidation({ ...validation, speciallization: true }))
                }
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="inputTpye">
              <label htmlFor="">Enter Additional Qualification</label>
              <input
                type="text"
                className="form-control"
                placeholder="Additional Qualification"
                onChange={(e) =>
                  e.target.value.length > 0
                    ? (setProfileInfo({
                        ...profileInfo,
                        additionalQualification: e.target.value,
                      }),
                      setValidation({
                        ...validation,
                        additionalQualification: false,
                      }))
                    : (setProfileInfo({
                        ...profileInfo,
                        additionalQualification: "",
                      }),
                      setValidation({
                        ...validation,
                        additionalQualification: true,
                      }))
                }
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="inputTpye">
              <label htmlFor="">
                Select Profile Image <span style={{ color: "red" }}>*</span>{" "}
              </label>
              <input
                type="file"
                className="form-control"
                placeholder=""
                accept=".jpg, .jpeg, .png"
                onChange={(e) =>
                  e.target.files.length > 0
                    ? (setProfileInfo({
                        ...profileInfo,
                        image: e.target.files[0],
                      }),
                      setValidation({ ...validation, image: false }))
                    : (setProfileInfo({ ...profileInfo, image: "" }),
                      setValidation({ ...validation, image: true }))
                }
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="inputTpye">
              <label htmlFor="">
                Enter Description <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                className="form-control"
                col="4"
                onChange={(e) =>
                  e.target.value.length > 0
                    ? (setProfileInfo({
                        ...profileInfo,
                        description: e.target.value,
                      }),
                      setValidation({ ...validation, description: false }))
                    : (setProfileInfo({ ...profileInfo, description: "" }),
                      setValidation({ ...validation, description: true }))
                }
              ></textarea>
            </div>
          </div>

          <div className="col-md-6 mb-3">
            <div className="inputTpye">
              <label htmlFor="">Enter Address</label>
              <textarea
                className="form-control"
                col="4"
                onChange={(e) =>
                  e.target.value.length > 0
                    ? (setProfileInfo({
                        ...profileInfo,
                        address: e.target.value,
                      }),
                      setValidation({ ...validation, address: false }))
                    : (setProfileInfo({ ...profileInfo, address: "" }),
                      setValidation({ ...validation, address: true }))
                }
              ></textarea>
            </div>
          </div>
          <div className="col-12">
            <div className="btn_group">
              <button
                disabled={
                  validation.firstName ||
                  validation.lastName ||
                  validation.dob ||
                  validation.gender ||
                  validation.degree ||
                  validation.speciallization ||
                  validation.image ||
                  validation.description
                }
                onClick={handleSubmitForm}
              >
                {" "}
                Submit & Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
